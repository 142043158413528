import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import contactBanner from "../../assets/images/contactBanner.webp";
import classes from "./ContactUs.module.css";
import Navbar from "../components/Navbar";
import Banner from "../components/Banner";
import MiddleBanner from "../components/MiddleBanner";
import MessageUsButton from "../components/MessageUsButton";
import Footer from "../components/Footer";
import GoToTop from "../components/GoToTop";
import { Link } from "react-router-dom";
import { BASE_URL } from "../../apiConfig";
import { Helmet } from "react-helmet-async";

function ContactUs() {
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const midBannerText = (
    <>
      Event planning ke liye humse sampark karo,
      <br />
      Phir mauj jamkar karo!
    </>
    // <>
    //   Jashn hoga yaadgaar
    //   <br />
    //   Party Help Desk haina yaar!
    // </>
  );

  const handleSubmit = (e) => {
    e.preventDefault();

    fetch(`${BASE_URL}/customer/ticket`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ firstname, lastname, email, message }),
    })
      .then((response) => {
        if (response.status === 200) {
          toast.success("Message sent successfully!", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "colored",
          });
        } else {
          toast.error("An error occurred! Try again.", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "colored",
          });
        }
        return response.json();
      })
      .then((result) => {
        console.log(result);
      })
      .catch((error) => {
        console.error("Error:", error);
        toast.error("An error occurred! Try again.", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "colored",
        });
      });

    setFirstname("");
    setLastname("");
    setMessage("");
    setEmail("");
  };

  return (
    <>
      <Helmet>
        <title>Contact Party Plan Desk - Party Planning Company in India</title>
        <link rel="canonical" href="https://www.partyhelpdesk.com/contactus" />
        <meta
          name="description"
          content="Feel free to contact Party Plan desk for Party Management, Provide all vendors, example: Venue, photographer, decoraters and catering. Contact us today"
        />
      </Helmet>
      <GoToTop />
      <Navbar />
      <Banner image={contactBanner} text="Contact Us" />
      <MessageUsButton />
      <div className={classes.contentDiv}>
        <div className={classes.supportDetailsDiv}>
          <h3>CUSTOMER SUPPORT</h3>
          <p>
            You can reach our customer support team to address any of your
            queries or complaints related to product and services by filling the
            form below or by
          </p>
          <p>
            <Link to="mailto:partyhelpdesk@gmail.com">
              Email: partyhelpdesk@gmail.com
            </Link>
          </p>
          <p>
            <Link to="tel:+918840152159">Phone: 8840 152 159</Link>
          </p>
          <p>
            <Link to="tel:+919838071565">Phone: 9838 071 565</Link>
          </p>
          <p>Address: 186/2 Tagore Town, Prayagraj - 211002</p>
          <p>Timings: Mon - Sat (9:00 - 18:00)</p>
        </div>
        <hr />
        <div className={classes.supportFormDiv}>
          <h3>CUSTOMER SUPPORT REQUEST</h3>
          <form onSubmit={handleSubmit}>
            <div className={classes.supportForm}>
              <input
                type="text"
                name="firstname"
                placeholder="First Name"
                value={firstname}
                onChange={(e) => setFirstname(e.target.value)}
              />
              <input
                type="text"
                name="lastname"
                placeholder="Last Name"
                value={lastname}
                onChange={(e) => setLastname(e.target.value)}
              />
              <input
                type="tel"
                name="email"
                placeholder="Registered Email*"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <textarea
                rows={10}
                name="message"
                placeholder="Message*"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
              <div className={classes.actions}>
                <button type="submit">Continue</button>
                <Link to="/contactus/message">
                  <p>If you're already logged in, skip</p>
                </Link>
              </div>
            </div>
          </form>
        </div>
      </div>
      <MiddleBanner text={midBannerText} />
      <ToastContainer />
      <Footer />
    </>
  );
}

export default ContactUs;
