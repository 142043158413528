import React from "react";
import FirstForm from "../components/VendorRegistration/FirstForm";
import Navbar from "../components/Navbar";
import MiddleBanner from "../components/MiddleBanner";
import Footer from "../components/Footer";
import MessageUsButton from "../components/MessageUsButton";
import GoToTop from "../components/GoToTop";
import classes from "./VendorRegistration.module.css";
import vendorBanner from "../../assets/images/vendorBanner.webp";
import { Helmet } from "react-helmet-async";

function VendorRegistration() {
  return (
    <>
      <Helmet>
        <title>
          Find party Vendors in All - Venues, Photographers, Bridal Makeup &
          more
        </title>
        <link
          rel="canonical"
          href="https://www.partyhelpdesk.com/vendorregistration"
        />
        <meta
          name="description"
          content="vendors including Venue, Photographer, Decoraters, Wedding planners, Corporate events Planners etc. Read Reviews & check prices."
        />
      </Helmet>
      <GoToTop />
      <MessageUsButton />
      <Navbar />

      <div
        className={classes.banner}
        style={{
          backgroundImage: `url(${vendorBanner})`,
        }}
      ></div>
      <FirstForm />
      {/* <MiddleBanner /> */}
      <Footer />
    </>
  );
}

export default VendorRegistration;
