import React from "react";

function PaymentInfo() {
  return (
    <>
      <h1>Payment</h1>
      <hr />
      <h2>Subscription Plans:</h2>
      <p>Basic: ₹499 + 18% GST (Annual) per category selected</p>
      <p>Premium: ₹799 + 18% GST (Annual) per category selected</p>

      <h2>
        Bank account details for payment of Subscription fees by Service
        Providers:
      </h2>
      <p>Account Holder Name: Jenny Blossom India (OPC) Private Limited</p>
      <p>Account Number: 35210200000468</p>
      <p>IFSC Code: BARB0GEORGE</p>
      <p>Branch: Bank of Baroda, George Town, Prayagraj</p>
      <h6>Payment can be made through UPI apps, NEFT, RTGS and Net Banking.</h6>
      <p>
        We aim to process and approve registrations within 24 to 48 hours, upon
        receiving payment.
        We appreciate your patience and look forward to welcoming you as an
        approved vendor.
      </p>
    </>
  );
}

export default PaymentInfo;
