import React from "react";
import classes from "./Partners.module.css";
import partner1 from "../../../assets/logos/partner1.png";
import partner2 from "../../../assets/logos/partner2.png";
import partner3 from "../../../assets/logos/partner3.png";
import partner4 from "../../../assets/logos/partner4.png";

function Partners() {
  return (
    <div className={classes.wrapper}>
      <div className={classes.line}>
        <hr />
      </div>
      <h4>Our Partners</h4>
      <div className={classes.gridContainer}>
        <div className={classes.gridItem}>
          <img src={partner1} alt="company-logo" />
        </div>
        <div className={classes.gridItem}>
          <img src={partner2} alt="company-logo" />
        </div>
        <div className={classes.gridItem}>
          <img src={partner3} alt="company-logo" />
        </div>
        <div className={classes.gridItem}>
          <img src={partner4} alt="company-logo" />
        </div>
      </div>
      <div className={classes.line}>
        <hr />
      </div>
    </div>
  );
}

export default Partners;
