import React, { useState } from "react";
import axios from "axios";
import Header from "./Header";
import { BASE_URL } from "../../apiConfig";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import Table from "./TableVendor";
import MenuIcon from "@mui/icons-material/Menu";
import Sidebar from "./Sidebar";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import TableBlog from "./TableBlog";
import classes from "./Blog.module.css";
import TableTicket from "./TableTicket";

function Category() {
  const [form, setForm] = useState(false);

  const handleForm = (e) => {
    setForm(!form);
  };

  return (
    <div className="adminPage">
      <Sidebar />

      <div className={classes.category}>
        <Header />
        <hr />
        <TableTicket />
      </div>
    </div>
  );
}

export default Category;
