import React, { useState } from "react";
import axios from "axios";
import Header from "./Header";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import MenuIcon from "@mui/icons-material/Menu";
import Sidebar from "./Sidebar";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import TableEnquiry from "./TableEnquiry";
import classes from "./Enquiry.module.css";

function Enquiry() {
  return (
    <div className="adminPage">
      <Sidebar />
      <div className={classes.enquiry}>
        <Header />
        <hr />
        <TableEnquiry />
      </div>
    </div>
  );
}

export default Enquiry;
