import React, { useState, useEffect } from "react";
import classes from "./Customer.module.css";
import Header from "./Header";
import { BASE_URL } from "../../apiConfig";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import axios from "axios";
import Sidebar from "./Sidebar";
import { Modal, Box } from "@mui/material";
import Fade from "@mui/material/Fade";

function Customer() {
  const [PopUp, setPopUp] = useState(false);
  const [form, setForm] = useState(false);
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [items, setItems] = useState([]);
  const [activeId, setActiveId] = useState("");
  const [openForm, setOpenForm] = useState(false);

  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    phone: "",
  });

  //Get all customers
  useEffect(() => {
    fetch(`${BASE_URL}/getuserdetails`, {
      method: "GET",
      //credentials: "include",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("No user found.");
        }
        return response.json();
      })
      .then((data) => {
        setItems(data.users);
        console.log(data);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, []);

  //Get a specific customer
  const getData = async (id) => {
    try {
      const response = await fetch(`${BASE_URL}/getuserdetails/${id}`, {
        method: "GET",
        //  credentials: "include",
      });

      if (!response.ok) {
        throw new Error("Error retrieving user details");
      }

      const data = await response.json();
      console.log(data);
      console.log(formData);
      setFormData(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getData(activeId);
  }, [activeId]);

  const viewCustomerHandler = (id) => {
    setOpenForm(true);
    setActiveId(id);
    console.log("hello");
  };

  const closeViewFormHandler = () => {
    setOpenForm(false);
  };

  //Add a customer
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const input = {
        firstname: firstname,
        lastname: lastname,
        email: email,
        password: password,
        phone: phone,
      };

      const res = await fetch(`${BASE_URL}/createuser`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(input),
      });

      if (res.ok) {
        console.log("user created");

        setFirstname("");
        setLastname("");
        setEmail("");
        setPhone("");
        setPassword("");
      }
    } catch (e) {
      console.log("user can't be created!");
    }
  };

  const handleForm = (e) => {
    setForm(!form);
  };

  return (
    <div className="adminPage">
      <Sidebar />
      <div className={classes.customer}>
        <Header />
        <h3>Our Customers</h3>
        <div className={classes.addCategory}>
          <button className={classes.addDiv} onClick={(e) => handleForm(e)}>
            <AddIcon className={classes.addIcon} />
            <h4>Add</h4>
          </button>
          <hr />
        </div>
        <hr />

        <Modal open={form} onClose={() => setForm(false)}>
          <Fade in={form}>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                overflow: "auto",
                maxHeight: "30rem",
                width: "60%", //default width for all screens
                "@media (max-width: 768px)": {
                  width: "85%" /* Width for screens up to 768px */,
                  maxHeight: "85%",
                },
                "@media (max-width: 480px)": {
                  width: "95%" /* Width for screens up to 480px */,
                  maxHeight: "95%",
                },
                bgcolor: "#FFF",
                borderRadius: "15px",
                boxShadow: "0px 3px 8px rgba(0, 0, 0, 0.3)",
              }}
            >
              <div
                className={classes.addCategoryForm}
                // id={form ? classes.formActive : " "}
              >
                <div className={classes["addCategoryForm-Header"]}>
                  <h3>Add Customer</h3>
                  <CloseIcon
                    className={classes.closeIcon}
                    onClick={(e) => setForm(!form)}
                  />
                </div>
                <hr />
                <div className={classes["addCategoryForm-content"]}>
                  <form
                    onSubmit={handleSubmit}
                    // encType="multipart/form-data"
                    method="POST"
                  >
                    <div className={classes.formInput}>
                      <div className={classes.formInput}>
                        <label>Enter First Name *</label>
                        <input
                          className={classes.categoryInput}
                          type="text"
                          value={firstname}
                          name="firstname"
                          required
                          onChange={(e) => setFirstname(e.target.value)}
                        />
                      </div>
                      <div className={classes.formInput}>
                        <label>Enter Last Name*</label>
                        <input
                          className={classes.categoryInput}
                          type="text"
                          value={lastname}
                          name="lastname"
                          required
                          onChange={(e) => setLastname(e.target.value)}
                        />
                      </div>
                      <div className={classes.formInput}>
                        <label>Enter Email*</label>
                        <input
                          className={classes.categoryInput}
                          type="text"
                          value={email}
                          name="email"
                          required
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                      <div className={classes.formInput}>
                        <label>Enter Password*</label>
                        <input
                          className={classes.categoryInput}
                          type="password"
                          value={password}
                          name="password"
                          required
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </div>
                      <div className={classes.formInput}>
                        <label>Enter Mobile Number *</label>
                        <input
                          className={classes.categoryInput}
                          type="text"
                          value={phone}
                          name="phone"
                          required
                          onChange={(e) => setPhone(e.target.value)}
                        />
                      </div>

                      <div className={classes.formControl}>
                        <button onClick={(e) => setForm(!form)}> Close</button>
                        <button type="submit"> Submit</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </Box>
          </Fade>
        </Modal>
        {/* <div
          className={classes.shadow}
          id={form ? classes.shadowActive : " "}
        ></div> */}

        <table>
          <tbody>
            <tr>
              <th>Name</th>
              <th>Mobile</th>
              <th>Email</th>
              <th>Customer Details</th>
            </tr>
            {items.map((val, index) => {
              return (
                <tr key={index}>
                  <td>{val.firstname}</td>
                  <td>{val.phone}</td>
                  <td>{val.email}</td>
                  <td
                    className={classes.viewCustomer}
                    onClick={() => {
                      viewCustomerHandler(val._id);
                    }}
                  >
                    View
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        <Modal open={openForm} onClose={() => setOpenForm(false)}>
          <Fade in={openForm}>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "60%",
                maxHeight: "500px",
                padding: " 20px",
                overflow: "auto",
                bgcolor: "#FFF",
                borderRadius: "15px",
                boxShadow: "0px 3px 8px rgba(0, 0, 0, 0.3)",
              }}
            >
              <div
                className={classes.bookPurchasedPopUp}
                // id={classes["bookPurchasedPopUp-active"]}
              >
                <div
                  className={classes.bookPurchasedPopUp}
                  id={classes["bookPurchasedPopUp-active"]}
                >
                  <div className={classes["PopUp-header"]}>
                    <h3>Customer Details</h3>
                    <CloseIcon
                      className={classes.closeIcon}
                      onClick={closeViewFormHandler}
                    />
                  </div>
                  <div className={classes["PopUp-content"]}>
                    <div className={classes["PopUp-content-row"]}>
                      <h4>Name</h4>
                      <p>{`${formData.firstname} ${formData.lastname}`}</p>
                    </div>
                    <div className={classes["PopUp-content-row"]}>
                      <h4>Mobile</h4>
                      <p>{formData.phone}</p>
                    </div>
                    <div className={classes["PopUp-content-row"]}>
                      <h4>Email</h4>
                      <p>{formData.email}</p>
                    </div>
                  </div>
                </div>
              </div>
            </Box>
          </Fade>
        </Modal>

        <div
          className={classes.shadow}
          id={PopUp ? classes.shadowActive : " "}
        ></div>
      </div>
    </div>
  );
}

export default Customer;
