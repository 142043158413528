import React, { useState } from "react";
import { BASE_URL } from "../../apiConfig";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import classes from "./VendorLogin.module.css";

function VendorLogin() {
  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState({
    email: "",
    password: "",
  });

  // console.log(document.cookie);

  const { email, password } = inputValue;

  function handleOnChange(e) {
    const { name, value } = e.target;
    setInputValue({
      ...inputValue,
      [name]: value,
    });
  }

  const handleError = (err) =>
    toast.error(err, {
      position: "bottom-left",
    });
  const handleSuccess = (msg) =>
    toast.success(msg, {
      position: "bottom-left",
    });

  // FOR VENDOR LOGIN
  async function submitHandler(e) {
    e.preventDefault();
    try {
      const response = await fetch(`${BASE_URL}/vendor/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(inputValue),
        credentials: "include",
      });

      const data = await response.json();
      console.log(data);
      const { success, message } = data;
      if (success) {
        handleSuccess(message);
        setTimeout(() => {
          navigate("/vendor/dashboard");
        }, 500);
      } else {
        handleError(message);
      }
    } catch (error) {
      console.log(error);
    }

    setInputValue({
      username: "",
      password: "",
    });
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.formContainer}>
        <form onSubmit={submitHandler}>
          <h3>Vendor Log In</h3>
          <div className="form-group">
            <input
              onChange={handleOnChange}
              value={email}
              type="email"
              className="form-control"
              name="email"
              placeholder="Enter email"
            />
          </div>
          <div className="form-group">
            <input
              onChange={handleOnChange}
              value={password}
              type="password"
              className="form-control"
              name="password"
              placeholder="Enter password"
            />
          </div>

          <button type="submit" className="btn btn-primary">
            Log In
          </button>
        </form>
      </div>
    </div>
  );
}

export default VendorLogin;
