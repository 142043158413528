import React from "react";

function CookiesSetting() {
  return (
    <>
      {/* <h2>Cookies Setting</h2> */}
      <p>
        Cookies that are strictly necessary to the operation of the vendor
        database, activating key functionality, and implementing security
        measures.
        <ol>
          <li>
            <strong>Performance cookies:</strong> Gather anonymous data to
            assess database performance, pinpoint problem areas, and improve
            user experience.
          </li>
          <li>
            <strong>Functionality Cookies:</strong> Recall user preferences,
            such as the preferred language, to customise their experience while
            browsing the vendor database.
          </li>
          <li>
            <strong>Cookies for targeting:</strong> Deliver pertinent ads based
            on user interactions and interests, improving marketing campaigns
            for event vendors.
          </li>
          <li>
            <strong>Cookies used for analytics:</strong> Track website usage
            data to gather information about user behaviour and enhance the
            functioning and user experience of vendor databases.
          </li>
        </ol>
      </p>
    </>
  );
}

export default CookiesSetting;
