import React from "react";
import { Link } from "react-router-dom";
import Navbar from "../components/Navbar";
import Banner from "../components/Banner";
import Footer from "../components/Footer";
import MiddleBanner from "../components/MiddleBanner";
import classes from "./AboutUs.module.css";
import MessageUsButton from "../components/MessageUsButton";
import aboutBanner from "../../assets/images/aboutBanner.webp";
import GoToTop from "../components/GoToTop";
import { Helmet } from "react-helmet-async";

function AboutUs() {
  const midBannerText = (
    <>
      Party Help Desk lagaye
      <br />
      Har event mein chaar-chand!
    </>
  );
  return (
    <>
      <Helmet>
        <title>
          Party Planner Desk| Your One-Stop Shop for All Your Event Needs
        </title>
        <link rel="canonical" href="https://www.partyhelpdesk.com/aboutus" />
        <meta
          name="description"
          content="We can help you plan and execute any type of party, from a small birthday party to a large corporate event. Contact us today to get started!"
        />
      </Helmet>
      <GoToTop />
      <Navbar />
      <Banner image={aboutBanner} text="About Us" />
      <MessageUsButton />
      <div className={classes.wrapper}>
        <div className={classes.contentDiv}>
          <p>
            A unit of Jenny Blossom India OPC Pvt. Ltd., Party Help Desk AKA PHD
            is your go-to platform for connecting service providers with
            customers for all types of events like
            <Link to="#"> wedding planning & execution, </Link>
            <Link to="#">corporate events, </Link>
            <Link to="#">any kind of puja, </Link>
            <Link to="#">and birthday parties. </Link>
            Committed to making event planning of any sort seamless and
            hassle-free - our gamut of professional service providers ensure
            that every event irrespective of the grandeur level is a success. We
            believe events ought to be larger than life and completely
            unforgettable. In order to realise this purpose, we at
            <Link to="#"> Party Help</Link> Desk work relentlessly toward the
            pursuit of <Link to="#">vendors </Link>
            and service providers, who can be there at your disposal as you
            please.
          </p>
          <p>
            An entrepreneur at heart with a gigantic expertise in the realm of
            management, Ms. Shilpa Srivastava is causing ripples and disrupting
            the online event management aggregator space with her brainchild,
            partyhelpdesk.com. With a vision to give an incredible platform to
            the unorganised sector in the country, she had begun her endeavour
            to facilitate seamless and delightful experiences for both the end
            consumers as well as the service providers.
          </p>
          <p>
            Poised to become India's biggest and one of its kind platform in the
            parties, fun, and leisure space, partyhelpdesk.com AKA PHD would
            cater to NRIs as well, who would plan their parties and events in
            India. Jenny Blossom India, the parent company, under the leadership
            of Shilpa Srivastava, plans to build the brand in Australia as well
            after the India launch, to start with. Gradually, the company has
            plans to expand in other countries as well.
          </p>
          <p>
            We know people are living very frantic lives. They're always on
            their toes, striving to provide and accomplish for themselves and
            their loved ones. In the process, they forget some of the most
            special days of their lives and the ones whom they love very dearly.
            It could be a <Link to="#">birthday party, </Link>your
            <Link to="#"> marriage </Link>
            anniversary, a <Link to="#">puja</Link>, or possibly
            <Link to="#"> corporate event</Link>. These are the moments we at
            <Link> Party Help </Link>
            Desk believe deserve great attention in order to translate into
            something magnificent and downright worthwhile.
          </p>
          <p>
            At <Link to="#">Party Help</Link> Desk, we run by the philosophy -
            <strong>“Party Ki Taiyyari, Bina Magajmari…”</strong> Often does it
            happen, we face tremendous challenges and experience inconvenience
            while planning an important party or an event! This happens due to
            the ambiguity that we have in terms of resources' allocation. We
            become entangled about how to move forward with respect to planning
            and executing the event. Therefore, onboarding and delegating
            seasoned service providers and vendors is an ideal solution.
          </p>
          <p>
            Service providers, viz.
            <Link to="#"> wedding planners, </Link>
            <Link to="#">décor managers, </Link>
            <Link to="#">caterers, </Link>
            <Link to="#">event logistics experts, </Link>
            <Link to="#">photographers, </Link>
            <Link to="#">mehendi artists, </Link>
            <Link to="#">AV specialists </Link>&
            <Link to="#"> music arrangers </Link>
            can bring to the table ingenious solutions that will take your event
            game from good to great.
          </p>
        </div>
        <MiddleBanner text={midBannerText} />
        <div className={classes.contentDiv}>
          <p>
            As a <Link to="#">service provider or vendor</Link>,
            <Link to="#"> Party Help</Link> Desk provides an endeavour that can
            contribute significantly toward massive business success and larger
            market equity. So, whether you are a
            <Link to="#"> wedding organizer, </Link>
            <Link to="#">mehendi artist, </Link>
            <Link to="#">caterer, </Link> or
            <Link to="#"> music arranger, </Link> you have a great chance to
            acquire greater traction across a wide network of audiences for your
            services. The Party Help Desk provides a one-stop solution for
            anything 'event' without an iota of 'Magajmari...'
          </p>
          <p>
            We have an unwavering belief that convenience is 'non-negotiable'
            when it comes to ideating and realising a great function or event.
            This means every time you have something crucial or unmissable to
            celebrate or commemorate, you'll have at hand a gamut of service
            providers who are undisputedly the best in the business across
            India. This, in turn, will give you a great deal of mental
            satisfaction that you thought of something and it went off
            splendidly.
          </p>
          <p>
            Using Party Help Desk's platform is incredibly simple and
            convenient. Clients can start by visiting our user-friendly website
            and can browse through our extensive directory of service providers,
            filtered by location, event type, and specific requirements. Our
            search functionality allows clients to find the perfect match for
            their event needs quickly. Once clients have identified potential
            service providers, they can review their profiles, portfolios, and
            customer reviews to make informed decisions. Our platform provides
            all the necessary information, including pricing, availability, and
            contact details, allowing clients to connect directly with the
            service providers they are interested in.
          </p>
          <p>
            We offer a wide range of services to cater to all types of events,
            <strong> including</strong>
            <Link to="#"> corporate conferences, </Link>
            <Link to="#">weddings, </Link>
            <Link to="#">parties, </Link>
            <Link to="#">exhibitions, </Link>
            <Link to="#">trade shows, </Link>
            and more. Whether you need
            <Link to="#"> event planners, </Link>
            <Link to="#">caterers, </Link>
            <Link to="#">decorators, </Link>
            <Link to="#">photographers, </Link>
            <Link to="#">entertainers, </Link>
            or any other service provider, we have you covered. Our extensive
            network consists of verified and highly skilled professionals who
            are passionate about delivering exceptional services and creating
            unforgettable experiences. In a nutshell, Party Help Desk provides:
          </p>
          <p>
            1. Extensive network that comprises of service providers in the
            <strong> event industry</strong>, ensuring that clients have access
            to a wide range of professionals to choose from. Our rigorous
            verification process guarantees that only qualified and reliable
            individuals or companies are included in our directory.
          </p>
          <p>
            2. Convenience and efficiency across the platform with a streamlined
            approach towards services for any event. Thus, instead of spending
            hours researching and contacting multiple service providers
            individually, clients can find all the information they need in one
            place, making the selection process much more efficient.
          </p>
          <p>
            3. We understand the importance of quality when it comes to
            <strong> events</strong>. That's why we carefully vet all our
            service providers to ensure that they meet our high standards. We
            encourage clients to provide feedback and reviews, allowing us to
            continually improve and maintain the quality of our service
            providers.
          </p>
          <p>
            4. We believe that every event is unique, and our platform allows
            clients to personalize their events by choosing service providers
            that align with their vision and requirements. Whether it's a
            specific cuisine for catering, a particular style of photography, or
            a theme for decor, our platform helps clients find the perfect
            match.
          </p>
          <p>
            5. Party Help Desk is not just a platform; we are your partners in
            event planning. Our dedicated customer support team is available to
            assist clients throughout the process, answering any questions and
            resolving any issues that may arise. Whether you are planning a
            small gathering or a large-scale event, Party Help Desk is here to
            help. Our platform provides a seamless experience, connecting you
            with top-notch service providers to make your event a memorable
            success. Join us today and discover the convenience and expertise
            that Party Help Desk has to offer. Let us take care of the details
            while you focus on enjoying your event to the fullest.
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default AboutUs;
