import React, { useState, useEffect } from "react";
import { BASE_URL } from "../../apiConfig";
import classes from "./Vendor.module.css";
import Header from "./Header";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import Table from "./TableVendor";
import Sidebar from "./Sidebar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Modal } from "@mui/material";
import Fade from "@mui/material/Fade";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function Vendor() {
  const [items, setItems] = useState([]);

  const [categoryGroup, setCategoryGroup] = useState([]);

  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedSubcategories, setSelectedSubcategories] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [saveSubcats, setSaveSubcats] = useState([]);
  const [saveCats, setSaveCats] = useState([]);
  const [activeCatId, setActiveCatId] = useState("");

  const [selectedItem, setSelectedItem] = useState("");
  const [selectedItemId, setSelectedItemId] = useState("");

  const handleClickOpen = () => {
    setOpenAddCategoryModal(true);
  };

  const handleClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setOpenAddCategoryModal(false);
    }
  };

  const [openAddCategoryModal, setOpenAddCategoryModal] = useState(false);

  ///////////////////////////////////////////////////
  useEffect(() => {
    //fetch all categories and subcategories from backend
    const fetchItems = async () => {
      try {
        const categoriesResponse = await fetch(`${BASE_URL}/getcategory`);

        if (!categoriesResponse.ok) {
          throw new Error("Error fetching items");
        }

        const data1 = await categoriesResponse.json();

        setCategories(data1.category);

        //fetch all subcategories from backend
        const subcategoriesResponse = await fetch(`${BASE_URL}/subcategories`);

        if (!subcategoriesResponse.ok) {
          throw new Error("Error fetching items");
        }

        const data2 = await subcategoriesResponse.json();
        setSubcategories(data2);
        console.log(data2);
      } catch (error) {
        console.error("Error fetching items:", error);
      }
    };
    fetchItems();
  }, []);

  const handleCategoryChange = (event) => {
    // setSaveSubcats([])
    setSelectedSubcategories([]);
    const selectedItemId = event.target.value;

    setSelectedItemId(selectedItemId);
    const selectedItem = categories.find((item) => item._id === selectedItemId);

    setSubcategories(selectedItem.subcategories);
    console.log(subcategories);
    setSelectedCategory(event.target.value);
  };

  const handleSubcategoryChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedSubcategories(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  console.log(saveCats);
  const saveCatId = (catId) => {
    setActiveCatId(catId);
  };

  const saveSubcatsHandler = (e, subcatId) => {
    console.log("hello");
    console.log(e.target.checked);
    console.log(subcatId);

    if (e.target.checked === true) {
      setSaveSubcats((prevValue) => [...prevValue, subcatId]);
    } else if (e.target.checked === false) {
      setSaveSubcats((prevValue) => {
        return prevValue.filter((id) => {
          return id !== subcatId;
        });
      });
    }
  };

  const handleCategoriesSelection = (event, reason) => {
    if (reason !== "backdropClick") {
      setOpenAddCategoryModal(false);
    }

    setSaveCats((prevValue) => [...prevValue, activeCatId]);
    setSelectedItem("");
    // setSubcategoriesSelected([]);

    const finalCategory = categories.find((cat) => {
      return cat._id === selectedCategory;
    });

    setCategoryGroup((prevValue) => [
      ...prevValue,
      {
        categoryName: finalCategory.categoryName,
        subCatNames: [selectedSubcategories],
      },
    ]);

    setSelectedCategory("");
    setSelectedSubcategories([]);
  };

  ///////////////////////////////////////////////////////////

  useEffect(() => {
    fetchItems();
  }, []);

  const fetchItems = () => {
    fetch(`${BASE_URL}/getcategory`)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Error fetching items");
        }
      })
      .then((data) => {
        setItems(data.category);
        console.log(data);
      })
      .catch((error) => {
        console.error("Error fetching items:", error);
      });
  };

  const [categoriesSelected, setCategoriesSelected] = useState("");
  const [subcategoriesSelected, setSubcategoriesSelected] = useState("");
  const [planSelected, setPlanSelected] = useState("");
  const [partnerName, setPartnerName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [address, setAddress] = useState("");
  const [websiteURL, setWebsiteURL] = useState("");
  const [instaLink, setInstaLink] = useState("");
  const [fbLink, setFbLink] = useState("");
  const [gstNumber, setGstNumber] = useState("");
  const [pincode, setPincode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [whatsappNumber, setWhatsappNumber] = useState("");
  const [projectsNum, setProjectsNum] = useState("");
  const [businessYears, setBusinessYears] = useState("");
  const [city, setCity] = useState("");
  const [email, setEmail] = useState("");
  const [additionalInfo, setAdditionalInfo] = useState("");
  const [overview, setOverview] = useState("");
  const [startingPrice, setStartingPrice] = useState("");
  const [maximumPrice, setMaximumPrice] = useState("");
  const [password, setPassword] = useState("");

  const [selectedFile1, setSelectedFile1] = useState(null);
  const [selectedFile2, setSelectedFile2] = useState(null);
  const [selectedFile3, setSelectedFile3] = useState(null);
  const [selectedFile4, setSelectedFile4] = useState(null);
  const [selectedFile5, setSelectedFile5] = useState(null);
  const [selectedFile6, setSelectedFile6] = useState(null);

  const [form, setForm] = useState(false);

  const handleForm = (e) => {
    setForm(!form);
  };

  const handleFileChange1 = (e) => {
    const fileInput = e.target;
    if (validateFile(fileInput, 2, "image")) {
      setSelectedFile1(fileInput.files[0]);
    }
  };

  const handleFileChange2 = (e) => {
    const fileInput = e.target;
    if (validateFile(fileInput, 2, "image")) {
      setSelectedFile2(fileInput.files[0]);
    }
  };

  const handleFileChange3 = (e) => {
    const fileInput = e.target;
    if (validateFile(fileInput, 2, "image")) {
      setSelectedFile3(fileInput.files[0]);
    }
  };

  const handleFileChange4 = (e) => {
    const fileInput = e.target;
    if (validateFile(fileInput, 2, "image")) {
      setSelectedFile4(fileInput.files[0]);
    }
  };

  const handleFileChange5 = (e) => {
    const fileInput = e.target;
    if (validateFile(fileInput, 10, "video")) {
      setSelectedFile5(fileInput.files[0]);
    }
  };
  const handleFileChange6 = (e) => {
    const fileInput = e.target;
    if (validateFile(fileInput, 10, "video")) {
      setSelectedFile6(fileInput.files[0]);
    }
  };

  // VALIDATE FILSE SIZE UPLOADED BY VENDOR
  function validateFile(fileInput, maxSize, fileType) {
    const file = fileInput.files[0];
    const fileSize = file.size / 1024 / 1024; // Convert to MB

    if (fileSize > maxSize) {
      alert(`The ${fileType} size exceeds the limit of ${maxSize}MB.`);
      fileInput.value = ""; // Clear the file input
      return false;
    }

    return true;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const formData = new FormData();
      formData.append("categoriesSelected", saveCats);
      formData.append("subcategoriesSelected", saveSubcats);
      formData.append("planSelected", planSelected);
      formData.append("partnerName", partnerName);
      formData.append("companyName", companyName);
      formData.append("address", address);
      formData.append("pincode", pincode);
      formData.append("phoneNumber", phoneNumber);
      formData.append("whatsappNumber", whatsappNumber);
      formData.append("projectsNum", projectsNum);
      formData.append("businessYears", businessYears);
      formData.append("city", city);
      formData.append("email", email);
      formData.append("additionalInfo", additionalInfo);
      formData.append("overview", overview);
      formData.append("password", password);
      formData.append("websiteURL", websiteURL);
      formData.append("instaLink", instaLink);
      formData.append("fbLink", fbLink);
      formData.append("gstNumber", gstNumber);
      formData.append("startingPrice", startingPrice);
      formData.append("maximumPrice", maximumPrice);

      formData.append("image1", selectedFile1);
      formData.append("image2", selectedFile2);
      formData.append("image3", selectedFile3);
      formData.append("image4", selectedFile4);
      formData.append("video1", selectedFile5);
      formData.append("video2", selectedFile6);

      const response = await fetch(`${BASE_URL}/vendor/upload`, {
        method: "POST",
        body: formData,
        // headers: {
        //   "Content-Type": "multipart/form-data",
        // },
      });
      if (!response.ok) {
        throw new Error("Failed to register vendor");
      }
      console.log("data uploaded successfully!");
    } catch (e) {
      console.log("Error uploading data:", e);
    }
  };

  return (
    <div className="adminPage">
      <Sidebar />
      <div className={classes.vendor}>
        <Header />
        <div className={classes.addVendor}>
          <h3>All Vendors</h3>
          {/* <button className={classes.addDiv} onClick={(e) => handleForm(e)}>
            <AddIcon className={classes.addIcon} />
            <h4>Add</h4>
          </button> */}
        </div>
        <hr />

        <Modal open={form} onClose={() => setForm(false)}>
          <Fade in={form}>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                overflow: "auto",
                maxHeight: "30rem",
                width: "80%", //default width for all screens
                "@media (max-width: 768px)": {
                  width: "85%" /* Width for screens up to 768px */,
                  maxHeight: "85%",
                },
                "@media (max-width: 480px)": {
                  width: "95%" /* Width for screens up to 480px */,
                  maxHeight: "95%",
                },
                bgcolor: "#FFF",
                borderRadius: "15px",
                boxShadow: "0px 3px 8px rgba(0, 0, 0, 0.3)",
              }}
            >
              <div
                className={classes.addVendorForm}
                // id={form ? classes.formActive : " "}
              >
                <div className={classes["addVendorForm-Header"]}>
                  <h3>Add Vendor</h3>
                  <CloseIcon
                    className={classes.closeIcon}
                    onClick={(e) => setForm(!form)}
                  />
                </div>
                <hr />
                <label> Select a Plan</label>
                <br />
                <div className={classes.selectVendor} value={planSelected}>
                  <input
                    type="radio"
                    name="planSelected"
                    value="basic"
                    checked={planSelected === "basic"}
                    onChange={(e) => setPlanSelected(e.target.value)}
                  />
                  Basic (₹499 + 18% GST)
                  <input
                    type="radio"
                    name="planSelected"
                    value="premium"
                    checked={planSelected === "premium"}
                    onChange={(e) => setPlanSelected(e.target.value)}
                  />
                  Premium (₹799 + 18% GST)
                </div>

                <div className={classes["addVendorForm-content"]}>
                  <h3>Vendor's Information</h3>
                  <form
                    onSubmit={handleSubmit}
                    encType="multipart/form-data"
                    method="POST"
                  >
                    <div className="inputBox"></div>
                    <div className="allCatWrapper">
                      Service(s) offered*
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div className="allCategoriesDiv">
                          {categoriesSelected.length < 0 ? (
                            <p>Choose Categories...</p>
                          ) : (
                            <ol>
                              {categoryGroup.map((group) => (
                                <li>
                                  <p>
                                    {group.categoryName}:{" "}
                                    {group.subCatNames.join(", ")}
                                  </p>
                                </li>
                              ))}
                            </ol>
                          )}
                        </div>
                        <button type="button" onClick={handleClickOpen}>
                          Add Categories +
                        </button>
                      </div>
                    </div>

                    <Dialog
                      disableEscapeKeyDown
                      open={openAddCategoryModal}
                      onClose={handleClose}
                    >
                      <DialogTitle>Select categories</DialogTitle>
                      <DialogContent>
                        <Box
                          component="form"
                          sx={{ display: "flex", flexWrap: "wrap" }}
                        >
                          <FormControl sx={{ m: 1, minWidth: 200 }}>
                            <InputLabel id="category-select-label">
                              Category
                            </InputLabel>
                            <Select
                              labelId="category-select-label"
                              id="category-select"
                              name="categoriesSelected"
                              value={selectedCategory}
                              onChange={handleCategoryChange}
                              input={<OutlinedInput label="Category" />}
                            >
                              {categories.map((category) => (
                                <MenuItem
                                  key={category._id}
                                  value={category._id}
                                  onClick={() => saveCatId(category._id)}
                                >
                                  {category.categoryName}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>

                          <FormControl sx={{ m: 1, width: 300 }}>
                            <InputLabel id="subcat-select-label">
                              Sub-Category
                            </InputLabel>
                            <Select
                              labelId="subcat-select-label"
                              id="subcat-select-checkbox"
                              multiple
                              value={selectedSubcategories}
                              onChange={handleSubcategoryChange}
                              input={<OutlinedInput label="Sub-Category" />}
                              renderValue={(selected) => selected.join(", ")}
                              MenuProps={MenuProps}
                            >
                              {subcategories.map((subcategory) => (
                                <MenuItem
                                  key={subcategory._id}
                                  value={subcategory.subcategoryName}
                                  className="menuItemVendor"
                                >
                                  <input
                                    type="checkbox"
                                    id={subcategory._id}
                                    checked={
                                      selectedSubcategories.indexOf(
                                        subcategory.subcategoryName
                                      ) > -1
                                    }
                                    onChange={(e) =>
                                      saveSubcatsHandler(e, subcategory._id)
                                    }
                                  />
                                  <label htmlFor={subcategory._id}>
                                    {subcategory.subcategoryName}
                                  </label>
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Box>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button onClick={handleCategoriesSelection}>Ok</Button>
                      </DialogActions>
                    </Dialog>

                    <div className={classes.formInput}>
                      <label>Enter Partner Name *</label>
                      <input
                        className={classes.vendorInput}
                        type="text"
                        value={partnerName}
                        name="partnerName"
                        required
                        onChange={(e) => setPartnerName(e.target.value)}
                      />
                    </div>
                    <div className={classes.formInput}>
                      <label>Enter Company Name *</label>
                      <input
                        className={classes.vendorInput}
                        type="text"
                        value={companyName}
                        name="companyName"
                        required
                        onChange={(e) => setCompanyName(e.target.value)}
                      />
                    </div>

                    <div className={classes.formInput}>
                      <label>Enter Full Address*</label>
                      <input
                        className={classes.vendorInput}
                        type="text"
                        value={address}
                        name="address"
                        required
                        onChange={(e) => setAddress(e.target.value)}
                      />
                    </div>
                    <div className={classes.formInput}>
                      <label>Enter City*</label>
                      <input
                        className={classes.vendorInput}
                        type="text"
                        value={city}
                        name="city"
                        required
                        onChange={(e) => setCity(e.target.value)}
                      />
                    </div>

                    <div className={classes.formInput}>
                      <label>Enter Pincode*</label>
                      <input
                        className={classes.vendorInput}
                        type="text"
                        value={pincode}
                        name="pincode"
                        required
                        onChange={(e) => setPincode(e.target.value)}
                      />
                    </div>
                    <div className={classes.formInput}>
                      <label> Number of Projects till date *</label>
                      <input
                        className={classes.vendorInput}
                        type="number"
                        value={projectsNum}
                        name="projectsNum"
                        required
                        onChange={(e) => setProjectsNum(e.target.value)}
                      />
                    </div>
                    <div className={classes.formInput}>
                      <label>Years in business*</label>
                      <input
                        className={classes.vendorInput}
                        type="number"
                        value={businessYears}
                        name="businessYears"
                        required
                        onChange={(e) => setBusinessYears(e.target.value)}
                      />
                    </div>
                    <div className={classes.formInput}>
                      <label>Enter Mobile Number *</label>
                      <input
                        className={classes.vendorInput}
                        type="number"
                        value={phoneNumber}
                        name="phoneNumber"
                        required
                        onChange={(e) => setPhoneNumber(e.target.value)}
                      />
                    </div>
                    <div className={classes.formInput}>
                      <label> Enter Whatsapp number *</label>
                      <input
                        className={classes.vendorInput}
                        type="number"
                        value={whatsappNumber}
                        name="whatsappNumber"
                        required
                        onChange={(e) => setWhatsappNumber(e.target.value)}
                      />
                    </div>

                    <div className={classes.formInput}>
                      <label>Enter Email *</label>
                      <input
                        className={classes.vendorInput}
                        type="text"
                        value={email}
                        name="email"
                        required
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <div className={classes.formInputBig}>
                      <label htmlFor="overview">Overview</label>
                      <textarea
                        id={classes.overview}
                        rows={4}
                        cols={40}
                        className={classes.categoryInput}
                        value={overview}
                        name="overview"
                        required
                        onChange={(e) => setOverview(e.target.value)}
                      ></textarea>
                    </div>

                    <div className={classes.formInputBig}>
                      <label htmlFor="additionalInfo">
                        Additional Information
                      </label>
                      <textarea
                        id={classes.additionalInfo}
                        rows={4}
                        cols={40}
                        className={classes.categoryInput}
                        value={additionalInfo}
                        name="additionalInfo"
                        required
                        onChange={(e) => setAdditionalInfo(e.target.value)}
                      ></textarea>
                    </div>

                    <div className={classes.formInput}>
                      <label>Enter Password *</label>
                      <input
                        className={classes.vendorInput}
                        type="password"
                        value={password}
                        name="password"
                        required
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>
                    <div className={classes.formInput}>
                      <label>Enter Website URL</label>
                      <input
                        className={classes.vendorInput}
                        type="text"
                        value={websiteURL}
                        name="websiteURL"
                        // required
                        onChange={(e) => setWebsiteURL(e.target.value)}
                      />
                    </div>
                    <div className={classes.formInput}>
                      <label>Enter Instagram Page Link</label>
                      <input
                        className={classes.vendorInput}
                        type="text"
                        value={instaLink}
                        name="instaLink"
                        // required
                        onChange={(e) => setInstaLink(e.target.value)}
                      />
                    </div>
                    <div className={classes.formInput}>
                      <label>Enter Facebook Page Link</label>
                      <input
                        className={classes.vendorInput}
                        type="text"
                        value={fbLink}
                        name="fbLink"
                        // required
                        onChange={(e) => setFbLink(e.target.value)}
                      />
                    </div>
                    <div className={classes.formInput}>
                      <label>Enter GST Number</label>
                      <input
                        className={classes.vendorInput}
                        type="text"
                        value={gstNumber}
                        name="gstNumber"
                        // required
                        onChange={(e) => setGstNumber(e.target.value)}
                      />
                    </div>
                    <div className={classes.formInput}>
                      <label>Enter Starting Price*</label>
                      <input
                        className={classes.vendorInput}
                        type="number"
                        value={startingPrice}
                        name="startingPrice"
                        required
                        onChange={(e) => setStartingPrice(e.target.value)}
                      />
                    </div>
                    <div className={classes.formInput}>
                      <label>Enter Maximum Price*</label>
                      <input
                        className={classes.vendorInput}
                        type="number"
                        value={maximumPrice}
                        name="maximumPrice"
                        required
                        onChange={(e) => setMaximumPrice(e.target.value)}
                      />
                    </div>

                    {planSelected === "premium" && (
                      <>
                        <div className={classes.formInput}>
                          <label>Upload small image1*</label>
                          <input
                            type="file"
                            required
                            name="image1"
                            onChange={handleFileChange1}
                          />
                        </div>
                        <div className={classes.formInput}>
                          <label>Upload small image2*</label>
                          <input
                            type="file"
                            name="image2"
                            required
                            onChange={handleFileChange2}
                          />
                        </div>
                        <div className={classes.formInput}>
                          <label>Upload small image3*</label>
                          <input
                            type="file"
                            name="image3"
                            required
                            onChange={handleFileChange3}
                          />
                        </div>
                        <div className={classes.formInput}>
                          <label>Upload small image4*</label>
                          <input
                            type="file"
                            name="image4"
                            required
                            onChange={handleFileChange4}
                          />
                        </div>
                        <div className={classes.formInput}>
                          <label>Upload a video1*</label>
                          <input
                            type="file"
                            name="video1"
                            id="video1"
                            required
                            onChange={handleFileChange5}
                          />
                        </div>
                        <div className={classes.formInput}>
                          <label>Upload a video2*</label>
                          <input
                            type="file"
                            name="video2"
                            id="video2"
                            required
                            onChange={handleFileChange6}
                          />
                        </div>
                      </>
                    )}
                    <div className={classes.formControl}>
                      <button onClick={(e) => setForm(!form)}> Close</button>
                      <button type="submit" onClick={(e) => setForm(!form)}>
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </Box>
          </Fade>
        </Modal>
        <Table />

        {/* <div
          className={classes.shadow}
          id={form ? classes.shadowActive : " "}
        ></div> */}
      </div>
    </div>
  );
}

export default Vendor;
