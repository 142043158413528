import React, { useState, useEffect } from "react";
import { BASE_URL } from "../../../apiConfig";
import "./UserContainer.css";
import { ToastContainer, toast } from "react-toastify";

function UserContainer() {
  const [active, setActive] = useState("account");

  function handleActive(name) {
    setActive(name);
  }

  const [user, setUser] = useState({
    firstname: "",
    lastname: "",
    phone: "",
    email: "",
    password: "",
    newPassword: "",
    confirmPassword: "",
    plot: "",
    landmark: "",
    street: "",
    city: "",
    state: "",
    pincode: "",
  });

  useEffect(() => {
    fetch(`${BASE_URL}/user/details`, {
      method: "GET",
      credentials: "include",
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Error fetching user data");
        }
      })
      .then((data) => {
        console.log(data);
        setUser(data);
      })
      .catch((error) => {
        console.error("Error fetching user data", error);
      });
  }, []);

  const handleChange = (event) => {
    setUser({ ...user, [event.target.name]: event.target.value });
  };

  const handleUpdate = (event) => {
    event.preventDefault();

    fetch(`${BASE_URL}/user/updateDetails`, {
      method: "POST",
      body: JSON.stringify(user),
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
      .then((response) => {
        if (response.ok) {
          console.log("User updated successfully");
        } else {
          console.error("Error updating user");
        }
      })
      .catch((error) => {
        console.error("Error updating user", error);
      });
  };

  // FOR USER LOGOUT
  const logoutHandler = () => {
    fetch(`${BASE_URL}/user/logout`, {
      method: "POST",
      credentials: "include",
    })
      .then((response) => {
        // Check if the logout was successful
        if (response.status === 200) {
          toast.success("Logged out successfully!", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "colored",
          });
          console.log("logged out");
          setTimeout(() => {
            window.location.href = "/";
          }, 1200);
        } else {
          // Handle logout error
          console.error("Logout failed.");
          toast.error("Failed to logout!", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "colored",
          });
        }
      })
      .catch((error) => {
        // Handle network or other errors
        toast.error("Failed to logout!", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "colored",
        });
        console.error("Error during logout:", error);
      });
  };

  return (
    <div className="userContainer">
      <div className="leftMenu">
        <button
          className={active === "account" ? "activeButton" : ""}
          onClick={() => handleActive("account")}
        >
          Account
        </button>
        <button
          className={active === "address" ? "activeButton" : ""}
          onClick={() => handleActive("address")}
        >
          Address
        </button>
        {/* <button
          className={active === "order" ? "activeButton" : ""}
          onClick={() => handleActive("order")}
        >
          Orders
        </button> */}
        <button onClick={logoutHandler}>Logout</button>
      </div>
      <div
        style={
          active === "account" ? { display: "block" } : { display: "none" }
        }
        className="rightContainer account"
      >
        <h3>Account Details</h3>
        <hr />
        <form onSubmit={handleUpdate}>
          <div className="inputFieldFirstName">
            <label htmlFor="firstName">First Name</label>
            <input
              type="text"
              id="firstname"
              name="firstname"
              value={user.firstname}
              onChange={handleChange}
            />
          </div>
          <div className="inputFieldLastName">
            <label htmlFor="lastName">Last Name</label>
            <input
              type="text"
              id="lastname"
              name="lastname"
              value={user.lastname}
              onChange={handleChange}
            />
          </div>
          <div className="inputFieldPhone">
            <label htmlFor="phone">Phone </label>
            <input
              type="number"
              max={9999999999}
              id="phone"
              name="phone"
              value={user.phone}
              onChange={handleChange}
            />
          </div>
          <div className="inputFieldEmail">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={user.email}
              onChange={handleChange}
            />
          </div>

          <h3>Change Password</h3>

          <div className="inputFieldCurrPass">
            <label htmlFor="currPassword">Current Password</label>
            <input
              type="password"
              id="password"
              name="password"
              onChange={handleChange}
            />
          </div>
          <div className="inputFieldNewPass">
            <label htmlFor="newPassword">New Password</label>
            <input
              type="password"
              id="newPassword"
              name="newPassword"
              onChange={handleChange}
            />
          </div>
          <div className="inputFieldConfirmNewPass">
            <label htmlFor="ConfirmPassword">Confirm New Password</label>
            <input
              type="password"
              id="ConfirmewPassword"
              name="confirmPassword"
              onChange={handleChange}
            />
          </div>

          <button className="saveChanges" type="submit">
            Save Changes
          </button>
        </form>
      </div>
      <div
        style={
          active === "address" ? { display: "block" } : { display: "none" }
        }
        className="rightContainer address"
      >
        <h3>Address Details</h3>
        <hr />
        <form autoComplete="off" onSubmit={handleUpdate}>
          <div className="inputFieldPlot">
            <label htmlFor="plot">Plot </label>
            <input
              type="text"
              id="plot"
              name="plot"
              value={user.plot}
              onChange={handleChange}
            />
          </div>
          <div className="inputFieldStreet">
            <label htmlFor="street">Street </label>
            <input
              type="text"
              id="street"
              name="street"
              value={user.street}
              onChange={handleChange}
            />
          </div>
          <div className="inputFieldLandmark">
            <label htmlFor="landmark">Landmark </label>
            <input
              type="text"
              id="landmark"
              name="landmark"
              value={user.landmark}
              onChange={handleChange}
            />
          </div>
          <div className="inputFieldState">
            <label htmlFor="state">State </label>
            <input
              type="text"
              id="state"
              name="state"
              value={user.state}
              onChange={handleChange}
            />
          </div>
          <div className="inputFieldCity">
            <label htmlFor="city">City </label>
            <input
              type="text"
              id="city"
              name="city"
              value={user.city}
              onChange={handleChange}
            />
          </div>
          <div className="inputFieldZipCode">
            <label htmlFor="zipCode">Zipcode </label>
            <input
              type="number"
              id="pincode"
              name="pincode"
              value={user.pincode}
              onChange={handleChange}
            />
          </div>
          <button className="saveChanges" type="submit">
            Save Changes
          </button>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
}

export default UserContainer;
