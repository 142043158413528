import React from "react";
import classes from "./TableCategory.module.css";
import { BASE_URL } from "../../apiConfig";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import { useState, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Modal, Box } from "@mui/material";
import Fade from "@mui/material/Fade";
import { CKEditor, ckEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

function TableCategory() {
  const [toggledelete, setToggledelete] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isView, setIsView] = useState(false);
  const [openUpdateForm, setOpenUpdateForm] = useState(false);
  const [categoryName, setCategoryName] = useState("");
  const [openEditForm, setOpenEditForm] = useState("");
  const [items, setItems] = useState([]);
  const [activeId, setActiveId] = useState(null);
  const [fetchData, setFetchData] = useState([]);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [filterName, setFilterName] = useState("");
  const [filterData, setFilterData] = useState([]);
  const [updateId, setUpdateId] = useState(null);
  const [fieldToEdit, setFieldToEdit] = useState("");
  const [subcat, setSubcat] = useState([]);
  const [count, setCount] = useState(0);

  const [catName, setCatName] = useState("");
  const [fieldToUpdate, setFieldToUpdate] = useState("");

  useEffect(() => {
    const getCategories = async () => {
      try {
        const response = await fetch(`${BASE_URL}/getcategory`);
        if (!response.ok) {
          throw new Error("Error retrieving categories");
        }
        const data = await response.json();
        console.log(data);
        setItems(data.category);
      } catch (error) {
        console.log(error);
      }
    };

    getCategories();
  }, []);

  const handleFileChange1 = (e) => {
    const file = e.target.files[0];
    setFormData((prevData) => ({ ...prevData, banner: file }));
  };

  const handleFileChange2 = (e) => {
    const file = e.target.files[0];
    setFormData((prevData) => ({ ...prevData, slider: file }));
  };

  useEffect(() => {
    const getSubcategories = async () => {
      try {
        const response = await fetch(`${BASE_URL}/subcategories`);
        if (!response.ok) {
          throw new Error("Error retrieving subcategories");
        }
        const data = await response.json();
        console.log(data);
        setSubcat(data);
      } catch (error) {
        console.log(error);
      }
    };

    getSubcategories();
  }, []);

  const handleToggleVisibility = (id) => {
    setIsVisible(!isVisible);
    setActiveId(id);

    fetch(`${BASE_URL}/getcategories/${id}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Error retrieving subcategories");
        }
        return response.json();
      })
      .then((data) => {
        console.log("hello");
        setFetchData(data.subcategories);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // const handleToggleView = async (filterName) => {
  //   setIsView(!isView);
  //   setFilterName(filterName);

  //   // const getFilteredData = async (filterName) => {//
  //   console.log(filterName);

  //   try {
  //     const response = await fetch(
  //       `${BASE_URL}/vendor/filter?categoryName=${filterName}`
  //     );
  //     if (!response.ok) {
  //       throw new Error("Error retrieving filtered data");
  //     }
  //     const data = await response.json();
  //     setFilterData(data.vendors);
  //     // setCount(data.count)
  //     setCount((prevCounts) => ({
  //       ...prevCounts,
  //       [filterName]: data.count,
  //     }));
  //     console.log(filterData);
  //     console.log(count);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // useEffect(() => {
  //   getFilteredData(filterName);
  // }, [filterName]);

  const handleToggleView = async (filterName) => {
    setIsView(!isView);
    setFilterName(filterName);

    // const getFilteredData = async (filterName) => {//
    console.log(filterName);

    try {
      const response = await fetch(`${BASE_URL}/getvendors/${filterName}`);
      if (!response.ok) {
        throw new Error("Error retrieving filtered data");
      }
      const data = await response.json();
      console.log(data);
      setFilterData(data);
      // setCount(data.count)
      setCount((prevCounts) => ({
        ...prevCounts,
        [filterName]: data.count,
      }));
      console.log(filterData);
      console.log(count);
    } catch (error) {
      console.log(error);
    }
  };

  const viewEditHandler = async (id) => {
    setOpenEditForm(true);
    setUpdateId(id);

    try {
      const response = await fetch(`${BASE_URL}/subcategories/${id}`);
      if (!response.ok) {
        throw new Error("Error retrieving subcategory data");
      }
      const existingData = await response.json();
      console.log(existingData);
      setFieldToEdit(existingData.subcategoryName);
    } catch (error) {
      console.log(error);
    }
  };

  const closeEditHandler = () => {
    setOpenEditForm(false);
  };

  const handleEdit = async (e) => {
    e.preventDefault();
    console.log(fieldToEdit);
    try {
      const response = await fetch(`${BASE_URL}/subcategories/${updateId}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ subcategoryName: fieldToEdit }),
      });

      if (!response.ok) {
        throw new Error("Error updating subcategory");
      }

      const data = await response.json();
      console.log(data);
    } catch (error) {
      console.log(error);
    }

    setOpenEditForm(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    console.log(formData);
  };

  const viewUpdateCategoryHandler = async (id) => {
    setOpenUpdateForm(true);
    setSelectedRowId(id);
    try {
      const response = await fetch(`${BASE_URL}/getcategories/${id}`);
      if (!response.ok) {
        throw new Error("Error retrieving category data");
      }
      const existingData = await response.json();
      console.log(existingData);
      setFormData(existingData);
      // setFieldToUpdate(existingData.categoryName);
    } catch (error) {
      console.log(error);
    }
  };

  const closeCategoryHandler = () => {
    setOpenUpdateForm(false);
  };

  const [formData, setFormData] = useState({
    categoryName: "",
    slider: "",
    banner: "",
    content: "",
  });
  ///////////////////////////////////////////////////////////////////////
  const [subcategoryNames, setSubcategoryNames] = useState([]);

  const handleAddSubcategory = () => {
    setSubcategoryNames([...subcategoryNames, ""]);
  };

  const handleSubcategoryChange = (index, value) => {
    const updatedSubcategoryNames = [...subcategoryNames];
    updatedSubcategoryNames[index] = value;
    setSubcategoryNames(updatedSubcategoryNames);
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
 
    const formDataToSend = new FormData();
    formDataToSend.append("categoryName", formData.categoryName);
    formDataToSend.append("slider", formData.slider);
    formDataToSend.append("banner", formData.banner);
    formDataToSend.append("content", formData.content);

    try {
      const response = await fetch(`${BASE_URL}/getcategory/${selectedRowId}`, {
        method: "POST",
        body: formDataToSend,
        // headers: {
        //   "Content-Type": "multipart/form-data",
        // },
      });

      if (!response.ok) {
        throw new Error("Error updating category");
      }
      const categoryData = await response.json();
      const categoryId = categoryData._id;
      // console.log(categoryId);

      const subcategoryResponses = await Promise.all(
        subcategoryNames.map(async (name) => {
          const response = await fetch(`${BASE_URL}/addsubcategories`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              subcategoryName: name,
              categoryId,
            }),
          });

          if (!response.ok) {
            throw new Error("Failed to create subcategory");
          }
          // console.log(subcategoryResponses);
          return response.json();
        })
      );
    } catch (error) {
      console.log(error);
    }
    setOpenUpdateForm(false);
  };
  ////////////////////////////////////////////////////////
  const [deletionId, setDeletionId] = useState("");
  function toggleDelete(id) {
    setToggledelete(true);
    setDeletionId(id);
  }

  const deleteCategory = async (event) => {
    event.preventDefault();
    console.log(deletionId);
    console.log(catName);

    setItems((prevData) => prevData.filter((item) => item._id !== deletionId));

    try {
      console.log("delete");
      const response = await fetch(
        `${BASE_URL}/getcategory/delete/${deletionId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ catName }),
          // credentials: "include",
        }
      );

      if (response.status === 200) {
        // setItems((prevData) => prevData.filter((item) => item._id !== deletionId));
        console.log("Category deleted!!!");
      }

      if (response.status === 500) {
        throw new Error("Error deleting category");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteSubcategory = async (id) => {
    setFetchData((prevData) => prevData.filter((val) => val._id !== id));
    try {
      const response = await fetch(`${BASE_URL}/subcategories/delete/${id}`, {
        method: "POST",
        credentials: "include",
      });

      if (!response.ok) {
        throw new Error("Error deleting subcategory");
      }

      console.log("Subcategory deleted!");
      //setSubcat((prevData) => prevData.filter((val) => val._id !== id));
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={classes.table}>
      <table className={`${classes["content-table"]} ${classes["main-table"]}`}>
        <tbody>
          <tr>
            <th>Category Name</th>
            <th>Sub-Categories </th>
            <th>No. of vendors </th>
            <th>Action</th>
          </tr>
          {items.map((val, index) => {
            return (
              <tr key={val._id}>
                <td>{val.categoryName}</td>
                <td>
                  <VisibilityIcon
                    fontSize="small"
                    onClick={() => handleToggleVisibility(val._id)}
                  />
                </td>
                <td>
                  {count[val.categoryName] || ""}
                  <VisibilityIcon
                    fontSize="small"
                    onClick={() => handleToggleView(val.categoryName)}
                  />
                </td>
                <td>
                  <div className={classes.action}>
                    <EditIcon
                      fontSize="small"
                      onClick={() => {
                        viewUpdateCategoryHandler(val._id);
                      }}
                    />
                    <DeleteIcon
                      fontSize="small"
                      onClick={() => toggleDelete(val._id)}
                    />

                    {/* DELETE CATEGORY CONFIRMATION DIALOGUE BOX */}
                    <Modal
                      open={toggledelete}
                      onClose={() => setToggledelete(false)}
                    >
                      <Fade in={toggledelete}>
                        <Box
                          sx={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            bgcolor: "#FFF",
                            // borderRadius: "15px",
                            // boxShadow: "0px 3px 8px rgba(0, 0, 0, 0.3)",
                          }}
                        >
                          <div className={classes.delete}>
                            <h3> Are you sure you want to delete?</h3>

                            <form onSubmit={deleteCategory}>
                              <label>Enter Category Name</label>
                              <br />
                              <input
                                type="text"
                                value={catName}
                                name="catName"
                                onChange={(e) => setCatName(e.target.value)}
                              />
                              <br />
                              <div className={classes.deleteBtn}>
                                <button
                                  className="btn btn-danger"
                                  type="submit"
                                  onClick={() => setToggledelete(false)}
                                >
                                  Delete
                                </button>
                              </div>
                            </form>
                          </div>
                        </Box>
                      </Fade>
                    </Modal>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      {/* VIEW SUBCATEGORY MODAL */}
      <Modal open={isVisible} onClose={() => setIsVisible(false)}>
        <Fade in={isVisible}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              maxHeight: "30rem",
              "@media (max-width: 768px)": {
                maxHeight: "85%" /* Width for screens up to 768px */,
              },
              "@media (max-width: 480px)": {
                maxHeight: "95%" /* Width for screens up to 480px */,
              },
              overflow: "auto",
              bgcolor: "#FFF",
              borderRadius: "15px",
              boxShadow: "0px 3px 8px rgba(0, 0, 0, 0.3)",
            }}
          >
            <table className={classes["content-table"]}>
              <tbody>
                <tr>
                  <th>Subcategory Name</th>
                  <th>Action</th>
                </tr>
                {fetchData.map((val, index) => {
                  return (
                    <tr key={index}>
                      <td>{val.subcategoryName}</td>
                      <td>
                        <div className={classes.action}>
                          <EditIcon
                            fontSize="small"
                            onClick={() => viewEditHandler(val._id)}
                          />
                          <DeleteIcon
                            fontSize="small"
                            onClick={() => {
                              deleteSubcategory(val._id);
                            }}
                          />
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Box>
        </Fade>
      </Modal>

      {/* VIEW VENDORS FOR A SUBCATEGORY MODAL */}
      <Modal open={isView} onClose={() => setIsView(false)}>
        <Fade in={isView}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              overflow: "auto",
              maxHeight: "30rem",
              width: "60%", //default width for all screens
              "@media (max-width: 768px)": {
                width: "85%" /* Width for screens up to 768px */,
                maxHeight: "85%",
              },
              "@media (max-width: 480px)": {
                width: "95%" /* Width for screens up to 480px */,
                maxHeight: "95%",
              },
              bgcolor: "#FFF",
              borderRadius: "15px",
              boxShadow: "0px 3px 8px rgba(0, 0, 0, 0.3)",
            }}
          >
            <table
              className={classes["content-table"]}
              style={{ width: "100%" }}
            >
              <tbody>
                <tr>
                  <th>Vendor Name</th>
                  <th>Vendor Email</th>
                  <th>Vendor Phone</th>
                </tr>
                {/* <tr>
                  <td>Akash Planners</td>
                  <td>akash@gmail.com</td>
                  <td>8989978767</td>
                </tr>
                <tr>
                  <td>Raj Caterers</td>
                  <td>raj@gmail.com</td>
                  <td>8989978767</td>
                </tr>
                <tr>
                  <td>XYZ Decorators</td>
                  <td>xyz@gmail.com</td>
                  <td>8989978767</td>
                </tr>
                <tr>
                  <td>Diyaz Planners</td>
                  <td>diyaz@gmail.com</td>
                  <td>8989978767</td>
                </tr>
                <tr>
                  <td>Kala Mehndi</td>
                  <td>kala@gmail.com</td>
                  <td>8989978767</td>
                </tr> */}
                {filterData.map((val, index) => {
                  return (
                    <tr key={index}>
                      <td>{val.companyName}</td>
                      <td>{val.email}</td>
                      <td>{val.phoneNumber}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Box>
        </Fade>
      </Modal>

      {/* EDIT CATEGORY FORM */}
      <Modal
        open={openUpdateForm}
        onClose={() => setOpenUpdateForm(false)}
        disableEnforceFocus
      >
        <Fade in={openUpdateForm}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              // width: "60%",
              // maxHeight: "500px",
              maxHeight: "30rem",
              width: "60%", //default width for all screens
              "@media (max-width: 768px)": {
                width: "85%" /* Width for screens up to 768px */,
                maxHeight: "55%",
              },
              "@media (max-width: 480px)": {
                width: "95%" /* Width for screens up to 480px */,
                maxHeight: "65%",
              },
              padding: " 20px",
              overflow: "auto",
              bgcolor: "#FFF",
              borderRadius: "15px",
              boxShadow: "0px 3px 8px rgba(0, 0, 0, 0.3)",
            }}
          >
            <div className={classes["editCategoryForm-Header"]}>
              <h3>Edit Category</h3>
              <CloseIcon
                className={classes.closeIcon}
                onClick={closeCategoryHandler}
              />
            </div>
            <hr />
            <div className={classes["editCategoryForm-content"]}>
              <form onSubmit={handleUpdate}>
                <div className={classes.formInput}>
                  <label>Enter Category Name </label>
                  <input
                    className={classes.categoryInput}
                    type="text"
                    value={formData.categoryName}
                    name="categoryName"
                    // onChange={handleInputChange}
                    // value={fieldToUpdate}
                    // onChange={(e) => setFieldToUpdate(e.target.value)}
                    onChange={handleInputChange}
                  />
                </div>

                {/* <div className={classes.formInput}>
                  <label> Upload Slider Image *</label>
                  <input
                    type="file"
                    name="slider"
                    // required
                    onChange={handleFileChange2}
                  />
                </div> */}
                <div className={classes.formInput}>
                  <label> Upload Slider Image *</label>
                  <input
                    type="file"
                    name="slider"
                    // required
                    onChange={handleFileChange2}
                  />
                </div>
                <div className={classes.formInput}>
                  <label> Upload Banner Image *</label>
                  <input
                    type="file"
                    name="banner"
                    //  required
                    onChange={handleFileChange1}
                  />
                </div>
                {/* <div className={classes.formInput}></div> */}
                <div
                  className={classes.formInputSubcat}
                  // style={{ maxHeight: "10rem", overflow: "auto" }}
                >
                  <label>Add Sub-Categories</label>
                  {subcategoryNames.map((subcategoryName, index) => (
                    <div key={index}>
                      <input
                        type="text"
                        className={classes.vendorInput}
                        value={subcategoryName}
                        onChange={(e) =>
                          handleSubcategoryChange(index, e.target.value)
                        }
                      />
                    </div>
                  ))}
                  <button
                    type="button"
                    className="btn btn-primary"
                    style={{ width: "10rem" }}
                    onClick={handleAddSubcategory}
                  >
                    Add Subcategory
                  </button>
                </div>
                <div className={classes.bottomDiv}>
                  <div
                    // className={classes.formInput}
                    style={{ width: "100%" }}
                    marginBottom="50px"
                  >
                    <label> Upload Content *</label>
                    <CKEditor
                      name="content"
                      editor={ClassicEditor}
                      data={formData.content}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setFormData((prevState) => ({
                          ...prevState,
                          content: data,
                        }));
                      }}
                    />
                  </div>
                  <div className={classes.formControl}>
                    <button
                      className="btn btn-secondary"
                      onClick={closeCategoryHandler}
                      style={{ color: "black" }}
                    >
                      Close
                    </button>
                    <button className="btn btn-success" type="submit">
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </Box>
        </Fade>
      </Modal>

      {/* EDIT SUBCATEGORY FORM */}
      <Modal open={openEditForm} onClose={() => setOpenEditForm(false)}>
        <Fade in={openEditForm}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "60%",
              maxHeight: "500px",
              padding: " 20px",
              overflow: "auto",
              bgcolor: "#FFF",
              borderRadius: "15px",
              boxShadow: "0px 3px 8px rgba(0, 0, 0, 0.3)",
            }}
          >
            <div className={classes["editCategoryForm-Header"]}>
              <h3>Edit Subcategory</h3>
              <CloseIcon
                className={classes.closeIcon}
                onClick={closeEditHandler}
              />
            </div>
            <hr />
            <div className={classes["editCategoryForm-content"]}>
              <form onSubmit={handleEdit}>
                <div className={classes.formInput}>
                  <label>Enter Subcategory Name </label>
                  <input
                    className={classes.categoryInput}
                    type="text"
                    name="subcategoryName"
                    value={fieldToEdit}
                    onChange={(e) => setFieldToEdit(e.target.value)}
                  />
                </div>
                <div className={classes.formControl}>
                  <button
                    className="btn btn-secondary"
                    onClick={closeEditHandler}
                  >
                    Close
                  </button>
                  <button className="btn btn-success" type="submit">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

export default TableCategory;
