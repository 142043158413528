import React from "react";
// import classes from "../../pages/Homepage.module.css";
// import { useNavigate } from "react-router-dom";

function Pricing() {
  
  return (
    <>
      {/* <h2>Pricing</h2> */}
      <p>
        <strong>
          Are you a service provider who’s looking at opportunities to offer
          your services and products at a wide range of events? Well, look no
          further!
        </strong>
      </p>
      <p>
        Party Help Desk is dedicated to connecting service providers and vendors
        like you with event planners, offering a great platform to you that can
        help you seize opportunities of business generation, and reach a
        plethora of potential customers. Whether it’s food and beverages, arts
        and crafts, technology or any other type of products and services, we
        introduce to you a seamless registration process to get you started in
        your event planning journey.
      </p>
      <p>
        Why register with us?
        <ul>
          <li>
            <strong>Extensive event network</strong>
          </li>
          <li>
            <strong>Systematic and hassle-free registration process</strong>
          </li>
          <li>
            <strong>Customised vendor profiles</strong>
          </li>
          <li>
            <strong>Targeted event matching</strong>
          </li>
          <li>
            <strong>Networking and support</strong>
          </li>
        </ul>
      </p>

      <p>
        <strong>Membership plans we offer:</strong>
      </p>

      <p style={{ textDecoration: "underline" }}>
        <strong>
          Basic Plan (Rs. 499 + 18% GST Annually) per category selected
          <ul>
            <li>
              Basic details, viz. company name, contact information, products
              and services you offer can be enlisted.
            </li>
          </ul>
        </strong>
      </p>

      <p style={{ textDecoration: "underline" }}>
        <strong>
          Premium Plan (Rs. 799 + 18% GST Annually) per category selected
          <ul>
            <li>
              In addition to the elementary features, you as a vendor can avail
              other benefits like uploading 4 photos and 2 videos, exhibiting
              your body of work to showcase credibility and expertise.
            </li>
          </ul>
        </strong>
      </p>
      <p>Register today!</p>
      <p>
        Don’t miss on the opportunity to scaling up your business like never
        before and elevate your market visibility. As you become Party Help
        Desk’s authorised service provider, you unlock access to a diverse array
        of events and accomplish great milestones in your entrepreneurial
        journey. Join our community of dynamic service providers and avail
        countless benefits we offer.
      </p>
      <p>
        Please fill out the registration form and Party Help Desk will soon get
        in touch with you to guide you through the subsequent steps. We look
        forward to associating with you and help you flourish in the realm of
        event planning!
      </p>
      
    </>
  );
}

export default Pricing;
