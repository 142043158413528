import React, { useEffect, useState } from "react";
import { BASE_URL } from "../../../apiConfig";
import classes from "./ExpectationCardsSlider.module.css";
import { Link } from "react-router-dom";
import weddingIcon from "../../../assets/logos/wedding.png";
import birthdayIcon from "../../../assets/logos/birthday.png";
import eventIcon from "../../../assets/logos/corpEvent.png";
import pujaIcon from "../../../assets/logos/puja.png";
import ExpectationCard from "./ExpectationCard";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import weddingImg from "../../../assets/images/weddingStatic.webp";
import birthdayImg from "../../../assets/images/birthdayStatic.webp";
import eventImg from "../../../assets/images/eventStatic.webp";
import pujaImg from "../../../assets/images/pujaStatic.webp";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 3,
    partialVisibilityGutter: 70,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 2, //changed from 3 to 2
    partialVisibilityGutter: 50,
  },
  tablet: {
    breakpoint: { max: 1024, min: 768 },
    items: 2,
    partialVisibilityGutter: 40,
  },
  mobile: {
    breakpoint: { max: 768, min: 0 },
    items: 1,
  },
};

// const weddingSubcatNames = [
//   "Wedding planners",
//   "Theme weddings",
//   "Event Management Companies",
//   "Hotels",
//   "Banquet Halls",
//   "Marriage Halls",
//   "Photography & Videography",
//   "Mehndi Artist",
//   "DJ",
//   "Caterers",
// ];

// const birthdaySubcatNames = [
//   "Gifts",
//   "DJ",
//   "Caterers",
//   "Bakery & Sweets",
//   "Theme decoration",
//   "Balloons decoration",
//   "Hotels",
//   "Restaurants",
//   "Tent Wala",
//   "Photography & Videography",
// ];

// const corporateSubcatNames = [
//   "Hotels",
//   "Tours & Travels",
//   "Event Management Companies",
//   "Stage Backdrop fabricators",
//   "Mementos",
//   "Sound & LEDs",
//   "DJ's",
//   "Orchestra",
//   "Motivational speakers",
//   "Buses",
// ];

// const pujaSubcatNames = [
//   "Priest ( eg.Pundits, Maulvi's)",
//   "Puja Samagri (Puja material)",
//   "Caterers",
//   "Sound & LED",
//   "Stage fabricators",
//   "Tent",
//   "Worshipping Idols suppliers",
//   "Electrician & Lighting",
//   "Sweet Shops",
//   "Flowers & Garlands",
// ];

function ExpectationCardsSlider({ categories }) {
  const [weddingSubcatNames, setWeddingSubcatNames] = useState([]);
  const [corporateSubcatNames, setCorporateSubcatNames] = useState([]);
  const [birthdaySubcatNames, setBirthdaySubcatNames] = useState([]);
  const [pujaSubcatNames, setPujaSubcatNames] = useState([]);

  // TO GET SEPCIFIC CATEGORY DETAILS
  // useEffect(() => {
  //   const getCategory = async () => {
  //     try {
  //       const response = await fetch(
  //         `${BASE_URL}/categories/wedding`
  //       );
  //       if (response.ok) {
  //         const data = await response.json();
  //         // setCategory(data);
  //         console.log(data);
  //         console.log("hello");
  //       } else {
  //         throw new Error("Error retrieving category: " + response.status);
  //       }
  //     } catch (error) {
  //       console.error("Error retrieving category:", error);
  //     }
  //   };
  //   getCategory();
  // }, []);

  useEffect(() => {
    const fetchCategory = async (category) => {
      try {
        const response = await fetch(`${BASE_URL}/categories/${category}`);
        if (response.ok) {
          const data = await response.json();
          return data;
        } else {
          throw new Error(
            `Error retrieving ${category} category: ${response.status}`
          );
        }
      } catch (error) {
        console.error(`Error retrieving ${category} category:`, error);
        return null;
      }
    };

    const fetchData = async () => {
      const categories = ["Wedding", "Birthday", "Corporate-Event", "Puja"];

      for (const category of categories) {
        const data = await fetchCategory(category);
        switch (category) {
          case "Wedding":
            // console.log(data.subcategories);
            const wedding = data.subcategories.map(
              (subCat) => subCat.subcategoryName
            );
            setWeddingSubcatNames(wedding);
            break;

          case "Birthday":
            // console.log(data.subcategories);
            const birthday = data.subcategories.map(
              (subCat) => subCat.subcategoryName
            );
            setBirthdaySubcatNames(birthday);
            break;
          case "Corporate-Event":
            // console.log(data.subcategories);
            const corporate = data.subcategories.map(
              (subCat) => subCat.subcategoryName
            );
            setCorporateSubcatNames(corporate);
            break;
          case "Puja":
            // console.log(data.subcategories);
            const puja = data.subcategories.map(
              (subCat) => subCat.subcategoryName
            );
            setPujaSubcatNames(puja);
            break;
          default:
            break;
        }
      }
    };

    fetchData();
  }, []);

  return (
    <div className={classes.wrapper}>
      <h2>What You Can Expect from Us</h2>
      {/* <div>
        <p>{weddingCategory}</p>
        <p>{corporateCategory}</p>
        <p>{birthdayCategory}</p>
        <p>{pujaCategory}</p>
      </div> */}
      <div className={classes.cardsWrapper}>
        <Carousel
          autoPlay
          autoPlaySpeed={3500}
          responsive={responsive}
          swipeable={true}
          draggable={true}
          arrows={false}
          // showDots={true}
          infinite={true}
          partialVisible={true}
          // dotListClass="customDotListStyle"
        >
          <Link to="/services/wedding">
            <ExpectationCard
              heading="Weddings & Anniversaries"
              icon={weddingIcon}
              image={weddingImg}
              list={weddingSubcatNames}
              link="/services/wedding"
              // list2={["Photographers", "Pre wedding shoots", "Bridal Wear"]}
            />
          </Link>
          <Link to="/services/birthday">
            <ExpectationCard
              heading="Birthday, house warming, promotion parties etc"
              image={birthdayImg}
              icon={birthdayIcon}
              list={birthdaySubcatNames}
              link="/services/birthday"
            />
          </Link>
          <Link to="/services/corporate-event">
            <ExpectationCard
              heading="Corporate functions & offsites, conferences/seminars, trade shows"
              image={eventImg}
              icon={eventIcon}
              list={corporateSubcatNames}
              link="/services/corporate-event"
            />
          </Link>
          <Link to="/services/puja">
            <ExpectationCard
              heading="Puja"
              icon={pujaIcon}
              image={pujaImg}
              list={pujaSubcatNames}
              link="/services/puja"
            />
          </Link>
        </Carousel>
      </div>
      {/* <div className={classes.line}>
        <hr />
      </div> */}
    </div>
  );
}

export default ExpectationCardsSlider;
