import React, { useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import classes from "./HomepageBanner.module.css";
import banner1 from "../../../assets/images/homepageCarousel/Banner1.webp";
import banner2 from "../../../assets/images/homepageCarousel/Banner2.webp";
import banner3 from "../../../assets/images/homepageCarousel/Banner3.webp";
import banner4 from "../../../assets/images/homepageCarousel/Banner4.webp";
import banner5 from "../../../assets/images/homepageCarousel/Banner5.webp";

function HomepageBanner() {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  return (
    <Carousel
      fade
      activeIndex={index}
      onSelect={handleSelect}
      //data-bs-pause="false"
      pause={false}
      controls={false}
      indicators={false}
    >
      <Carousel.Item>
        <img
          className={classes["carousel-img"]}
          src={banner1}
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className={classes["carousel-img"]}
          src={banner2}
          alt="Second slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className={classes["carousel-img"]}
          src={banner3}
          alt="Third slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className={classes["carousel-img"]}
          src={banner4}
          alt="Fourth slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className={classes["carousel-img"]}
          src={banner5}
          alt="Fifth slide"
        />
      </Carousel.Item>
    </Carousel>
  );
}

export default HomepageBanner;
