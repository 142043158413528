import React, { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import classes from "./ServiceProvidersSection.module.css";
import ReactPaginate from "react-paginate";
import SummaryCard from "../SummaryCard";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
// import { VendorContext } from "../../../context/VendorContext";

function ServiceProvidersSection({ vendors, hasVendors, servicename }) {
  console.log(hasVendors);
  // const { setVendor } = useContext(VendorContext);
  const navigate = useNavigate();
  const [current, setCurrent] = useState(0);

  console.log(vendors);

  const handlePageClick = async (data) => {
    let currentPage = data.selected;
    currentPage = currentPage * 3;
    setCurrent(currentPage);
  };

  function sendToBasic() {
    navigate("/services/wedding/basic");
  }

  function sendToPremium() {
    navigate("/services/wedding/basic");
  }

  return (
    <div className={classes.wrapper} id="vendorsSection">
      <h2>Service Providers</h2>
      {/* {!hasVendors && (
        <div className={classes.noResultsDiv}>
          <h5>We couldn't find any matches!</h5>
          <p>Please try again.</p>
        </div>
      )} */}
      {hasVendors ? (
        <>
          <div className={classes.serviceProvidersCardsSection}>
            {/* CHANGE THE 4 IN BELOW LINE TO 3 OR 6 LATER, THIS IS JUST FOR STATIC STYLING */}
            {vendors
              .filter((data, i) => i < current + 3 && i >= current)
              .map((data, index) => {
                const lowercaseCompanyName = data.companyName.toLowerCase();
                const modifiedCompanyName = lowercaseCompanyName.replace(
                  /\s+/g,
                  "-"
                );
                return (
                  <div key={data._id} style={{ width: "380px" }}>
                    <Link
                      to={`/services/${servicename}/${modifiedCompanyName}/${data._id}`}
                      // onClick={() => setVendor(data._id)}
                    >
                      <SummaryCard
                        //   key={index}
                        id={data._id}
                        heading={data.companyName}
                        detail={data.overview}
                        image={data.image1}
                        plan={data.planSelected}
                        userRatings={data.userRatings}
                        // city={data.city}
                        // priceRange={`₹${data.startingPrice} - ₹${data.maximumPrice}`}
                      />
                    </Link>
                  </div>
                );
              })}
          </div>
          <ReactPaginate
            // previousLabel={"previous"}
            // nextLabel={"next"}
            previousLabel={<ArrowBackIcon />}
            nextLabel={<ArrowForwardIcon />}
            breakLabel={"..."}
            pageCount={Math.ceil(vendors.length / 3)}
            marginPagesDisplayed={1}
            pageRangeDisplayed={3}
            onPageChange={handlePageClick}
            containerClassName={"pagination justify-content-center"}
            pageClassName={"page-item-num"}
            pageLinkClassName={"page-link-num"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            breakClassName={"page-item-num"}
            breakLinkClassName={"page-link-num "}
            activeClassName={"active"}
          />
        </>
      ) : (
        <div className={classes.noResultsDiv}>
          <h5>We couldn't find any matches!</h5>
          <p>Please try again.</p>
        </div>
      )}
    </div>
  );
}

export default ServiceProvidersSection;
