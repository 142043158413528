import React from "react";
import "./Overview.css";

function Overview({ vendor }) {
  return (
    <div className="overview">
      <h3>Overview</h3>
      {/* <p>{vendor.overview}</p> */}
      <p>{vendor.overview}</p>
      <hr />
      <h3>Additional Details</h3>
      {/* <p>{vendor.additionalInfo}</p> */}
      <p>{vendor.additionalInfo}</p>
    </div>
  );
}

export default Overview;
