import React, { useState } from "react";
import axios from "axios";
import Header from "./Header";
import { BASE_URL } from "../../apiConfig";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import Table from "./TableVendor";
import MenuIcon from "@mui/icons-material/Menu";
import Sidebar from "./Sidebar";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import TableCategory from "./TableCategory";
import classes from "./Category.module.css";
import { CKEditor, ckEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Modal, Box } from "@mui/material";
import Fade from "@mui/material/Fade";

function Category() {
  const [form, setForm] = useState(false);

  const [categoryName, setCategoryName] = useState("");
  const [content, setContent] = useState("");

  const [selectedFile1, setSelectedFile1] = useState(null);
  const [selectedFile2, setSelectedFile2] = useState(null);

  // const [formData, setFormData] = useState({
  //   categoryName: "",
  //   banner: null,
  //   slider: null,
  //   content: ""
  // });

  const [subcategoryNames, setSubcategoryNames] = useState([]);

  const handleForm = (e) => {
    setForm(!form);
  };

  const handleFileChange1 = (e) => {
    setSelectedFile1(e.target.files[0]);
  };

  const handleFileChange2 = (e) => {
    setSelectedFile2(e.target.files[0]);
  };

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData((prevData) => ({ ...prevData, [name]: value }));
  // };

  // const handleFileChange1 = (e) => {
  //   const file = e.target.files[0];
  //   setFormData((prevData) => ({ ...prevData, banner: file }));
  // };

  // const handleFileChange2 = (e) => {
  //   const file = e.target.files[0];
  //   setFormData((prevData) => ({ ...prevData, slider: file }));
  // };

  //   const [categoryName, setCategoryName] = useState('');
  //   const [image, setImage] = useState('');
  //   const [text, setText] = useState('');

  const handleAddSubcategory = () => {
    setSubcategoryNames([...subcategoryNames, ""]);
  };

  const handleSubcategoryChange = (index, value) => {
    const updatedSubcategoryNames = [...subcategoryNames];
    updatedSubcategoryNames[index] = value;
    setSubcategoryNames(updatedSubcategoryNames);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // const { categoryName, banner, slider,content } = formData;

    // Create form data object
    const formData1 = new FormData();
    formData1.append("categoryName", categoryName);
    formData1.append("content", content);

    formData1.append("banner", selectedFile1);
    formData1.append("slider", selectedFile2);

    console.log(formData1);

    try {
      // Create Category
      const categoryResponse = await fetch(`${BASE_URL}/addcategory`, {
        method: "POST",
        body: formData1,
        // headers: {
        //   "Content-Type": "multipart/form-data",
        // },
      });

      if (!categoryResponse.ok) {
        throw new Error("Failed to create category");
      }

      const categoryData = await categoryResponse.json();

      const categoryId = categoryData._id;

      // Create Subcategories
      const subcategoryResponses = await Promise.all(
        subcategoryNames.map(async (name) => {
          const response = await fetch(`${BASE_URL}/addsubcategories`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              subcategoryName: name,
              categoryId,
            }),
          });

          if (!response.ok) {
            throw new Error("Failed to create subcategory");
          }

          return response.json();
        })
      );

      console.log(categoryResponse.data);
      console.log(subcategoryResponses);
      // Handle success or show notification to the user
    } catch (error) {
      console.error(error);
      // Handle error or show error message to the user
    }
  };

  return (
    <div className="adminPage">
      <Sidebar />

      <div className={classes.category}>
        <Header />
        <div className={classes.addCategory} onClick={(e) => handleForm(e)}>
          <h3>All Categories</h3>

          <button className={classes.addDiv}>
            <AddIcon className={classes.addIcon} />
            <h4>Add Category</h4>
          </button>
        </div>
        <hr />

        {/* ADD CATEGORY */}
        <Modal open={form} onClose={() => setForm(false)} disableEnforceFocus>
          <Fade in={form}>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                overflow: "auto",
                maxHeight: "30rem",
                width: "60%", //default width for all screens
                "@media (max-width: 768px)": {
                  width: "85%" /* Width for screens up to 768px */,
                  maxHeight: "85%",
                },
                "@media (max-width: 480px)": {
                  width: "95%" /* Width for screens up to 480px */,
                  maxHeight: "95%",
                },
                bgcolor: "#FFF",
                borderRadius: "15px",
                boxShadow: "0px 3px 8px rgba(0, 0, 0, 0.3)",
              }}
            >
              <div
                className={classes.addVendorForm}
                // id={form ? classes.formActive : " "}
              >
                <div className={classes["addVendorForm-Header"]}>
                  <h3>Add Category</h3>
                  <CloseIcon
                    className={classes.closeIcon}
                    onClick={(e) => setForm(!form)}
                  />
                </div>
                <form
                  encType="multipart/form-data"
                  method="POST"
                  onSubmit={handleSubmit}
                >
                  <div className={classes.formInput}>
                    <label> Enter Category Name *</label>
                    <input
                      className={classes.vendorInput}
                      type="text"
                      value={categoryName}
                      name="categoryName"
                      required
                      // onChange={handleChange}
                      onChange={(e) => setCategoryName(e.target.value)}
                    />
                  </div>
                  <div className={classes.formInput}>
                    <label> Upload Banner Image *</label>

                    <input
                      type="file"
                      name="banner"
                      required
                      onChange={handleFileChange1}
                    />
                  </div>
                  <div className={classes.formInput}>
                    <label> Upload Slider Image *</label>
                    <input
                      type="file"
                      name="slider"
                      required
                      onChange={handleFileChange2}
                    />
                  </div>
                  <div className={classes.formInput}>
                    <label>Add Sub-Categories</label>
                    {subcategoryNames.map((subcategoryName, index) => (
                      <div key={index}>
                        <input
                          type="text"
                          className={classes.vendorInput}
                          value={subcategoryName}
                          onChange={(e) =>
                            handleSubcategoryChange(index, e.target.value)
                          }
                        />
                      </div>
                    ))}
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={handleAddSubcategory}
                    >
                      Add Subcategory
                    </button>
                  </div>
                  <br />
                  <div
                    className={classes.formInput}
                    style={{ width: "100%", marginBottom: "30px" }}
                  >
                    <label> Upload Content *</label>
                    <CKEditor
                      name="content"
                      editor={ClassicEditor}
                      data={content}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setContent(data);
                        console.log({ event, editor, data });
                      }}
                    />
                  </div>
                  <div className={classes.formControl}>
                    <button onClick={(e) => setForm(!form)}> Close</button>
                    <button type="submit" onClick={(e) => setForm(!form)}>
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </Box>
          </Fade>
        </Modal>
        {/* <div
          className={classes.shadow}
          id={form ? classes.shadowActive : " "}
        ></div> */}
        <TableCategory />
      </div>
    </div>
  );
}

export default Category;
