import React, { useState } from "react";
import { BASE_URL } from "../../../apiConfig";
import "./Login.css";
import { useNavigate, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { Link } from "react-router-dom";

function Login(props) {
  const [fullname, setFullname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const location = useLocation();
  // console.log(location.pathname)

  // const handleFullNameChange = (event) => {
  //     setFullname(event.target.value);
  //   };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const [first, last] = fullname.split(" ");
    // console.log(first);
    // console.log(last);

    if (location.pathname === "/usersignup") {
      try {
        const input = {
          firstname: first,
          lastname: last,
          email: email,
          password: password,
        };
        console.log(input);

        const response = await fetch(`${BASE_URL}/user/signup`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(input),
          credentials: "include",
        });

        if (response.ok) {
          console.log("user signed up successfully!");
          toast.success("Signed up successfully!", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "colored",
          });
          setEmail("");
          setPassword("");
          setTimeout(() => {
            navigate("/services");
          }, 1200);
        } else {
          console.log("User already registered with this email");
          toast.error("Email already registered!", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "colored",
          });
        }
      } catch (e) {
        toast.error("An error occurred! Try again.", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "colored",
        });
        console.log("An error occurred during user signup!", e);
      }
    } else if (location.pathname === "/userlogin") {
      try {
        const input = { email: email, password: password };

        const response = await fetch(`${BASE_URL}/user/login`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(input),
          credentials: "include",
        });

        if (response.ok) {
          toast.success("Logged in successfully!", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "colored",
          });
          console.log("user logged in successfully!");
          setEmail("");
          setPassword("");
          setTimeout(() => {
            navigate("/services");
          }, 1200);
        } else {
          console.log("Invalid credentials!!");
          toast.error("Invalid credentials!", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "colored",
          });
        }
      } catch (e) {
        console.log("An error occurred during user login!", e);
        toast.error("An error occurred! Try again.", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "colored",
        });
      }
    } else {
      try {
        const input = { email: email, password: password };

        const response = await fetch(`${BASE_URL}/vendor/login`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(input),
          credentials: "include",
        });

        if (response.ok) {
          toast.success("Logged in successfully!", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "colored",
          });
          console.log("vendor logged in successfully!");
          setEmail("");
          setPassword("");
          setTimeout(() => {
            navigate("/vendor/dashboard");
          }, 1200);
        } else {
          console.log("Invalid credentials!!");
          toast.error("Invalid credentials!", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "colored",
          });
        }
      } catch (e) {
        console.log("An error occurred during vendor login!", e);
        toast.error("An error occurred! Try again.", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  };

  const page = props.page;
  const navigate = useNavigate();

  function sendToSignup() {
    if (props.for === "user") {
      navigate("/usersignup");
    } else {
      navigate("/vendorregistration");
    }
  }
  function sendToLogin() {
    navigate("/userlogin");
  }
  // function sendToUser() {
  //     navigate("/user")
  // }
  // const page="signUp";
  //set page according to our need either login or signUp
  return (
    <>
      <div className="container">
        <div className="loginContainer">
          <div
            className="left"
            style={page === "login" ? {} : { display: "none" }}
          ></div>
          <div className="right">
            <h3>{page === "login" ? "Hello Again !" : "Get Started"}</h3>
            <form onSubmit={handleSubmit} method="POST">
              <div
                style={page === "login" ? { display: "none" } : {}}
                className="inputField"
              >
                <label htmlFor="name">Full Name</label>

                <input
                  type="text"
                  id="fullname"
                  value={fullname}
                  name="fullname"
                  // required
                  //onChange={handleFullNameChange}
                  onChange={(e) => setFullname(e.target.value)}
                />
              </div>
              <div className="inputField">
                <label htmlFor="email">Email</label>
                <input
                  type="text"
                  id="email"
                  value={email}
                  name="email"
                  required
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="inputField">
                <label htmlFor="password">Password</label>
                <input
                  type="password"
                  id="password"
                  value={password}
                  name="password"
                  required
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <p style={page === "login" ? {} : { display: "none" }}>
                {/* <strong>Forgot Password ?</strong> */}
                <Link to="/" style={{color: "#116d6e", fontStyle:"italic", textDecoration: "underline", fontSize: "16px"}}>Skip and proceed</Link>
              </p>
              <button type="submit">
                {page === "login" ? "Login" : "Sign up"}
              </button>
              <h6>
                {page === "login"
                  ? "Dont’t have an account yet ?"
                  : "Already have an account ?"}{" "}
                <span onClick={page === "login" ? sendToSignup : sendToLogin} style={{color: "#116d6e"}}>
                  {page === "login" ? "Sign up" : "Login"}
                </span>
              </h6>
            </form>

            {/* {props.for === "user" && (
            <>
              <div className="or">
                <hr />
                <p>or</p>
                <hr />
              </div>
              <button>
                <img
                  src={require("../../../assets/logos/google.png")}
                  alt="google"
                />
                {page === "login" ? "Login" : "Sign up"} with Google
              </button>
            </>
          )} */}
          </div>
          <div
            className="left"
            style={page === "login" ? { display: "none" } : {}}
          ></div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default Login;
