import React from "react";
import { useState } from "react";
import { BASE_URL } from "../../apiConfig";
import Header from "./Header";
import classes from "./Password.module.css";
import Sidebar from "./Sidebar";
//import { useNavigate } from 'react-router-dom';
import axios from "axios";

function Password() {
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const input = {
        password: password,
        newPassword: newPassword,
        confirmPassword: confirmPassword,
      };

      await fetch(`${BASE_URL}/admin/changePassword`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(input),
      });

      console.log("password changed");
    } catch (e) {
      console.log("user not logged in");
    }
  };

  return (
    <div className="adminPage">
      <Sidebar />
      <div className={classes.password}>
        <Header />
        <div className={classes["password-container"]}>
          <h2>
            <strong>Change Password</strong>
          </h2>
          <form
            onSubmit={handleSubmit}
            encType="multipart/form-data"
            method="POST"
          >
            <div className={classes.formInput}>
              <label>Enter Current Password*</label>
              <input
                className={classes.categoryInput}
                type="password"
                value={password}
                name="password"
                required
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className={classes.formInput}>
              <label>Enter New Password*</label>
              <input
                className={classes.categoryInput}
                type="password"
                value={newPassword}
                name="newPassword"
                required
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </div>
            <div className={classes.formInput}>
              <label>Confirm New Password*</label>
              <input
                className={classes.categoryInput}
                type="password"
                value={confirmPassword}
                name="confirmPassword"
                required
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </div>
            <div className={classes.passwordBottomBar}>
              <button>Save</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Password;
