import React, { useState } from "react";
import "./Plans.css";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

function Plans({ planSelect }) {
  const [activePlan, setActivePlan] = useState("basic");

  // function handleClick(plan) {
  //     setActivePlan(plan);
  // }

  function planSelectHandler(e) {
    //  e.preventDefault()
    console.log(activePlan);
    planSelect(activePlan);
  }

  return (
    // <form onSubmit={planSelectHandler}>
    // <div className="plans-section">
    <div className="plans">
      <div className="plans-heading">
        <h1>Grow your Business</h1>
        <p>
          Boost Your Business Visibility and Attract More Customers with our
          Listings
        </p>
      </div>
      <div className="plans-main">
        <h2>Select your Plan</h2>
        <div className="plans-pricing">
          <button
            type="button"
            onClick={() => setActivePlan("basic")}
            className={activePlan === "basic" ? "checked" : ""}
          >
            <input
              type="radio"
              id="basic"
              name="planSelected"
              value="basic"
              checked={activePlan === "basic"}
            />
            <label htmlFor="basic">Basic</label>
          </button>
          <button
            type="button"
            onClick={() => setActivePlan("premium")}
            className={activePlan === "premium" ? "checked" : ""}
          >
            <input
              type="radio"
              id="premium"
              name="planSelected"
              value="premium"
              checked={activePlan === "premium"}
            />
            <label htmlFor="premium">Premium</label>
          </button>
        </div>
        <div
          className="plans-content"
          id="basic-plans-content"
          style={
            activePlan === "basic" ? { display: "block" } : { display: "none" }
          }
        >
          <h3>What you get in Basic</h3>
          <div className="plans-list">
            <CheckCircleIcon />
            <p>Upload social media links</p>
          </div>
          <div className="plans-list">
            <CheckCircleIcon />
            <p>Add Overview and additional details</p>
          </div>
          <div className="plans-list">
            <CheckCircleIcon />
            <p>Add Location</p>
          </div>
        </div>
        <div
          className="plans-content"
          id="premium-plans-content"
          style={
            activePlan === "premium"
              ? { display: "block" }
              : { display: "none" }
          }
        >
          <h3>What you get in Premium</h3>
          <div className="plans-list">
            <CheckCircleIcon />
            <p>Upload social media links</p>
          </div>
          <div className="plans-list">
            <CheckCircleIcon />
            <p>Add Overview and additional details</p>
          </div>
          <div className="plans-list">
            <CheckCircleIcon />
            <p>Add Location</p>
          </div>
          <div className="plans-list">
            <CheckCircleIcon />
            <p>Upload upto 4 images</p>
          </div>
          <div className="plans-list">
            <CheckCircleIcon />
            <p>2 Videos</p>
          </div>
        </div>
      </div>
      {/* <hr /> */}
      <div className="plans-bottom">
        <div>
          <h5>
            {activePlan === "basic"
              ? "Rs 499 + 18% GST"
              : "Rs 799 + 18% GST"}
          </h5>
          <h6>per category selected</h6>
        </div>
        <button type="button" onClick={planSelectHandler}>
          Proceed
        </button>
      </div>
    </div>
    // </div>
    // </form>
  );
}

export default Plans;
