import React, { useState, useEffect } from "react";
import moment from "moment";
import classes from "./BlogDetail.module.css";
import GoToTop from "../components/GoToTop";
import Navbar from "../components/Navbar";
import ServiceBanner from "../components/ServiceDetailPage/ServiceBanner";
import MiddleBanner from "../components/MiddleBanner";
import Footer from "../components/Footer";
import MessageUsButton from "../components/MessageUsButton";
import eventBanner from "../../assets/images/eventBanner.webp";
import { useParams } from "react-router-dom";
import { BASE_URL } from "../../apiConfig";

function BlogDetail() {
  const [item, setItem] = useState({});
  const { blogId } = useParams();

  const midBannerText = (
    <>
      Party Help Desk jahaan
      <br />
      Shaandaar aayojan wahan!
    </>
  );

  console.log(item);

  useEffect(() => {
    const fetchItems = async () => {
      try {
        const blogs = await fetch(`${BASE_URL}/blog/${blogId}`);
        if (!blogs.ok) {
          throw new Error("Error fetching items");
        }

        const data = await blogs.json();
        console.log(data.blog);
        setItem(data.blog);
      } catch (e) {
        console.error("Error fetching items:", e);
      }
    };
    fetchItems();
  }, [blogId]);

  const formatBlogDate = (createdAt) => {
    const blogDate = moment(createdAt);

    return blogDate.format("MMM YYYY");
  };

  console.log(item.bannerImg);

  return (
    <>
      <GoToTop />
      <MessageUsButton />
      <Navbar />
      {/* <ServiceBanner image={item.bannerImg} /> */}
      <div className={classes.bannerDiv}>
        <img src={`${BASE_URL}/upload/${item.bannerImg}`} alt="blog-banner" />
      </div>
      {/* <div
        className={classes.banner}
        style={{
          backgroundImage: `url(${BASE_URL}/upload/${item.bannerImg})`,
        }}
      ></div> */}
      <div className={classes.contentDiv}>
        <h1>{item.title}</h1>
        <h6>{formatBlogDate(item.createdAt)}</h6>
        <>
          <div dangerouslySetInnerHTML={{ __html: item.content }} />
        </>
      </div>
      <MiddleBanner text={midBannerText} />
      <Footer />
    </>
  );
}

export default BlogDetail;
