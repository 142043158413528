import React, { useEffect } from "react";
import classes from "./Homepage.module.css";
import { BASE_URL } from "../../apiConfig";
import Navbar from "../components/Navbar";
import HomepageBanner from "../components/Homepage/HomepageBanner";
import MiddleBanner from "../components/MiddleBanner";
import MessageUsButton from "../components/MessageUsButton";
import HomepageText from "../components/Homepage/HomepageText";
import ExpectationCardsSlider from "../components/Homepage/ExpectationCardsSlider";
import Footer from "../components/Footer";
import Partners from "../components/Homepage/Partners";
import ServicesSlider from "../components/Homepage/ServicesSlider";
import PopUp from "../components/Homepage/PopUp";
import GoToTop from "../components/GoToTop";
import NoticePopUp from "../components/Homepage/NoticePopUp";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

function Homepage() {
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const hasVisited = sessionStorage.getItem("hasVisited");
  console.log(hasVisited);

  useEffect(() => {
    const getCategories = async () => {
      try {
        const response = await fetch(`${BASE_URL}/getcategory`);
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        setCategories(data.category); //set all categories (an array)
        console.log(data);
      } catch (error) {
        console.error(error);
      }
    };
    getCategories();
  }, []);

  const midBannerText = (
    <>
      Party ka prabandh hum karenge
      <br />
      Aap sirf jalsa karenge!
    </>
  );

  function sendToVendor() {
    navigate("/vendorregistration");
  }

  const ldJson = {
    "@context": "https://schema.org/",
    "@type": "WebSite",
    name: "Party help Desk",
    url: "https://www.partyhelpdesk.com/",
    potentialAction: {
      "@type": "SearchAction",
      target: "https://www.partyhelpdesk.com/{search_term_string}",
      "query-input": "required name=search_term_string",
    },
  };

  return (
    <>
      <Helmet>
        <title>Vendor that Provides All Events for One Company</title>
        <link rel="canonical" href="https://www.partyhelpdesk.com/" />
        <meta
          name="description"
          content="We are all party vendor in India who can provide everything you need to plan and execute a successful Party. We offer a wide range of services, from venue selection to catering to entertainment. Contact us today"
        />
        <script type="application/ld+json">{JSON.stringify(ldJson)}</script>
      </Helmet>
      <GoToTop />
      <NoticePopUp />
      <PopUp />
      <Navbar />
      <HomepageBanner />
      <MessageUsButton />
      <HomepageText />
      <MiddleBanner text={midBannerText} />
      <div style={{ height: "60px" }}></div>
      <ExpectationCardsSlider categories={categories} />
      {/* <Partners /> */}
      {/* <ServicesSlider categories={categories} /> */}
      <div className={classes.registerDiv}>
        <button onClick={sendToVendor}>Register as a Service Provider</button>
      </div>
      <Footer />
    </>
  );
}

export default Homepage;
