import React from "react";
import classes from "./Blogs.module.css";
import MessageUsButton from "../components/MessageUsButton";
import Navbar from "../components/Navbar";
import MiddleBanner from "../components/MiddleBanner";
import Footer from "../components/Footer";
import Banner from "../components/Banner";
import blogBanner from "../../assets/images/blogBanner.webp";
import BlogsSlider from "../components/BlogsPage/BlogsSlider";
import GoToTop from "../components/GoToTop";

function Blogs() {
  const midBannerText = (
    <>
      Party Help Desk jahaan
      <br />
      Shaandaar aayojan wahan!
    </>
  );

  return (
    <>
      <GoToTop />
      <MessageUsButton />
      <Navbar />
      <Banner image={blogBanner} text="Blogs" />
      {/* <div className={classes.contentDiv}>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque
          at auctor lorem, vel volutpat augue. Maecenas convallis turpis eget
          tincidunt ornare. Vivamus dapibus mi sit amet leo bibendum dictum.
          Nulla arcu augue, mollis in justo nec, lacinia commodo odio. Praesent
          non tortor pretium, congue lorem vel, ullamcorper metus. Mauris
          posuere facilisis odio sed sollicitudin. Pellentesque habitant morbi
          tristique senectus et netus et malesuada fames ac turpis egestas.
          Quisque accumsan erat vel lectus dapibus placerat.
        </p>
      </div> */}
      <BlogsSlider />
      {/* <div className={classes.gridContainer}>
        <SummaryCard />
        <SummaryCard />
        <SummaryCard />
        <SummaryCard />
        <SummaryCard />
        <SummaryCard />
      </div> */}

      <MiddleBanner text={midBannerText} />
      <Footer />
    </>
  );
}

export default Blogs;
