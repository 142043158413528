import React, { useState } from "react";
import cities from "../../../cities-name-list.json";
import classes from "./CitySearch.module.css";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import mumbaiIcon from "../../../assets/logos/mumbai.png";
import delhiIcon from "../../../assets/logos/delhi.png";
import gujaratIcon from "../../../assets/logos/gujarat.png";
import hyderabadIcon from "../../../assets/logos/hyderabad.png";
import keralaIcon from "../../../assets/logos/kerala.png";

const popularCities = [
  {
    id: "1",
    icon: mumbaiIcon,
    city: "Mumbai",
  },
  {
    id: "2",
    icon: delhiIcon,
    city: "Delhi",
  },
  // {
  //   id: "3",
  //   icon: gujaratIcon,
  //   city: "Gujarat",
  // },
  {
    id: "3",
    icon: hyderabadIcon,
    city: "Hyderabad",
  },
  // {
  //   id: "5",
  //   icon: keralaIcon,
  //   city: "Kerala",
  // },
];

function CitySearch({ onCitySet }) {
  const [filteredCities, setFilteredCities] = useState([]);
  const [city, setCity] = useState("");
  const [showPopularCities, setShowPopularCities] = useState(false);

  function handleCityFilter(e) {
    const searchWord = e.target.value;
    setShowPopularCities(false);
    setCity(searchWord);
    const newFilter = cities.filter((city) => {
      return city.toLowerCase().includes(searchWord.toLowerCase());
    });

    if (city === "") {
      setFilteredCities([]);
    } else {
      setFilteredCities(newFilter);
    }
  }

  function handleCitySelect(citySelected) {
    //  console.log(citySelected);
    console.log("on click hello");
    setShowPopularCities(false);
    setCity(citySelected);
    onCitySet(citySelected);
    setFilteredCities([]);
  }

  function handleClearInput() {
    setShowPopularCities(false);
    setCity("");
    setFilteredCities([]);
  }

  return (
    <>
      {/* CITY FILTER */}
      <div className={classes.search}>
        <div className={classes.searchInputs}>
          <input
            type="text"
            placeholder="City"
            onChange={handleCityFilter}
            value={city}
            onFocus={() => setShowPopularCities(true)}
            // onBlur={() => setShowPopularCities(false)}
          />
          <div className={classes.searchIcon}>
            {filteredCities.length === 0 && showPopularCities === false ? (
              <>
                <SearchIcon />
                {/* <SearchIcon onClick={() => setShowPopularCities(true)} /> */}
              </>
            ) : (
              <CloseIcon id={classes.clearBtn} onClick={handleClearInput} />
            )}
          </div>
        </div>

        {/* {showPopularCities && (
          <div className={classes.dataResult}>
            {popularCities.map((city) => {
              return (
                <div
                  key={city.id}
                  className={classes.dataItemStatic}
                  onClick={() => handleCitySelect(city.city)}
                >
                  <img src={city.icon} alt="city-icon" />
                  {city.city}
                </div>
              );
            })}
          </div>
        )} */}

        {filteredCities.length !== 0 && (
          <div className={classes.dataResult}>
            {filteredCities.slice(0, 5).map((city, index) => {
              return (
                <div
                  key={index}
                  className={classes.dataItem}
                  onClick={() => handleCitySelect(city)}
                >
                  {city}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </>
  );
}

export default CitySearch;
