import React, { useState, useEffect } from "react";
import Header from "./Header";
import classes from "./Dashboard.module.css";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import GroupsIcon from '@mui/icons-material/Groups';
import { BASE_URL } from "../../apiConfig";
import { Link } from "react-router-dom";
import Sidebar from "./Sidebar";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";

function Dashboard() {
  const [items, setItems] = useState([]);
  const [data, setData] = useState([]);
  const [customerData, setCustomerData] = useState([]);
  const [vendorData, setVendorData] = useState([]); //for export to excel sheet

  // EXPORT TO EXCEL FUNCTION--------------------------------------------------------
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const fileName = "PHDVendorsData";

  async function exportToExcel() {
    const ws = XLSX.utils.json_to_sheet(vendorData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  }
  // ----------------------------------------------------------------------------------

  // to get all vendors and export their details to excel
  useEffect(() => {
    const getVendors = async () => {
      try {
        const response = await fetch(`${BASE_URL}/vendors/data`);
        if (!response.ok) {
          throw new Error("Error fetching categories");
        }
        const Data = await response.json();
        // console.log(Data);
        // setVendors(data);
        const newData = Data.vendors.map((data) => ({
          Approved: data.approved === true ? "Yes" : "Pending",
          Email: data.email,
          CompanyName: data.companyName,
          PartnerName: data.partnerName,
          PlanSelected: data.planSelected,
          GSTIN: data.gstNumber,
          PhoneNumber: data.phoneNumber,
          WhatsappNumber: data.whatsappNumber,
          Address: data.address,
          Pincode: data.pincode,
          City: data.city,
          Website: data.websiteURL,
          Facebook: data.fbLink,
          Instagram: data.instaLink,
        }));
        setVendorData(newData);
        console.log(newData);
      } catch (error) {
        console.log(error);
      }
    };
    getVendors();
  }, []);

  //Get all customers
  useEffect(() => {
    fetch(`${BASE_URL}/getuserdetails`, {
      method: "GET",
      //credentials: "include",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("No user found.");
        }
        return response.json();
      })
      .then((data) => {
        setCustomerData(data);
        console.log(data);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, []);

  useEffect(() => {
    const getCategories = async () => {
      try {
        const response = await fetch(`${BASE_URL}/getcategory`);
        if (!response.ok) {
          throw new Error("Error retrieving categories");
        }
        const data = await response.json();
        console.log(data);
        setData(data);
      } catch (error) {
        console.log(error);
      }
    };

    getCategories();
  }, []);

  useEffect(() => {
    const getCountOfVendors = async () => {
      try {
        const response = await fetch(`${BASE_URL}/vendors/data`);
        if (!response.ok) {
          throw new Error("Error fetching vendors");
        }
        const data = await response.json(); 
        setItems(data);
        // console.log(items);
      } catch (error) {
        console.log(error);
      }
    };
    getCountOfVendors();
  }, []);

  return (
    <div className="adminPage">
      <Sidebar />

      <div className={classes.dashboard}>
        <Header />
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => exportToExcel(fileName)}
        >
          Download Vendors Data
        </button>
        <div className={classes.dashboardTopContainer}>
          <div className={classes["dashboardTopContainer-item"]}>
            <div className={classes["dashboardTopContainer-icon"]}>
              <GroupsIcon />
            </div>
            <p>Pending Vendor Approvals</p>
            <h3>{items.countApprovals}</h3>
          </div>
          <div className={classes["dashboardTopContainer-item"]}>
            <div className={classes["dashboardTopContainer-icon"]}>
              <CurrencyRupeeIcon />
            </div>
            <p>Revenue generated</p>
            <h3>{`₹${items.totalRevenue}`}</h3>
          </div>
        </div>
        <div className={classes.dashboardCardContainer}>
          <Link style={{ textDecoration: "none" }} to="/admin/category">
            <div className={`${classes.dashboardCard} ${classes.first}"`}>
              <h3>Categories</h3>
              <p>Total Categories</p>
              <h1>{data.countCategories}</h1>
            </div>
          </Link>
          <Link style={{ textDecoration: "none" }} to="/admin/customer">
            <div className={`${classes.dashboardCard} ${classes.second}"`}>
              <h3>Customers</h3>
              <p>Total Customers</p>
              <h1>{customerData.countCustomers}</h1>
            </div>
          </Link>
          <Link style={{ textDecoration: "none" }} to="/admin/vendor">
            <div className={`${classes.dashboardCard} ${classes.third}"`}>
              <h3>Vendors</h3>
              <p>Total Approved Vendors</p>
              <h1>{items.countVendors}</h1>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
