import React from "react";

function TermsConditions() {
  return (
    <>
      {/* <h2>Terms and Conditions</h2> */}
      <p>
        The Terms and Conditions govern the use of services provided by Party
        Help Desk to vendors who wish to render their products and services for
        the events that fall under the purview of Party Help Desk. By availing
        our services, end consumers & Service Providers/ Vendors agree to come
        in complete accord and be bound by the terms and conditions set forth
        herein by the parent company viz: Jenny Blossom India (OPC) Private
        Limited.
      </p>
      <ol>
        <li>
          The company provides an online platform viz; partyhelpdesk.com for
          Vendors and Service Providers to connect with event organisers or the
          end consumers and provide their services for various events.
        </li>
        <li>
          Vendor or the service provider shall be referred to as any business or
          entity that registers itself on the partyhelpdesk.com website,
          showcasing their services to the prospective clients through online
          ads placed on the site. End consumer shall be referred to as any
          person that visits the partyhelpdesk.com website and avails the
          services of any service provider listed on the website.
        </li>
        <li>
          Vendor/service provider must abide by the legal compliances and
          possess necessary permits, licenses, and certifications required by
          the local authorities to provide their goods and services at events.
          Party Help Desk has all the rights reserved to verify the credentials
          of vendors and service providers and may request supporting documents.
          Vendor or the service providers must use proprietary material for
          their ads that they intend to place on the Partyhelpdesk website and
          must possess necessary licenses/copyright etc. Partyhelpdesk.com won’t
          be held liable for any copyright claim that may arise.
        </li>
        <li>
          Vendors must create an account on the Party Help Desk website by
          providing accurate and complete credentials. Vendors are liable to
          maintain the confidentiality of their account information and are also
          responsible for any activity that occurs under their account.
        </li>
        <li>
          Partyhelpdesk is an online consortium of service providers who provide
          services for any kind of party viz; Weddings, Birthdays, Marriage
          Anniversaries, Puja Rituals, Corporate Seminars & Conferences, Trade
          Shows, Corporate offsites, Tours and Travels etc. The company just
          provides an online platform for end consumers and service providers to
          connect with each other, negotiate & close deals between themselves
          and avail the services. End consumers must enquire about service
          providers credentials and track record by themselves, before closing a
          deal.
        </li>
        <li>
          Partyhelpdesk would not negotiate on behalf of end consumers with the
          service providers or charge any commission from the service providers
          for closed deals, to begin with. The company would charge only annual
          subscription fees from the service providers towards providing the
          online platform for placing their ads and towards marketing services
          of the website.
        </li>
        <li>
          The end consumer after availing the services shall pay directly to the
          vendor or the service provider towards the services availed as per the
          deal negotiated with the vendor or the service provider. The payment
          would not be routed through Partyhelpdesk.
        </li>
        <li>
          The company cannot be deemed liable for any direct, indirect,
          incidental, consequential, or special damages emerging from or in
          connection with the use of its services. The vendor or the service
          provider would keep Partyhelpdesk indemnified on any dispute that may
          arise between the service provider and the end consumer.
        </li>
        <li>
          Any dispute that may arise should be settled amicably and if not then
          jurisdiction of Courts in Prayagraj shall apply.
        </li>
        <li>
          Vendors or service providers shall not indulge in any activity that
          earns a bad name for the company or dents its reputation. In case of
          repeated complaints or unacceptable behavior with the customers, the
          company reserves the right to cancel the service provider’s
          subscription and refund the money on pro-rata basis without any
          interest that may accrue, after deducting applicable taxes.
        </li>
        <li>
          The terms and conditions stipulated above would be subject to review
          from time to time and can be amended/repealed or annulled, if the
          prevailing situation so demands. If it is altogether repealed or
          annulled, fresh set of terms and conditions would be framed and
          uploaded on the website.
        </li>
      </ol>
    </>
  );
}

export default TermsConditions;
