import React, { useState } from "react";
import { Modal, Box } from "@mui/material";
import Fade from "@mui/material/Fade";
import Backdrop from "@mui/material/Backdrop";
import logo from "../../../assets/logos/brandLogo.jpeg";
import classes from "./NoticePopUp.module.css";

function NoticePopUp({ open, onClose }) {
  // const [open, setOpen] = useState(true);
  return (
    <>
      <Modal
        open={open}
        // onClose={() => setOpen(false)}
        closeAfterTransition
        // onBackdropClick={false}
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
            style: { backdropFilter: "blur(5px)" },
          },
        }}
      >
        <Fade in={open}>
          <Box
            sx={{
              outline: "none", //to remove the strange outline (black border) that shows up when modal is in focus
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              // width: "450px",
              width: "450px" /* Default width for all screen sizes */,
              "@media (max-width: 768px)": {
                width: "350px" /* Width for screens up to 768px */,
              },
              "@media (max-width: 480px)": {
                width: "300px" /* Width for screens up to 480px */,
              },
              maxHeight: "700px",
              padding: "20px 10px", //new
              overflow: "auto", //new
              bgcolor: "white",
              // bgcolor: "rgba(255, 255, 255, 0.700)",
              border: "2px solid white",
              borderRadius: "15px",
              boxShadow: "0px 3px 8px rgba(0, 0, 0, 0.3)",
            }}
          >
            <div className={classes.noticeHeaderDiv}>
              <img src={logo} alt="party-helpdesk-logo" />
              <div className={classes.noticeHeader}>
                <h2>Welcome to Party Help Desk</h2>
                <p>Party Ki Taiyyari, Bina Magajmari…</p>
                <hr />
              </div>
            </div>
            <div className={classes.noticeContentDiv}>
              {/* <div> */}
              <p>
                Service provider registrations are going on.
                <br /> In the meantime, we encourage the end consumers to
                explore the various features of our website.
              </p>
              {/* </div> */}
              <div className={classes.formActions}>
                <button type="button" onClick={() => onClose()}>
                  Explore
                </button>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </>
  );
}

export default NoticePopUp;
