import React, { useState } from "react";
import classes from "./MessageUs.module.css";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import Navbar from "../components/Navbar";
import Banner from "../components/Banner";
import MiddleBanner from "../components/MiddleBanner";
import Footer from "../components/Footer";
import contactBanner from "../../assets/images/contactBanner.webp";
import GoToTop from "../components/GoToTop";
import MessageUsButton from "../components/MessageUsButton";
import FaqContainer from "../components/ProductDetail/FaqContainer";
import { BASE_URL } from "../../apiConfig";
import { Helmet } from "react-helmet-async";

function MessageUs() {
  const [showFaqs, setShowFaqs] = useState(false);
  const [message, setMessage] = useState("");

  const midBannerText = (
    <>
      Event planning ke liye humse sampark karo,
      <br />
      Phir mauj jamkar karo!
    </>
    // <>
    //   Event planning ki chinta laazmi hai
    //   <br /> lekin Party Help Desk kaafi hai
    // </>
  );

  const handleSubmit = (e) => {
    e.preventDefault();

    fetch(`${BASE_URL}/customer/ticket/loggedin`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ message }),
      credentials: "include",
    })
      .then((response) => response.json())
      .then((result) => {
        toast.success("Message sent successfully!", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "colored",
        });
        console.log(result);
      })
      .catch((error) => {
        console.error("Error:", error);
        toast.error("An error occurred! Try again.", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "colored",
        });
      });

    setMessage("");
  };

  return (
    <>
      <Helmet>
        <title>Contact Party Plan Desk - Party Planning Company in India</title>
        <meta
          name="description"
          content="Feel free to contact Party Plan desk for Party Management, Provide all vendors, example: Venue, photographer, decoraters and catering. Contact us today"
        />
      </Helmet>
      <GoToTop />
      <MessageUsButton />
      <Navbar />
      <Banner image={contactBanner} text="Contact Us" />
      <div className={classes.contentDiv}>
        <form onSubmit={handleSubmit}>
          <div className={classes.messageFormDiv}>
            <h3>MESSAGE US</h3>
            <textarea
              placeholder="How can we help you today?"
              rows={5}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
            <button type="submit">Submit</button>
          </div>
        </form>
        <div className={classes.contactDetailsDiv}>
          <div className={classes.emailDiv}>
            <h3>WRITE TO US</h3>
            <p>
              <Link to="mailto:partyhelpdesk@gmail.com">
                Email: partyhelpdesk@gmail.com
              </Link>
            </p>
            <hr />
          </div>
          <div className={classes.callDiv}>
            <h3>CALL US</h3>
            <p>
              <Link to="tel:+918840152159">+91 8840 152 159</Link>, {" "}
              <Link to="tel:+919838071565">+91 9838 071 565</Link>
            </p>
            <hr />
          </div>
          <div className={classes.callDiv}>
            <h3>ADDRESS</h3>
            <p>Address: 186/2 Tagore Town, Prayagraj - 211002</p>
            <hr />
          </div>
          {/* <div className={classes.faqDiv}>
            {showFaqs ? (
              <FaqContainer />
            ) : (
              <>
                <h3>FAQs</h3>
                <ArrowCircleRightOutlinedIcon
                  fontSize="large"
                  htmlColor="grey"
                  cursor="pointer"
                  onClick={() => setShowFaqs(true)}
                />
              </>
            )}
          </div> */}
        </div>
      </div>
      <MiddleBanner text={midBannerText} />
      <ToastContainer />
      <Footer />
    </>
  );
}

export default MessageUs;
