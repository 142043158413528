import React, { useState, useEffect } from "react";
import Header from "./Header";
import classes from "./Dashboard.module.css";
import { Link } from "react-router-dom";
import { BASE_URL } from "../../apiConfig";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

function Dashboard(props) {
  // console.log(props.books);
  // console.log(props.myUsers);

  const [userDetails, setUserDetails] = useState([]);
  const [count, setCount] = useState(0);
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [date, setDate] = useState("");

  async function paymentHandler() {
    try {
      const response = await axios.post(
        `${BASE_URL}/phonepe/payment`,
        {},
        { withCredentials: true }
      );
      console.log(response.data);
      console.log(response.data.data.instrumentResponse.redirectInfo.url);
      const paymentUrl = response.data.data.instrumentResponse.redirectInfo.url;
      // console.log(window)
      // Redirect the user to the PhonePe payment page
      window.location.href = paymentUrl;
    } catch (error) {
      toast.error("Error Initiating Payment!", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "colored",
      });
      console.error("Error initiating payment:", error);
      // Handle error scenario
    }
  }

  useEffect(() => {
    fetch(`${BASE_URL}/getdetails`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
      .then((response) => response.json())
      .then((data) => {
        setIsLoading(false);
        console.log(data);
        setData(data);
        setDate(data.paymentDetails.createdAt);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }, []);

  /////////////////////////////////////////////////
  //display expiry date
  const getDateAfterOneYear = (createdAt) => {
    const dateTime = new Date(createdAt);

    const year = dateTime.getFullYear();
    const month = dateTime.getMonth();
    const day = dateTime.getDate();

    const newDate = new Date();
    newDate.setFullYear(year + 1, month + 1, day);
    return newDate.toLocaleDateString();
  };

  const dateAfterOneYear = getDateAfterOneYear(date);
  ///////////////////////////////////////////////////

  // to GET customers who contacted this specific vendor
  useEffect(() => {
    fetch(`${BASE_URL}/getusers`, {
      credentials: "include",
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error(
            "Error fetching user details. Status: " + response.status
          );
        }
      })
      .then((data) => {
        const userDetails = data.users;
        setUserDetails(userDetails);
        setCount(data.count);
        setIsLoading(false);
        // console.log(count);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error fetching details:", error);
      });
  }, []);

  console.log(data.categoriesSelected);

  return (
    <>
      <div className={classes.dashboard}>
        <Header />
        {!isLoading && (
          <>
            <h2 style={{ fontSize: 15 }}>
              Your subscription expires on: {dateAfterOneYear}
            </h2>

            <div className={classes.dashboardCardContainer}>
              <Link style={{ textDecoration: "none" }} to="#">
                <div className={`${classes.dashboardCard} ${classes.first}`}>
                  <h3>Categories</h3>
                  <p>Total Categories</p>
                  <h1>{data.categoriesSelected?.length || 0}</h1>
                </div>
              </Link>
              <Link style={{ textDecoration: "none" }} to="/vendor/customer">
                <div className={`${classes.dashboardCard} ${classes.second}`}>
                  <h3>Customers</h3>
                  <p>Total Customers</p>
                  <h1>{count}</h1>
                </div>
              </Link>
            </div>
            {data.transactionId && (
              <div className={classes.formInput}>
                <p>Transaction Id: {data.transactionId}</p>
              </div>
            )}
            {data.paymentDetails?.data?.state && (
              <div className={classes.formInput}>
                <p>Payment Status: {data.paymentDetails.data.state}</p>
              </div>
            )}

            {data.paymentDetails?.data?.amount && (
              <div className={classes.formInput}>
                <p>
                  Amount: ₹
                  {(parseInt(data.paymentDetails.data.amount) / 100).toFixed(2)}
                </p>
              </div>
            )}
            {data.paymentDetails?.data?.state === "FAILED" && (
              <button
                className="btn btn-primary"
                onClick={paymentHandler}
                style={{ marginBottom: "60px" }}
              >
                Pay Now
              </button>
            )}
          </>
        )}
        <ToastContainer />
      </div>
    </>
  );
}

export default Dashboard;
