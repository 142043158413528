import React, { useState, useEffect } from "react";
import classes from "./Header.module.css";
import { BASE_URL } from "../../apiConfig";

function Header() {
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetch(`${BASE_URL}/getdetails`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
      .then((response) => response.json())
      .then((data) => {
        setIsLoading(false);
        console.log(data);
        setData(data);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }, []);

  return (
    <div className={classes.header}>
      <h4>
        <strong>Hello, {data.companyName}!</strong>
      </h4>
    </div>
  );
}

export default Header;
