import React, { useState, useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "../../apiConfig";
import classes from "./CustomerPage.module.css";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
// import AddCustomer from "../components/AddCustomer";
import CustomerDetailsPopUp from "../components/CustomerDetailsPopUp";

const USERS = [
  {
    _id: "1",
    fName: "xyz",
    lName: "xyz",
    mobile: 67897788788,
    email: "xyz@gmail.com",
    plot: "jsacjsbc",
    landmark: "hdqwdfjq",
    street: "scjashc",
    city: "scjksac",
    state: "sncjksahc",
    zipcode: 121011,
  },
  {
    _id: "2",
    fName: "xyz",
    lName: "xyz",
    mobile: 67897788788,
    email: "xyz@gmail.com",
    plot: "jsacjsbc",
    landmark: "hdqwdfjq",
    street: "scjashc",
    city: "scjksac",
    state: "sncjksahc",
    zipcode: 121011,
  },
  {
    _id: "3",
    fName: "xyz",
    lName: "xyz",
    mobile: 67897788788,
    email: "xyz@gmail.com",
    plot: "jsacjsbc",
    landmark: "hdqwdfjq",
    street: "scjashc",
    city: "scjksac",
    state: "sncjksahc",
    zipcode: 121011,
  },
];

function CustomerPage() {
  const [myUsers, setMyUsers] = useState([USERS]);
  const [hasUsers, setHasUsers] = useState(true);
  const [addUserForm, setAddUserForm] = useState(false);
  const [activeUserId, setActiveUserId] = useState(null);
  const [viewUserDetails, setViewUserDetails] = useState(false);
  const [userDetails, setUserDetails] = useState([]);
  const [hasDetails, setHasDetails] = useState(true);

  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    phone: "",
    plot: "",
    landmark: "",
    street: "",
    city: "",
    state: " ",
    pincode: "",
  });

  // to GET customers who contacted this specific vendor
  useEffect(() => {
    fetch(`${BASE_URL}/getusers`, {
      credentials: "include",
    })
      .then((response) => {
        if (response.ok) {              
          return response.json();
        } else {
          throw new Error(
            "Error fetching user details. Status: " + response.status
          );
        }
      })        
      .then((data) => {
        const userDetails = data.users.users;
        setUserDetails(userDetails);
        if (userDetails) {
          setHasDetails(true);
        } else {
          setHasDetails(false);
        }
        console.log(userDetails);
      })
      .catch((error) => {
        console.error("Error fetching details:", error);
      });
  }, []);

  // to GET all users and display in table
  // useEffect(() => {
  //   axios
  //     .get("http://localhost:5000/vendor/users", { withCredentials: true })
  //     .then((res) => {
  //       // setAvlBooks(res.data);
  //       setMyUsers(res.data);
  //       setHasUsers(true);
  //       console.log(res.data);
  //     })
  //     .catch((err) => {
  //       setHasUsers(false);
  //       console.log("Error fetching users:" + err);
  //     });
  // }, []);

  // on adding a new user through add cutomer component
  function addUserHandler(newUser) {
    setMyUsers((prevUsers) => [...prevUsers, newUser]);
  }

  // TO DELETE A SPECIFIC USER
  function deleteUserHandler(userId) {
    fetch(`${BASE_URL}/getusers/${userId}`, {
      method: "POST",
      credentials: "include",
    })                               
      .then((response) => {
        if (response.ok) {
          setUserDetails((prevUsers) => {
            return prevUsers.filter((user) => {
              return user._id !== userId;
            });
          });
        } else {   
          throw new Error("Error deleting user. Status: " + response.status);
        }
      })
      .catch((error) => {
        console.log("Error deleting user:", error);
      });
  }


  function formOpenHandler() {
    setAddUserForm(true);
  }

  function formCloseHandler() {
    setAddUserForm(false);
  }

  function openPopUpHandler(userId) {
    setViewUserDetails(true);
    setActiveUserId(userId);
  }

  function closePopUpHandler() {
    setViewUserDetails(false);
  }

  const usersTableRows = userDetails.map((user) => {
    return (
      <tr key={user._id}>
        <td>{`${user.firstname}`}</td>
        <td>{user.phone}</td>
        <td>{user.email}</td>
        <td>{user.city ? user.city : ""}</td>
        <td>{user.state ? user.state : ""}</td>
        <td   
          className={classes.viewCustomer}
          onClick={() => openPopUpHandler(user._id)}
        >    
          View
        </td>
        <td style={{ textAlign: "center" }}>
          <DeleteIcon
            fontSize="small"
            onClick={() => deleteUserHandler(user._id)}
          />
        </td>
      </tr>
    );
  });
  
  return (
    <div className={classes.wrapper}>
      <Sidebar />
      <div className={classes.customer}>
        <Header />
        <div className={classes.addCustomer}>
          <h3>Our Customers</h3>
          {/* <button className={classes.addDiv} onClick={formOpenHandler}>
            <AddIcon className={classes.addIcon} />
            <h4>Add</h4>
          </button> */}
        </div>
        <hr />

        {/* {addUserForm && (
          <AddCustomer onAdd={addUserHandler} onClose={formCloseHandler} />
        )} */}

        <table className={classes.table}>
          <tbody>
            <tr>
              <th>Name</th>
              <th>Mobile</th>
              <th>Email</th>
              <th>City</th>
              <th>State</th>
              <th>Details</th>
              <th>Action</th>
            </tr>
            {!hasUsers ? <p>No Users found!</p> : usersTableRows}
          </tbody>
        </table>

        {/*CUSTOMER DETAILS POP-UP */}
        {viewUserDetails && (
          <CustomerDetailsPopUp
            userId={activeUserId}
            onClose={closePopUpHandler}
          />
        )}
      </div>
    </div>
  );
}

export default CustomerPage;
