import React, { useState, useEffect, useContext } from "react";
// import { VendorContext } from "../../../context/VendorContext";
import { useParams } from "react-router-dom";
import { BASE_URL } from "../../../apiConfig";
import "./ProductDetailPremium.css";
import CallIcon from "@mui/icons-material/Call";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import Rating from "@mui/material/Rating";

import VendorDetailModal from "./VendorDetailModal";
import AskContactDetailsModal from "./AskContactDetailsModal";

function ProductDetailPremium({
  // vendorId,
  vendor,
  isLoggedIn,
  onLogin,
  avgRating,
}) {
  const { vendorId } = useParams();

  // const { vendorId } = useContext(VendorContext);

  const [openContactModal, setOpenContactModal] = useState(false);
  const [ratingGiven, setRatingGiven] = useState(false);
  const [rating, setRating] = useState(0);
  const [ratingId, setRatingId] = useState("");
  // const [avgRating, setAvgRating] = useState(0);
  const displayRating = isNaN(avgRating) ? null : avgRating;

  function modalCloseHandler() {
    setOpenContactModal(false);
  }

  function loginSuccessHandler() {
    setOpenContactModal(false);
    onLogin();
  }

  function postRatingHandler(newValue) {
    if (isLoggedIn) {
      // TO UPDATE EXISTING RATING
      if (ratingGiven) {
        console.log("rating exists");
        setRating(newValue); //send post request here to save the rating by the logged in user for the specific vendor
        console.log(newValue);

        fetch(`${BASE_URL}/rating/updaterating`, {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            newRatingValue: newValue,
            ratingId: ratingId,
          }),
        })
          .then((response) => {
            if (response.ok) {
              return response.json();
            }
            throw new Error("Error: " + response.status);
          })
          .then((data) => {
            console.log(data);
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      } else {
        //TO CREATE NEW RATING IF NOT PREVIOUSLY RATED
        setRating(newValue); //send post request here to save the rating by the logged in user for the specific vendor
        console.log(newValue);

        fetch(`${BASE_URL}/rating/${vendorId}`, {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ rating: newValue, ratingId: ratingId }),
        })
          .then((response) => {
            if (response.ok) {
              return response.json();
            }
            throw new Error("Error: " + response.status);
          })
          .then((data) => {
            console.log(data);
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      }
    } else {
      //IF USER NOT LOGGED IN
      setOpenContactModal(true);
    }
  }

  //TO GET RATING GIVEN BY THE LOGGED IN USER TO THE SPECIFIC VENDOR
  useEffect(() => {
    fetch(`${BASE_URL}/rating/${vendorId}/getrating`, {
      credentials: "include",
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Error: " + response.status);
      })
      .then((data) => {
        if (isLoggedIn) {
          const userRating = data.userRatings.find(
            (rating) => rating.user._id === data.userId
          );
          if (userRating) {
            setRatingGiven(true);
            setRating(userRating.rating);
            setRatingId(userRating._id);

            console.log(userRating);
          }
        }

        console.log(data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, [vendorId, isLoggedIn]);

  const grossHitHandler = async () => {
    setOpenContactModal(true);
    console.log("hello");

    if (isLoggedIn === true) {
      try {
        const response = await fetch(`${BASE_URL}/user/contact/${vendorId}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
        });

        if (response.ok) {
        } else {
          console.log("Invalid credentials!!");
        }
      } catch (e) {
        console.error("Error occurred during login:", e);
      }
    }
  };

  return (
    <div className={"productDetailContainer"}>
      <div className="productDetailContainer-header">
        <div className="productDetailContainer-header-top">
          <div style={{ display: "flex" }}>
            <h1>{vendor.companyName}</h1>
            {displayRating !== null && (
              <div className="ratingsAvgDiv">
                <p>{avgRating}</p>
              </div>
            )}
          </div>
          <div className="productDetailContainer-icons">
            <CallIcon className="productDetailIcon" onClick={grossHitHandler} />
            <WhatsAppIcon
              className="productDetailIcon"
              onClick={grossHitHandler}
            />
          </div>
        </div>
        <h6>{vendor.address}</h6>
        <div className="productDetailContainer-header-bottom">
          <p style={{ fontWeight: "bold", marginTop: "20px" }}>
            Price Range: {`₹${vendor.startingPrice} - ₹${vendor.maximumPrice}`}
          </p>
          <div className="ratingDiv">
            <p>Rate us:</p>
            <Rating
              name="rating"
              value={rating}
              onChange={(event, newValue) => {
                postRatingHandler(newValue);
                // setRating(newValue);
              }}
            />
          </div>
          {/* <button>Message Us</button> */}
        </div>
      </div>

      {/* FOR PREMIUM VENDORS */}
      <div className="productDetailContainer-bottom">
        {/* <div className="productDetailContainer-bottom-mainImg">
            <img
              src={`http://localhost:8000/upload/${vendor.image1}`}
              alt="mainImg"
            />
          </div> */}
        <div className="productDetailContainer-bottom-imagesJ">
          <img
            // src={`http://localhost:8000/upload/${vendor.image2}`}
            src={`${BASE_URL}/upload/${vendor.image1}`}
            alt="mainsmallimg"
          />

          <img
            // src={`http://localhost:8000/upload/${vendor.image3}`}
            src={`${BASE_URL}/upload/${vendor.image2}`}
            alt="mainsmallimg"
          />

          <img
            // src={`http://localhost:8000/upload/${vendor.image4}`}
            src={`${BASE_URL}/upload/${vendor.image3}`}
            alt="mainsmallimg"
          />
          <video controls loop>
            <source
              //   src={`http://localhost:8000/upload/${vendor.video}`}
              src={`${BASE_URL}/upload/${vendor.video1}`}
              type="video/mp4"
            ></source>
          </video>

          <img
            // src={`http://localhost:8000/upload/${vendor.image4}`}
            src={`${BASE_URL}/upload/${vendor.image4}`}
            alt="mainsmallimg"
          />

          <video controls loop>
            <source
              //   src={`http://localhost:8000/upload/${vendor.video}`}
              src={`${BASE_URL}/upload/${vendor.video2}`}
              type="video/mp4"
            ></source>
          </video>
        </div>
      </div>
      {isLoggedIn ? (
        <VendorDetailModal
          open={openContactModal}
          onClose={modalCloseHandler}
          vendor={vendor}
        />
      ) : (
        <AskContactDetailsModal
          open={openContactModal}
          onClose={modalCloseHandler}
          onLogin={loginSuccessHandler}
        />
      )}
    </div>
  );
}

export default ProductDetailPremium;
