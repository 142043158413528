import React from "react";

function RefundCancellationPolicy() {
  return (
    <>
      {/* <h2>Refund and Cancellation Policy</h2> */}
      <p>
        The Refund and Cancellation policy govern the use of services provided
        by Party Help Desk to vendors who wish to render their products and
        services for the events that fall under the purview of Party Help Desk.
        By availing our services, end consumers & Service Providers/ Vendors
        agree to come in complete accord and be bound by the refund and
        cancellation policy set forth herein by the parent company viz: Jenny
        Blossom India (OPC) Private Limited.
        <ol>
          <li>
            The company provides an online platform viz; partyhelpdesk.com for
            Vendors and Service Providers to connect with event organisers or
            the end consumers and provide their services for various events.
          </li>
          <li>
            Vendor or the service provider shall be referred to as any business
            or entity that registers itself on the partyhelpdesk.com website,
            showcasing their services to the prospective clients through online
            ads placed on the site. End consumer shall be referred to as any
            person that visits the partyhelpdesk.com website and avails the
            services of any service provider listed on the website.
          </li>
          <li>
            Partyhelpdesk is an online consortium of service providers who
            provide services for any kind of party viz; Weddings, Birthdays,
            Marriage Anniversaries, Puja Rituals, Corporate Seminars &
            Conferences, Trade Shows, Corporate offsites, Tours and Travels etc.
            The company just provides an online platform for end consumers and
            service providers to connect with each other, negotiate & close
            deals between themselves and avail the services. End consumers must
            enquire about service providers credentials and track record by
            themselves, before closing a deal.
          </li>
          <li>
            Partyhelpdesk would not negotiate on behalf of end consumers with
            the service providers.
          </li>
          <li>
            The end consumer after availing the services shall pay directly to
            the vendor or the service provider towards the services availed as
            per the deal negotiated with the vendor or the service provider. The
            payment would not be routed through Partyhelpdesk. Any payment
            dispute or any other dispute has to be settled between the end
            consumer and the service provider or the vendor. The company shall
            not be held responsible in any way.
          </li>
          <li>
            The company cannot be deemed liable for any direct, indirect,
            incidental, consequential, or special damages emerging from or in
            connection with the use of its services. The vendor or the service
            provider would keep Partyhelpdesk indemnified on any dispute that
            may arise between the service provider and the end consumer.
          </li>
          <li>
            Company can deny registration or cancel registration of any
            vendor/service provider on its own discretion. The decision of the
            company shall be final and binding in this regard. In that event,
            the company shall refund the subscription fee to the vendor/service
            provider on a pro-rata basis without any interest that may have
            accrued and after deducting applicable taxes.
          </li>
          <li>
            Vendors or service providers shall not indulge in any activity that
            earns a bad name for the company or dents its reputation. In case of
            repeated complaints or unacceptable behavior with the end consumers
            or the company, the company reserves the right to cancel the service
            provider’s subscription and refund the money on pro-rata basis
            without any interest that may accrue, after deducting applicable
            taxes.
          </li>
          <li>
            In case the vendor or the service provider wishes to cancel his or
            her subscription anytime during the year, then the company shall
            cancel the subscription and refund the subscription fee to the
            vendor/service provider on a pro-rata basis without any interest
            that may have accrued and after deducting applicable taxes. The
            decision of the company shall be final and binding in this regard.
          </li>
          <li>
            The refund will be made within 7-10 working days and the amount will
            get credited in customer's account within this period.
          </li>
          <li>
            Any dispute that may arise should be settled amicably and if not
            then jurisdiction of Courts in Prayagraj shall apply.
          </li>
          <li>
            The guidelines stipulated above may be reviewed from time to time
            and amendments may be made, if the prevailing situation so demands.
          </li>
        </ol>
      </p>
    </>
  );
}

export default RefundCancellationPolicy;
