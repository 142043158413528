import React, { useState } from "react";
import { BASE_URL } from "../../apiConfig";
import classes from "./Login.module.css";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const input = { username: username, password: password };

      await fetch(`${BASE_URL}/admin/signin`, {
        method: "POST",
        body: JSON.stringify(input),
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      }).then((response) => {
        if (response.status === 200) {
          toast.success("Logged in successfully!", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "colored",
          });
          console.log("logged in");

          setTimeout(() => {
            navigate("/admin/dashboard");
          }, 1200);
        } else {
          toast.error("Invalid credentials! Try again.", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "colored",
          });
        }
        return response.json();
      });
    } catch (e) {
      console.log("admin not logged in");
    }
  };

  return (
    <>
      <div className={classes.wrapper}>
        <div className={classes.formContainer}>
          <form onSubmit={handleSubmit}>
            <h3>Admin Log In</h3>
            <div className="form-group">
              <input
                onChange={(e) => setUsername(e.target.value)}
                value={username}
                type="text"
                className="form-control"
                name="username"
                placeholder="Enter username"
              />
            </div>
            <div className="form-group">
              <input
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                type="password"
                className="form-control"
                name="password"
                placeholder="Enter password"
              />
            </div>

            <button type="submit" className="btn btn-primary">
              Log In
            </button>
          </form>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default Login;
