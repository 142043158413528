import React, { useState } from "react";
import { BASE_URL } from "../../../apiConfig";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import TextField from "@mui/material/TextField";
// import { MuiOtpInput } from "mui-one-time-password-input";
import logo from "../../../assets/logos/brandLogo.jpeg";
import classes from "./AskContactDetails.module.css";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 450,
  bgcolor: "background.paper",
  //   border: "2px solid #000",
  borderRadius: "15px",
  boxShadow: 24,
  p: 3,
  outline: "none",
  width: "450px" /* Default width for all screen sizes */,
  "@media (max-width: 768px)": {
    width: "95%" /* Width for screens up to 768px */,
  },
  "@media (max-width: 480px)": {
    width: "300px" /* Width for screens up to 480px */,
  },
};

function AskContactDetailsModal(props) {
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");

  async function loginHandler(e) {
    e.preventDefault();
    console.log(email);
    console.log(password);

    try {
      const input = { email: email, password: password };

      const response = await fetch(`${BASE_URL}/user/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(input),
        credentials: "include",
      });

      if (response.ok) {
        console.log("User logged in successfully!");
        toast.success("Logged in successfully!", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "colored",
        });
        // navigate("/services");
        setEmail("");
        setPassword("");
        setTimeout(() => {
          props.onLogin(); // Only call onLogin if login was successful
        }, 1200);
      } else {
        console.log("Invalid credentials!!");
        toast.error("Invalid credentials!", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (e) {
      console.error("Error occurred during login:", e);
      toast.error("An error occurred! Try again.", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "colored",
      });
    }
  }

  return (
    <>
      <Modal
        open={props.open}
        // onClose={() => props.onClose()}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
            style: { backdropFilter: "blur(5px)" },
          },
        }}
      >
        <Box sx={style} width={450}>
          <div className={classes.contactFormHeaderDiv}>
            <img src={logo} alt="party-helpdesk-logo" />
            <div className={classes.contactFormHeader}>
              <h2>Welcome</h2>
              <p>Login for a seamless experience</p>
            </div>
          </div>
          <div className={classes.otpFormDiv}>
            <form onSubmit={loginHandler}>
              <>
                <TextField
                  className={classes.inputField}
                  label="Email"
                  name="email"
                  color="primary"
                  value={email}
                  onChange={(event) => {
                    setEmail(event.target.value);
                  }}
                />
                <TextField
                  className={classes.inputField}
                  type="password"
                  label="Password"
                  name="password"
                  color="primary"
                  value={password}
                  onChange={(event) => {
                    setPassword(event.target.value);
                  }}
                />
                <div className={classes.formActions}>
                  <button type="submit">Login</button>
                  <p
                    onClick={() => navigate("/usersignup")}
                    className={classes.signUp}
                  >
                    Don't have an account? Sign up
                  </p>
                  <p onClick={() => props.onClose()}>Maybe Later</p>
                </div>
              </>
            </form>
          </div>
        </Box>
      </Modal>
      <ToastContainer />
    </>
  );
}

export default AskContactDetailsModal;
