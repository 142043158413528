import React from "react";
import classes from "./TableCategory.module.css";
import { BASE_URL } from "../../apiConfig";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import { useState, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";
import { Modal, Box } from "@mui/material";
import Fade from "@mui/material/Fade";

const data = [{}];

function TableTicket() {
  const [openForm, setOpenForm] = useState(false);
  const [form, setForm] = useState(false);
  const [tickets, setTickets] = useState([]); //to get all tickets
  const [isTicket, setIsTicket] = useState(false);
  const [data, setData] = useState({
    message: "",
  });

  const viewCustomerHandler = async (id) => {
    setOpenForm(true);
    console.log("hello");

    try {
      const response = await fetch(`${BASE_URL}/customer/ticket/${id}`);
      if (!response.ok) {
        throw new Error("Error fetching data");
      }
      const data = await response.json();
      console.log(data);
      setData(data.ticket);
    } catch (error) {
      console.log(error);
    }
  };

  const closeViewFormHandler = () => {
    setOpenForm(false);
  };

  const handleCheckboxChange = async (id, isChecked) => {
    try {
      const response = await fetch(`${BASE_URL}/updateTicketStatus/${id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ isTicket: isChecked }),
      });

      if (!response.ok) {
        throw new Error("Error updating ticket status");
      }

      const data = await response.json();
      setTickets((prevTickets) =>
        prevTickets.map((ticket) =>
          ticket._id === id ? { ...ticket, isTicket: isChecked } : ticket
        )
      );

      // setIsTicket(!isTicket);
    } catch (e) {
      console.log("error updating status");
    }
  };

  useEffect(() => {
    const getTickets = async () => {
      try {
        const response = await fetch(`${BASE_URL}/customer/ticket`);
        if (!response.ok) {
          throw new Error("Error retrieving tickets");
        }
        const data = await response.json();
        setTickets(data.tickets);
      } catch (error) {
        console.log(error);
      }
    };

    getTickets();
  }, []);

  return (
    <div className={classes.table}>
      <table className={`${classes["content-table"]} ${classes["main-table"]}`}>
        <tbody>
          <tr>
            <th>Customer Name</th>
            <th>Email</th>
            <th>Message</th>
            <th>Reply Status</th>
          </tr>
          {tickets.map((val, index) => {
            return (
              <tr key={val._id}>
                <td>{`${val.userId.firstname} ${val.userId.lastname}`}</td>
                <td>{val.userId.email}</td>
                <td>
                  <div className={classes.action}>
                    <VisibilityIcon
                      fontSize="small"
                      onClick={() => viewCustomerHandler(val._id)}
                    />
                  </div>
                </td>
                <td>
                  <input
                    type="checkbox"
                    checked={val.isTicket}
                    onClick={(e) =>
                      handleCheckboxChange(val._id, e.target.checked)
                    }
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      <Modal open={openForm} onClose={() => setOpenForm(false)}>
        <Fade in={openForm}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "60%",
              maxHeight: "500px",
              padding: " 20px",
              overflow: "auto",
              bgcolor: "#FFF",
              borderRadius: "15px",
              boxShadow: "0px 3px 8px rgba(0, 0, 0, 0.3)",
            }}
          >
            <div
              className={classes.viewCategoryForm}
              // id={form ? classes.formActive : " "}
            >
              <div className={classes["viewCategoryForm-Header"]}>
                <h3>View Message</h3>
                <CloseIcon
                  className={classes.closeIcon}
                  onClick={closeViewFormHandler}
                />
              </div>
              <hr />
              <div className={classes["viewCategoryForm-content"]}>
                <form>
                  <div className={classes.formInput}>
                    <label>Message</label>
                    <p>{data.message}</p>
                  </div>
                  <div className={classes.formControl}>
                    <button onClick={closeViewFormHandler}> Close</button>
                  </div>
                </form>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

export default TableTicket;
