import React from "react";
import classes from "./Footer.module.css";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import CallIcon from "@mui/icons-material/Call";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import { Link } from "react-router-dom";
import TermsConditions from "../../assets/pdfs/TermsConditions.pdf";
import RefundCancellation from "../../assets/pdfs/RefundCancellationPolicy.pdf";
import PrivacyPolicy from "../../assets/pdfs/PrivacyPolicy.pdf";
import Pricing from "../../assets/pdfs/Pricing.pdf";
import Faqs from "../../assets/pdfs/FAQs.pdf";
import CookiesPolicy from "../../assets/pdfs/Cookies Policy.pdf";
import CookiesSettings from "../../assets/pdfs/Cookies Setting.pdf";

function Footer() {
  return (
    <>
      <div className={classes.footer}>
        <div className={classes["footer-header"]}>
          <div className={classes["footer-row"]}>
            <h4>CONTENT</h4>
            {/* <p>Calendar of festivities</p>
            <p>New Events</p>
            <p>The Most Popular Events</p>
            <p>Search Trends</p> */}
            <p>
              <Link to="/blogs">Blog</Link>
            </p>
          </div>
          <div className={classes["footer-row"]}>
            <h4>INFORMATION</h4>
            <p>
              <Link to="/details/pricing">Pricing</Link>
            </p>
            <p>
              <Link to="/aboutus">About us</Link>
            </p>
            {/* <p>Business</p> */}

            <p>
              <Link to="/services">Services</Link>
            </p>
            <p>
              <Link to="/vendorlogin">Vendors</Link>
            </p>
            <p>
              <Link to="/vendorregistration/payment">Payment</Link>
            </p>
          </div>
          <div className={classes["footer-row"]}>
            <h4>LEGAL</h4>
            <p>
              <Link to="/details/termsconditions">Terms and conditions</Link>
            </p>
            <p>
              <Link to="/details/privacypolicy">Privacy policy</Link>
            </p>
            <p>
              <Link to="/details/refundcancellationpolicy">
                Refund & Cancellation Policy
              </Link>
            </p>
            {/* <p>Copyright Information</p> */}
            <p>
              <Link to="/details/cookiespolicy">Cookies policy</Link>
            </p>
            <p>
              <Link to="/details/cookiessetting">Cookies setting</Link>
            </p>
          </div>
          <div className={classes["footer-row"]}>
            <h4>SUPPORT</h4>
            <p>
              <Link to="/details/faqs">FAQs</Link>
            </p>
            <p>
              <Link to="/contactus">Contact us</Link>
            </p>
            <p className={classes.contactLink}>
              <Link to="tel:+918840152159">
                <CallIcon fontSize="small" />
                8840152159
              </Link>
            </p>
            <p className={classes.contactLink}>
              <Link to="mailto:partyhelpdesk@gmail.com">
                <EmailOutlinedIcon fontSize="small" />
                partyhelpdesk@gmail.com
              </Link>
            </p>
          </div>
          <div className={classes["footer-row"]}>
            {/* <h4>SOCIAL MEDIA</h4>
            <div className={classes.socialMediaIcons}>
              <InstagramIcon />
              <FacebookIcon />
            </div> */}
          </div>
        </div>
      </div>
      <div className={classes["footer-bottom"]}>
        <p> Trademark All rights reserved</p>
      </div>
    </>
  );
}

export default Footer;
