import React, { useState, useEffect } from "react";
import { BASE_URL } from "../../../apiConfig";
import classes from "./ServicesSearch.module.css";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import CloseIcon from "@mui/icons-material/Close";
import Checkbox from "@mui/material/Checkbox";
import { useParams } from "react-router-dom";

// const SUBCAT_LIST = [
//   "Photography",
//   "Decoration",
//   "Caterers",
//   "DJ/Music",
//   "Mehndi Artists",
//   "Makeup",
//   "Event Planning",
//   "Lorem Ipsum 1",
//   "Lorem Ipsum 2",
//   "Lorem Ipsum 3",
//   "Lorem Ipsum 4",
//   "Lorem Ipsum 5",
// ];

const SUBCAT_LIST = [
  "Wedding planners",
  "Theme weddings",
  "Event Management Companies",
  "Hotels",
  "Resorts",
  "Restaurants",
  "Banquet Halls",
  "Marriage Halls",
  "Guest House",
  "Dharamshala",
  "Homestays",
  "Tent Wala",
  "Photography & Videography",
  "Pre wedding shoots",
  "Mehndi Artist",
  "Electrician, Lighting, Generators",
  "DJ",
  "Orchestra/Stage Performers",
  "Buses",
  "Taxi Services",
  "Tours & Travels for Honeymoon",
  "Theme decoration",
  "Flower decoration",
  "Florists & Garlands",
  "Balloons decoration",
  "Other types of decoration",
  "Priest (eg.Pundits, Maulvi's)",
  "Invitation Cards",
  "Dancers & trainers",
  "Traditional musical instruments(Shehnai, Dhol Tasha)",
  "Band Baja",
  "Salon & Spa",
  "Dresses & attire (Men & Women)",
  "Accessories & artificial Jewellery",
  "Jewellers",
  "Caterers",
  "Cook (eg Khansama & halwai)",
  "Mineral water suppliers (drinking water)",
  "Paan wala (Beetle shops)",
  "Fruits vendor",
  "Kulfi & icecream",
  "Chatak Chaat wala",
  "Fruit Juices & sharbat",
  "Other snacks vendors",
  "Fireworks",
  "Baggi",
  "Luxury Cars",
  "Horses, elephants",
];

function ServicesSearch({ onServicesSet }) {
  const params = useParams();
  const [category, setCategory] = useState({});
  const [subCats, setSubCats] = useState([]); //array of subcategory names
  const [filteredServices, setFilteredServices] = useState(subCats);
  // const [subCats, setSubCats] = useState(SUBCAT_LIST); //array of subcategory names
  // const [filteredServices, setFilteredServices] = useState(SUBCAT_LIST);

  const [services, setServices] = useState([]);
  const [showServices, setShowServices] = useState(false);
  // console.log(services);

  useEffect(() => {
    const getCategory = async () => {
      try {
        const response = await fetch(
          `${BASE_URL}/categories/${params.servicename}`
        );
        if (response.ok) {
          const data = await response.json();
          setCategory(data);
          // const subcategories = data.subcategories.map(
          //   (subCat) => subCat.subcategoryName
          // );
          // setSubCats(subcategories);
          setSubCats(data.subcategories);
          setFilteredServices(data.subcategories);
          console.log(data.subcategories);
          console.log(data);
          console.log("hello");
        } else {
          throw new Error("Error retrieving category: " + response.status);
        }
      } catch (error) {
        console.error("Error retrieving category:", error);
      }
    };
    getCategory();
  }, [params.servicename]);

  function handleServicesFilter(e) {
    setShowServices(true);
    const searchWord = e.target.value;
    const newFilter = subCats.filter((service) => {
      return service.toLowerCase().includes(searchWord.toLowerCase());
    });

    setFilteredServices(newFilter);
  }

  function handleServiceSelect(e, serviceId) {
    //  console.log(e.target.checked);

    if (e.target.checked === true) {
      setServices((current) => [...current, serviceId]);
    } else if (e.target.checked === false) {
      //means someone deselected a previoulsy selected service, so we remove it from array
      setServices((prevServices) => {
        return prevServices.filter((serviceItem) => {
          return serviceItem !== serviceId;
        });
      });
    }
    onServicesSet(services);

    //  console.log(service);
  }

  function closeListHandler() {
    setShowServices(false);
  }

  function showListHandler() {
    setFilteredServices((prevValue) => [...prevValue]);
    setShowServices(true);
  }

  return (
    <>
      {/* SERVICES FILTER */}
      <div className={classes.search}>
        <div className={classes.searchInputs}>
          <input
            type="text"
            placeholder="Services"
            onChange={handleServicesFilter}
          />
          <div className={classes.searchIcon}>
            {!showServices ? (
              <ArrowDownwardIcon onClick={showListHandler} />
            ) : (
              <CloseIcon id={classes.clearBtn} onClick={closeListHandler} />
            )}
          </div>
        </div>

        {showServices && (
          <div className={classes.dataResult}>
            {/* {SUBCAT_LIST.map((service, index) => {
              return (
                <div key={service} className={classes.dataItem}>
                  <Checkbox
                    checked={services.includes(service) ? true : false}
                    color="default"
                    size="small"
                    onChange={(e) => handleServiceSelect(e, service._id)}
                    style={{ marginRight: "15px" }}
                  />
                  {service}
                </div>
              );
            })} */}
            {filteredServices.map((service, index) => {
              return (
                <div key={service._id} className={classes.dataItem}>
                  <Checkbox
                    checked={services.includes(service._id) ? true : false}
                    color="default"
                    size="small"
                    onChange={(e) => handleServiceSelect(e, service._id)}
                    style={{ marginRight: "15px" }}
                  />
                  {service.subcategoryName}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </>
  );
}

export default ServicesSearch;
