import React from "react";
import classes from "./FilterMenusSection.module.css";
import CitySearch from "./CitySearch";
import ServicesSearch from "./ServicesSearch";
import BudgetFilter from "./BudgetFilter";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

function FilterMenusSection({
  onCitySelect,
  onServicesSelect,
  onBudgetSelect,
  onFilterClick,
}) {
  // console.log(subCats)

  const handleCity = (city) => {
    onCitySelect(city);
  };
  const handleServices = (services) => {
    // console.log(services)
    console.log("filter menus");
    onServicesSelect(services);
  };
  const handleBudget = (option) => {
    onBudgetSelect(option);
  };

  const handleClick = () => {
    onFilterClick();
  };

  return (
    <div className={classes.filterMenus}>
      <CitySearch onCitySet={handleCity} />
      <ServicesSearch
        onServicesSet={handleServices}
        // subCats={subCats.map((subCat) => subCat.subcategoryName)}
      />
      <BudgetFilter onBudgetSet={handleBudget} />
      <button type="button" onClick={handleClick}>
        <ArrowForwardIcon fontSize="large" />
      </button>
    </div>
  );
}

export default FilterMenusSection;
