import React from "react";
import CallIcon from "@mui/icons-material/Call";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import classes from "./AskContactDetails.module.css";
import { Link } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 450,
  bgcolor: "background.paper",
  //   border: "2px solid #000",
  borderRadius: "15px",
  boxShadow: 24,
  p: 3,
  outline: "none",
};

function VendorDetailModal(props) {
  return (
    <div>
      <Modal
        open={props.open}
        onClose={() => props.onClose()}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
            style: { backdropFilter: "blur(5px)" },
          },
        }}
      >
        <Box sx={style} width={315}>
          <div className={classes.contactModalHeader}>
            <h2>Ski Event Planners</h2>
            <hr style={{ width: "20%" }} />
          </div>
          <div className={classes.contactModalContent}>
            <div className={classes.contactDetails}>
              <CallIcon className={classes.productDetailIcon} />
              <h4>
                <Link to={`tel:${props.vendor.phoneNumber}`}>
                  {props.vendor.phoneNumber}
                </Link>

                {/* <Link to="whatsapp://send?text=YoYoYo">987989089</Link> */}
              </h4>
            </div>
            <div className={classes.contactDetails}>
              <WhatsAppIcon className={classes.productDetailIcon} />
              <h4>
                <Link
                  to={`https://api.whatsapp.com/send?phone=${props.vendor.whatsappNumber}`}
                >
                  {props.vendor.whatsappNumber}
                </Link>
              </h4>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default VendorDetailModal;
