import React, { useEffect, useState } from "react";
import classes from "./Sidebar.module.css";
import { BASE_URL } from "../../apiConfig";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Link, useLocation } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import CategoryIcon from "@mui/icons-material/Category";
import "../pages/AdminPage.css";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import LogoutIcon from "@mui/icons-material/Logout";
import GroupsIcon from "@mui/icons-material/Groups";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";

function Sidebar() {
  const [currBtn, setCurrBtn] = useState("1");
  const navigate = useNavigate();

  const routePath = useLocation().pathname;
  useEffect(() => {
    if (routePath === "/admin/dashboard") {
      setCurrBtn("1");
    } else if (routePath === "/admin/category") {
      setCurrBtn("2");
    } else if (routePath === "/admin/blog") {
      setCurrBtn("3");
    } else if (routePath === "/admin/vendor") {
      setCurrBtn("4");
    } else if (routePath === "/admin/customer") {
      setCurrBtn("5");
    } else if (routePath === "/admin/ticket") {
      setCurrBtn("6");
    } else if (routePath === "/admin/enquiry") {
      setCurrBtn("7");
    } else if (routePath === "/admin/password") {
      setCurrBtn("8");
    }
  }, [routePath]);

  const logout = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${BASE_URL}/admin/logout`, {
        method: "GET",
        credentials: "include",
      });
      if (response.ok) {
        toast.success("Logged out successfully!", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "colored",
        });
        console.log("logged out");

        setTimeout(() => {
          navigate("/admin/login");
        }, 1200);
      } else {
        // Logout failed
        console.log("user not logged out");
        toast.error("Failed to logout!", { 
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (e) {
      toast.error("Failed to logout!", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "colored",
      });
      console.log("user not logged out", e);
    }
  };

  //id='sidebar-active'
  return (
    <>
      <div className={classes.sidebar}>
        <div className={classes["sidebar-content-container"]}>
          <Link style={{ textDecoration: "none" }} to="/admin/dashboard">
            <button
              className={
                currBtn === "1"
                  ? classes["sidebar-content-active"]
                  : classes["sidebar-content"]
              }
              id="1"
            >
              <HomeIcon className={classes.dashboardIcon} />
              <h3>Dashboard</h3>
              <ChevronRightIcon className={classes.rightIcon} />
            </button>
          </Link>
          <Link style={{ textDecoration: "none" }} to="/admin/category">
            <button
              className={
                currBtn === "2"
                  ? classes["sidebar-content-active"]
                  : classes["sidebar-content"]
              }
              id="2"
            >
              <CategoryIcon className={classes.dashboardIcon} />
              <h3>Categories</h3>
              <ChevronRightIcon className={classes.rightIcon} />
            </button>
          </Link>

          <Link style={{ textDecoration: "none" }} to="/admin/blog">
            <button
              className={
                currBtn === "3"
                  ? classes["sidebar-content-active"]
                  : classes["sidebar-content"]
              }
              id="3"
            >
              <CategoryIcon className={classes.dashboardIcon} />
              <h3>Blogs</h3>
              <ChevronRightIcon className={classes.rightIcon} />
            </button>
          </Link>

          <Link style={{ textDecoration: "none" }} to="/admin/vendor">
            <button
              className={
                currBtn === "4"
                  ? classes["sidebar-content-active"]
                  : classes["sidebar-content"]
              }
              id="4"
            >
              <GroupsIcon className={classes.dashboardIcon} />
              <h3>Vendors</h3>
              <ChevronRightIcon className={classes.rightIcon} />
            </button>
          </Link>
          <Link style={{ textDecoration: "none" }} to="/admin/customer">
            <button
              className={
                currBtn === "5"
                  ? classes["sidebar-content-active"]
                  : classes["sidebar-content"]
              }
              id="5"
            >
              <GroupsIcon className={classes.dashboardIcon} />
              <h3>Customers</h3>
              <ChevronRightIcon className={classes.rightIcon} />
            </button>
          </Link>

          <Link style={{ textDecoration: "none" }} to="/admin/ticket">
            <button
              className={
                currBtn === "6"
                  ? classes["sidebar-content-active"]
                  : classes["sidebar-content"]
              }
              id="6"
            >
              <GroupsIcon className={classes.dashboardIcon} />
              <h3>Tickets</h3>
              <ChevronRightIcon className={classes.rightIcon} />
            </button>
          </Link>

          <Link style={{ textDecoration: "none" }} to="/admin/enquiry">
            <button
              className={
                currBtn === "7"
                  ? classes["sidebar-content-active"]
                  : classes["sidebar-content"]
              }
              id="7"
            >
              <GroupsIcon className={classes.dashboardIcon} />
              <h3>Approvals</h3>
              <ChevronRightIcon className={classes.rightIcon} />
            </button>
          </Link>
          <Link style={{ textDecoration: "none" }} to="/admin/password">
            <button
              className={
                currBtn === "8"
                  ? classes["sidebar-content-active"]
                  : classes["sidebar-content"]
              }
              id="8"
            >
              <VpnKeyIcon className={classes.dashboardIcon} />
              <h3>Password</h3>
              <ChevronRightIcon className={classes.rightIcon} />
            </button>
          </Link>

          <Link style={{ textDecoration: "none" }} onClick={logout}>
            <button
              className={
                currBtn === "9"
                  ? classes["sidebar-content-active"]
                  : classes["sidebar-content"]
              }
              id="9"
            >
              <LogoutIcon className={classes.dashboardIcon} />
              <h3>Logout</h3>
              <ChevronRightIcon className={classes.rightIcon} />
            </button>
          </Link>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default Sidebar;
