import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

import Sidebar from "../components/Sidebar";
import classes from "./VendorPage.module.css";
import Dashboard from "../components/Dashboard";

function VendorPage() {
  // const [cookies] = useCookies([]);
  // const [myUsers, setMyUsers] = useState([]);

  // useEffect(() => {
  //   // Check if the token exists in the cookie
  //   if (cookies.tokenA) {
  //     // API call to validate the token on the server
  //     axios
  //       .post(
  //         "http://localhost:5000/validateAdminToken",
  //         {},
  //         { withCredentials: true }
  //       )
  //       .then((response) => {
  //         if (response.data.valid) {
  //           // setIsAuthenticated(true);
  //           console.log("trueeeeeeeeee");
  //           console.log(response.data.valid);
  //         } else {
  //           console.log("falseeeee");
  //           // setIsAuthenticated(false);
  //         }
  //       })
  //       .catch((error) => {
  //         console.error(error);
  //         console.log("falseeeeeeee");
  //         // setIsAuthenticated(false);
  //       });
  //   } else {
  //     console.log("falseeeeee");
  //     // setIsAuthenticated(false);
  //   }
  // }, [cookies]);

 

  // TO GET ALL USERS AND DISPLAY THEIR COUNT
  // useEffect(() => {
  //   console.log(document.cookie);
  //   axios
  //     .get("http://localhost:5000/vendor/users", {
  //       withCredentials: true,
  //     })
  //     .then((res) => {
  //       setMyUsers(res.data);
  //       console.log(res.data);
  //     })
  //     .catch((err) => {
  //       console.log("Error fetching users:" + err);
  //     });
  // }, []);

  return (
    <div className={classes.wrapper}>
      <Sidebar />
      <Dashboard />
    </div>
  );
}

export default VendorPage;
