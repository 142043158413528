import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
// import { VendorContextProvider } from "./context/VendorContext";
// MAIN WEBSITE IMPORTS
import Payment from "./website/pages/Payment";
import Homepage from "./website/pages/Homepage";
import LoginPage from "./website/pages/LoginPage";
import UserDashboard from "./website/pages/UserDashboard";
import VendorRegistration from "./website/pages/VendorRegistration";
import AboutUs from "./website/pages/AboutUs";
import Services from "./website/pages/Services";
import ServiceDetailPage from "./website/pages/ServiceDetailPage";
import ProductDetailPage from "./website/pages/ProductDetailPage";
import ContactUs from "./website/pages/ContactUs";
import MessageUs from "./website/pages/MessageUs";
import Blogs from "./website/pages/Blogs";
import BlogDetail from "./website/pages/BlogDetail";

// VENDOR PANEL IMPORTS
import VendorPage from "./vendorPanelNew/pages/VendorPage";
import CustomerPage from "./vendorPanelNew/pages/CustomerPage";
import PasswordPage from "./vendorPanelNew/pages/PasswordPage";
import VendorLogin from "./vendorPanelNew/pages/VendorLogin";
import VendorProfile from "./vendorPanelNew/pages/VendorProfile";
import EditProfile from "./vendorPanelNew/pages/EditProfile";

// ADMIN PANEL IMPORTS
import Dashboard from "./adminPanelNew/components/Dashboard.jsx";
import Password from "./adminPanelNew/components/Password";
import Customer from "./adminPanelNew/components/Customer";
import Login from "./adminPanelNew/pages/Login";
import Vendor from "./adminPanelNew/components/Vendor.jsx";
import Blog from "./adminPanelNew/components/Blog.jsx";
import Ticket from "./adminPanelNew/components/Ticket.jsx";
import Category from "./adminPanelNew/components/Category.jsx";
import Enquiry from "./adminPanelNew/components/Enquiry.jsx";
import FooterLinkOpenPage from "./website/pages/FooterLinkOpenPage";

import PrivateRoutesUser from "./utils/PrivateRoutesUser";
import PublicRoutesUser from "./utils/PublicRoutesUser";
import PublicRoutesVendor from "./utils/PublicRoutesVendor";
import PrivateRoutesVendor from "./utils/PrivateRoutesVendor";
import PublicRoutesAdmin from "./utils/PublicRoutesAdmin";
import PrivateRoutesAdmin from "./utils/PrivateRoutesAdmin";
import VendorPayment from "./website/pages/VendorPayment";
import PaymentStatus from "./website/pages/PaymentStatus";

function App() {
  const helmetContext = {};
  return (
    <HelmetProvider context={helmetContext}>
      {/* <VendorContextProvider> */}
      <Router>
        <div className="App">
          <Routes>
            {/* MAIN WEBSITE ROUTES */}
            <Route path="/" element={<Homepage />}></Route>
            <Route path="/payment" element={<Payment />}></Route>
            <Route path="/payment-status" element={<PaymentStatus />}></Route>

            {/* PUBLIC ROUTES USER */}
            <Route path="/" element={<PublicRoutesUser />}>
              <Route
                path="/userlogin"
                element={<LoginPage for="user" page="login" />}
              />
              <Route
                path="/usersignup"
                element={<LoginPage for="user" page="signUp" />}
              />
            </Route>

            {/* PRIVATE ROUTE USER */}
            <Route path="/" element={<PrivateRoutesUser />}>
              <Route path="/user" element={<UserDashboard />} />
            </Route>

            {/* PUBLIC ROUTES VENDOR */}
            <Route path="/" element={<PublicRoutesVendor />}>
              <Route
                path="/vendorlogin"
                element={
                  <LoginPage
                    for="vendor"
                    page="login"
                    canonicalHref="https://www.partyhelpdesk.com/vendorlogin"
                  />
                }
              />
              <Route
                path="/vendorregistration"
                element={<VendorRegistration />}
              />
            </Route>

            <Route
              path="/vendorregistration/payment"
              element={<VendorPayment />}
            ></Route>

            <Route path="/aboutus" element={<AboutUs />}></Route>
            <Route path="/services" element={<Services />}></Route>
            <Route
              path="/services/:servicename"
              element={<ServiceDetailPage />}
            ></Route>
            <Route
              path="services/:servicename/:vendorCompanyName/:vendorId"
              element={<ProductDetailPage />}
            ></Route>
            <Route path="/contactus" element={<ContactUs />}></Route>
            <Route path="/contactus/message" element={<MessageUs />}></Route>
            <Route
              path="/details/:filename"
              element={<FooterLinkOpenPage />}
            ></Route>
            <Route path="/blogs" element={<Blogs />}></Route>
            <Route
              path="/blogs/:blogTitle/:blogId"
              element={<BlogDetail />}
            ></Route>

            {/* VENDOR PANEL ROUTES */}
            {/* <Route path="/vendor/login" element={<VendorLogin />} /> */}

            {/* PRIVATE ROUTES VENDOR */}
            <Route path="/" element={<PrivateRoutesVendor />}>
              <Route path="/vendor/dashboard" element={<VendorPage />} />
              <Route path="/vendor/profile" element={<VendorProfile />} />
              <Route path="/vendor/edit" element={<EditProfile />} />
              <Route path="/vendor/customer" element={<CustomerPage />} />
              <Route path="/vendor/password" element={<PasswordPage />} />
            </Route>

            {/* ADMIN PANEL ROUTES */}
            {/* PUBLIC ROUTE ADMIN */}
            <Route path="/" element={<PublicRoutesAdmin />}>
              <Route exact path="/admin/login" element={<Login />} />
            </Route>
            <Route path="/" element={<PrivateRoutesAdmin />}>
              <Route
                exact
                path="/admin/dashboard"
                element={<Dashboard />}
              ></Route>
              <Route path="/admin/category" element={<Category />}></Route>
              <Route path="/admin/vendor" element={<Vendor />}></Route>
              <Route path="/admin/customer" element={<Customer />}></Route>
              <Route path="/admin/enquiry" element={<Enquiry />}></Route>
              <Route
                exact
                path="/admin/password"
                element={<Password />}
              ></Route>
              <Route exact path="/admin/blog" element={<Blog />}></Route>
              <Route exact path="/admin/ticket" element={<Ticket />}></Route>
            </Route>
          </Routes>
        </div>
      </Router>
      {/* </VendorContextProvider> */}
    </HelmetProvider>
  );
}

export default App;
