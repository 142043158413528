import React, { useState, useEffect, useContext } from "react";
// import { VendorContext } from "../../context/VendorContext";
import { useCookies } from "react-cookie";
import { useParams, useLocation } from "react-router-dom";
import { BASE_URL } from "../../apiConfig";
import ProductDetailPremium from "../components/ProductDetail/ProductDetailPremium";
import Overview from "../components/ProductDetail/Overview";
import FaqContainer from "../components/ProductDetail/FaqContainer";
import Similar from "../components/ProductDetail/Similar";
import Footer from "../components/Footer";
import MiddleBanner from "../components/MiddleBanner";
import Navbar from "../components/Navbar";
import MessageUsButton from "../components/MessageUsButton";
import pujaBanner from "../../assets/images/pujaBanner.webp";
import weddingBanner from "../../assets/images/weddingBanner.webp";
import birthdayBanner from "../../assets/images/birthdayBanner.webp";
import eventBanner from "../../assets/images/eventBanner.webp";
import ServiceBanner from "../components/ServiceDetailPage/ServiceBanner";
import ProductDetailBasic from "../components/ProductDetail/ProductDetailBasic";
import GoToTop from "../components/GoToTop";
import { Helmet } from "react-helmet-async";
import { ToastContainer } from "react-toastify";
// import GoToTop from "../components/GoToTop";

function ProductDetailPage() {
  const { vendorId, servicename } = useParams();
  // const { vendorId } = useContext(VendorContext);
  const [cookies] = useCookies(["token"]);
  const [isLoggedIn, setIsLoggedIn] = useState(true); //logged in status of user
  const [vendor, setVendor] = useState({});
  const [avgRating, setAvgRating] = useState(0);
  const [category, setCategory] = useState({});

  // const { vendorId } = useParams();

  // const location = useLocation();
  // console.log(location);

  const midBannerText = (
    <>
      Shor-sharaaba dhoom dhadakka
      <br />
      Event planning dekh har koi hoga
      <br />
      Hakka-bakka!
    </>
  );

  function loginThroughModalhandler() {
    console.log("main");
    setIsLoggedIn(true);
  }

  // let banner;

  // if (location.pathname.includes("wedding")) {
  //   banner = weddingBanner;
  // } else if (location.pathname.includes("birthday")) {
  //   banner = birthdayBanner;
  // } else if (location.pathname.includes("puja")) {
  //   banner = pujaBanner;
  // } else if (location.pathname.includes("corpevent")) {
  //   banner = eventBanner;
  // }

  function getCookie(cookieName) {
    const cookieString = document.cookie;
    const cookies = cookieString.split("; ");

    for (const cookie of cookies) {
      const [name, value] = cookie.split("=");
      if (name === cookieName) {
        console.log("foundddddddd");
        return decodeURIComponent(value);
      }
    }
    console.log("not found");
    return null;
  }

  useEffect(() => {
    // Check if the token exists in the cookie
    console.log(cookies.token);
    const myCookieValue = getCookie("token");
    console.log(myCookieValue);
    // if (cookies.token) {
    // API call to validate the token on the server
    fetch(`${BASE_URL}/validateUserToken`, {
      method: "POST",
      // headers: {
      //   "Content-Type": "application/json",
      //   Cookie: `token=${cookies.token}`,
      // },
      credentials: "include",
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Network response was not ok.");
        }
      })
      .then((data) => {
        if (data.valid) {
          setIsLoggedIn(true);
          console.log(data.valid);
        } else {
          setIsLoggedIn(false);
        }
      })
      .catch((error) => {
        console.error(error);
        setIsLoggedIn(false);
      });
    // } else {
    //   setIsLoggedIn(false);
    // }
  }, [cookies.token]);

  useEffect(() => {
    const getCategory = async () => {
      try {
        const response = await fetch(`${BASE_URL}/categories/${servicename}`);
        if (response.ok) {
          const data = await response.json();
          setCategory(data);
          console.log(data);
          console.log("hello");
        } else {
          throw new Error("Error retrieving category: " + response.status);
        }
      } catch (error) {
        console.error("Error retrieving category:", error);
      }
    };
    getCategory();
  }, [servicename]);

  useEffect(() => {
    const getVendor = async () => {
      try {
        const response = await fetch(`${BASE_URL}/vendor/${vendorId}`);
        if (response.ok) {
          const data = await response.json();
          setVendor(data);
        } else {
          throw new Error("Error retrieving vendor data: " + response.status);
        }
      } catch (error) {
        console.error("Error retrieving vendor data:", error);
      }
    };
    getVendor();
  }, [vendorId]);

  //TO GET AVG RATING FOR A SPECIFIC VENDOR
  useEffect(() => {
    fetch(`${BASE_URL}/rating/${vendorId}`)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Error: " + response.status);
      })
      .then((data) => {
        setAvgRating(data.avgRating);
        console.log(data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, [vendorId]);

  return (
    <div style={{ backgroundColor: "#f1f1f1" }}>
      <Helmet>
        <title>Party Help Desk</title>
        {/* <meta
          name="description"
          content="We can help you plan and execute any type of party, from a small birthday party to a large corporate event. Contact us today to get started!"
        /> */}
      </Helmet>
      <GoToTop />
      <MessageUsButton />
      <Navbar />
      <ServiceBanner image={category.banner} />

      {vendor.planSelected === "basic" && (
        <ProductDetailBasic
          vendorId={vendorId}
          vendor={vendor}
          isLoggedIn={isLoggedIn}
          onLogin={loginThroughModalhandler}
          avgRating={avgRating}
        />
      )}
      {vendor.planSelected === "premium" && (
        <ProductDetailPremium
          vendorId={vendorId}
          vendor={vendor}
          isLoggedIn={isLoggedIn}
          onLogin={loginThroughModalhandler}
          avgRating={avgRating}
        />
      )}

      <Overview vendor={vendor} />
      <MiddleBanner text={midBannerText} />
      {/* <FaqContainer /> */}
      {/* <Similar /> */}
      <Footer />
      {/* <GoToTop /> */}
    </div>
  );
}

export default ProductDetailPage;
