import React from "react";
import { BASE_URL } from "../../../apiConfig";
import classes from "./ServiceBanner.module.css";
import { useLocation } from "react-router-dom";

function ServiceBanner({ image }) {
  const location = useLocation();
  console.log("hello");
  console.log(image);

  // if (location.pathname.startsWith("/blogs")) {
  //   return (
  //     <div
  //       className={classes.banner}
  //       style={{
  //         backgroundImage: `url(${image})`,
  //         // backgroundImage: `url(${BASE_URL}/upload/${image})`,
  //       }}
  //     ></div>
  //   );
  // }

  // if (!image) {
  //   // If the image prop is undefined or not available, return null or any fallback component
  //   return null;
  // }

  return (
    <>
      <div
        className={classes.banner}
        style={{
          backgroundImage: `url(${BASE_URL}/upload/${image})`,
        }}
      ></div>
      {/* <img src={`${BASE_URL}/upload/${image}`} alt="blog-img" /> */}
    </>
  );
}

export default ServiceBanner;
