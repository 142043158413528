import React from "react";
import classes from "./TableCategory.module.css";
import { BASE_URL } from "../../apiConfig";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import { useState, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";
import { CKEditor, ckEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Modal, Box } from "@mui/material";
import Fade from "@mui/material/Fade";
import moment from "moment";

function TableBlog() {
  // const [openForm, setOpenForm] = useState(false);
  const [editForm, setEditForm] = useState(false);
  const [viewForm, setViewForm] = useState(false);
  // const [openEditForm, setOpenEditForm] = useState(false);
  const [blogs, setBlogs] = useState([]);
  const [activeId, setActiveId] = useState("");

  const formatBlogDate = (createdAt) => {
    const blogDate = moment(createdAt);

    return blogDate.format("MMM YYYY");
  };

  const [data, setData] = useState({
    title: " ",
    content: " ",
    bannerImg: " ",
    smallImg: " ",
    createdAt: " ",
  });

  const handleFileChange1 = (e) => {
    const file = e.target.files[0];
    setFormData((prevData) => ({ ...prevData, bannerImg: file }));
  };

  const handleFileChange2 = (e) => {
    const file = e.target.files[0];
    setFormData((prevData) => ({ ...prevData, smallImg: file }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    console.log(formData);
  };

  const [formData, setFormData] = useState({
    title: " ",
    content: " ",
    bannerImg: null,
    smallImg: null,
  });

  const viewEditBlogHandler = async (id) => {
    // setOpenEditForm(true);
    setEditForm(true);
    setActiveId(id);

    try {
      const response = await fetch(`${BASE_URL}/blog/${id}`);
      if (!response.ok) {
        throw new Error("Error retrieving blog data");
      }
      const existingData = await response.json();

      setFormData(existingData.blog);

      console.log(formData);
    } catch (error) {
      console.log(error);
    }
  };

  const viewBlogHandler = async (id) => {
    console.log(id);
    setViewForm(true);
    // setOpenForm(true);

    try {
      const response = await fetch(`${BASE_URL}/blog/${id}`);
      if (!response.ok) {
        throw new Error("Error fetching data");
      }
      const data = await response.json();
      console.log(data);
      setData(data.blog);
    } catch (error) {
      console.log(error);
    }
  };

  const closeViewFormHandler = () => {
    setViewForm(false);
  };

  useEffect(() => {
    const getBlogs = async () => {
      try {
        const response = await fetch(`${BASE_URL}/blog`);
        if (!response.ok) {
          throw new Error("Error retrieving blogs");
        }
        const data = await response.json();
        setBlogs(data.blogs);
      } catch (error) {
        console.log(error);
      }
    };

    getBlogs();
  }, []);

  //delete a particular blog
  const deleteBlog = async (id) => {
    // e.preventDefault();

    try {
      const response = await fetch(`${BASE_URL}/blog/${id}`, {
        method: "POST",
      });

      if (!response.ok) {
        throw new Error("Error deleting blog");
      }

      console.log("A blog has been deleted!");
      setBlogs((prevData) => prevData.filter((val) => val._id !== id));
    } catch (error) {
      console.log(error);
    }
  };

  //update a particular blog
  const handleUpdate = async (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();
    formDataToSend.append("title", formData.title);
    formDataToSend.append("content", formData.content);
    formDataToSend.append("bannerImg", formData.bannerImg);
    formDataToSend.append("smallImg", formData.smallImg);

    try {
      const response = await fetch(`${BASE_URL}/blog/updateBlog/${activeId}`, {
        method: "POST",
        body: formDataToSend,
        // headers: {
        //   "Content-Type": "multipart/form-data",
        // },
      });

      if (!response.ok) {
        throw new Error("Error updating blog");
      }

      const data = await response.json();
      console.log(data);
    } catch (error) {
      console.log(error);
    }
    setEditForm(false);
  };

  return (
    <div className={classes.table}>
      <table className={`${classes["content-table"]} ${classes["main-table"]}`}>
        <tbody>
          <tr>
            <th>Blog title</th>
            <th>Created At</th>
            <th>Action</th>
          </tr>
          {blogs.map((val, index) => {
            return (
              <tr key={val._id}>
                <td>{val.title}</td>
                <td>{formatBlogDate(val.createdAt)}</td>
                <td>
                  <div className={classes.action}>
                    <EditIcon
                      fontSize="small"
                      onClick={() => viewEditBlogHandler(val._id)}
                    />
                    <VisibilityIcon
                      fontSize="small"
                      onClick={() => viewBlogHandler(val._id)}
                    />
                    <DeleteIcon
                      fontSize="small"
                      onClick={() => deleteBlog(val._id)}
                    />
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      {/* VIEW BLOG */}
      <Modal open={viewForm} onClose={() => setViewForm(false)}>
        <Fade in={viewForm}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "80%",
              maxHeight: "500px",
              padding: " 20px",
              overflow: "auto",
              bgcolor: "#FFF",
              borderRadius: "15px",
              boxShadow: "0px 3px 8px rgba(0, 0, 0, 0.3)",
            }}
          >
            <div
              className={classes.viewCategoryForm}
              // id={form ? classes.formActive : " "}
            >
              <div className={classes["viewCategoryForm-Header"]}>
                <h3>View Blog</h3>
                <CloseIcon
                  className={classes.closeIcon}
                  onClick={closeViewFormHandler}
                />
              </div>
              <hr />
              <div className={classes["viewCategoryForm-content"]}>
                <h3>Blog Details</h3>

                <form>
                  <div className={classes.formInput}>
                    <label>Blog title</label>
                    <h4>{data.title}</h4>
                  </div>
                  <div className={classes.formInput}>
                    <label>Created At</label>
                    <h4>{formatBlogDate(data.createdAt)}</h4>
                  </div>

                  <div className={classes.formInput}>
                    <label>Uploaded banner image</label>
                    <Link to={`${BASE_URL}/upload/${data.bannerImg}`}>
                      View
                    </Link>
                  </div>
                  <div className={classes.formInput}>
                    <label>Uploaded small image</label>
                    <Link to={`${BASE_URL}/upload/${data.smallImg}`}>View</Link>
                  </div>
                  <div
                    // className={classes.formInput}
                    style={{ width: "100%", margin: "0, 15px" }}
                    // style={{ maxHeight: "60px", overflow: "auto" }}
                  >
                    <label style={{ fontWeight: "bold" }}>Content</label>
                    {/* <h4>{data.content}</h4> */}
                    <div dangerouslySetInnerHTML={{ __html: data.content }} />
                  </div>
                  <div className={classes.formControl}>
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={closeViewFormHandler}
                    >
                      Close
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>

      {/* EDIT BLOG FORM */}
      <Modal
        open={editForm}
        onClose={() => setEditForm(false)}
        disableEnforceFocus
      >
        <Fade in={editForm}>
          <Box
            // tabIndex={-1}
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "80%",
              maxHeight: "500px",
              padding: " 20px",
              overflow: "auto",
              bgcolor: "#FFF",
              borderRadius: "15px",
              boxShadow: "0px 3px 8px rgba(0, 0, 0, 0.3)",
            }}
          >
            <div
              className={classes.addVendorForm}
              // id={form ? classes.formActive : " "}
            >
              <div className={classes["addVendorForm-Header"]}>
                <h3>Edit Blog</h3>
                <CloseIcon
                  className={classes.closeIcon}
                  onClick={(e) => setEditForm(false)}
                />
              </div>
              <hr />
              <form
                encType="multipart/form-data"
                method="POST"
                onSubmit={handleUpdate}
              >
                <div className={classes.formInput}>
                  <label> Enter Blog title *</label>
                  <input
                    className={classes.vendorInput}
                    type="text"
                    value={formData.title}
                    onChange={handleInputChange}
                    name="title"
                    required
                  />
                </div>
                <div className={classes.formInput}>
                  <label> Upload Banner Image*</label>
                  <input
                    type="file"
                    name="bannerImg"
                    onChange={handleFileChange1}
                  />
                </div>
                <div className={classes.formInput}>
                  <label> Upload small image *</label>
                  <input
                    type="file"
                    name="smallImg"
                    onChange={handleFileChange2}
                  />
                </div>
                {/* <div className={classes.formInput}></div> */}

                <div
                  className={classes.contentInput}
                  // style={{ zIndex: "999999999" }}
                >
                  <label htmlFor="index">Upload Content* </label>
                  <CKEditor
                    name="content"
                    editor={ClassicEditor}
                    data={formData.content}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setFormData((prevState) => ({
                        ...prevState,
                        content: data,
                      }));
                    }}
                  />
                </div>
                <br />
                <div className={classes.formControl}>
                  <button onClick={(e) => setEditForm(false)}>Close</button>
                  <button type="submit">Submit</button>
                </div>
              </form>
            </div>
          </Box>
        </Fade>
      </Modal>

      {/* {openEditForm && (
        <div
          className={classes.addVendorForm}
          // id={form ? classes.formActive : " "}
        >
          <div className={classes["addVendorForm-Header"]}>
            <h3>Edit Blog</h3>
            <CloseIcon
              className={classes.closeIcon}
              onClick={(e) => setEditForm(false)}
            />
          </div>
          <form
            encType="multipart/form-data"
            method="POST"
            onSubmit={handleUpdate}
          >
            <div className={classes.formInput}>
              <label> Enter Blog title *</label>
              <input
                className={classes.vendorInput}
                type="text"
                value={formData.title}
                onChange={handleInputChange}
                name="title"
                required
              />
            </div>
            <div className={classes.formInput}>
              <label> Upload Banner Image*</label>
              <input
                type="file"
                name="bannerImg"
                onChange={handleFileChange1}
              />
            </div>
            <div className={classes.formInput}>
              <label> Upload small image *</label>
              <input type="file" name="smallImg" onChange={handleFileChange2} />
            </div>
            <div className={classes.formInput}></div>
            <div className={classes.contentInput}>
              <label htmlFor="index">Upload Content* </label>
              <CKEditor
                name="content"
                editor={ClassicEditor}
                data={formData.content}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setFormData((prevState) => ({
                    ...prevState,
                    content: data,
                  }));
                }}
              />
            </div>
            <br />
            <div className={classes.formControl}>
              <button onClick={(e) => setEditForm(false)}>Close</button>
              <button type="submit">Submit</button>
            </div>
          </form>
        </div>
      )} */}
    </div>
  );
}

export default TableBlog;
