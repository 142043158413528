import React from "react";
import classes from "./FooterLinkOpenPage.module.css";
import Navbar from "../components/Navbar";
import Banner from "../components/Banner";
import MiddleBanner from "../components/MiddleBanner";
import Footer from "../components/Footer";
import CookiesPolicy from "../components/FooterLinkPages/CookiesPolicy";
import CookiesSetting from "../components/FooterLinkPages/CookiesSetting";
import Faqs from "../components/FooterLinkPages/Faqs";
import Pricing from "../components/FooterLinkPages/Pricing";
import RefundCancellationPolicy from "../components/FooterLinkPages/RefundCancellationPolicy";
import TermsConditions from "../components/FooterLinkPages/TermsConditions";
import PrivacyPolicy from "../components/FooterLinkPages/PrivacyPolicy";
import { useParams, useNavigate } from "react-router-dom";
import GoToTop from "../components/GoToTop";
import banner from "../../assets/images/footerPageBanner.webp";
import MessageUsButton from "../components/MessageUsButton";
import { Helmet } from "react-helmet-async";

function FooterLinkOpenPage() {
  const param = useParams();

  const navigate = useNavigate();
  function sendToVendor() {
    navigate("/vendorregistration");
  }

  let heading;
  let href;
  let title;
  let description;

  if (param.filename === "cookiespolicy") {
    heading = "Cookie Policy";
    href = "https://www.partyhelpdesk.com/details/cookiespolicy";
    title = "Cookie Policy | Party Help Desk";
    description =
      "Party Help Desk uses cookies to enhance browsing experience, personalize content, and analyze website traffic.";
  } else if (param.filename === "cookiessetting") {
    heading = "Cookie Setting";
    href = "https://www.partyhelpdesk.com/details/cookiessetting";
    title = "Cookie Setting | Party Help Desk";
    description =
      "Party Help Desk manages cookie settings, ensures personalized online experience, respects privacy, and controls website usage.";
  } else if (param.filename === "faqs") {
    heading = "FAQs";
    href = "https://www.partyhelpdesk.com/details/faqs";
    title = "Frequently Asked Questions (FAQs) | Party Help Desk";
    description =
      "Explore FAQs for event management services, including planning process, cost, and availability.";
  } else if (param.filename === "pricing") {
    heading = "Pricing";
    href = "https://www.partyhelpdesk.com/details/pricing";
    title = "Pricing | Party Help Desk";
  } else if (param.filename === "privacypolicy") {
    heading = "Privacy Policy";
    href = "https://www.partyhelpdesk.com/details/privacypolicy";
    title = "Privacy Policy | Party Help Desk";
    description =
      "Explore Party Help Desk's thorough privacy policy to see confidentiality, practices, and safe data processing.Contact us if you have any questions or concerns regarding your privacy.";
  } else if (param.filename === "refundcancellationpolicy") {
    heading = "Refund & Cancellation Policy";
    href = "https://www.partyhelpdesk.com/details/refundcancellationpolicy";
    title = "Refund and Cancellation Policy | Party Help Desk";
    description =
      "Learn about the refund and cancellation policies for Party Help Desk's event management services. Learn how to cancel or seek a refund for a future event.";
  } else if (param.filename === "termsconditions") {
    heading = "Terms and Conditions";
    href = "https://www.partyhelpdesk.com/details/termsconditions";
    title = "Terms and Conditions | Party Help Desk";
    description =
      "Explore Party Help Desk's event management services' terms and conditions for a hassle-free booking and execution experience.";
  }

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <link rel="canonical" href={href} />
        <meta name="description" content={description} />
      </Helmet>
      <GoToTop />
      <MessageUsButton />
      <Navbar />
      <Banner image={banner} text={heading} />
      <div className={classes.contentDiv}>
        {param.filename === "cookiespolicy" && <CookiesPolicy />}
        {param.filename === "cookiessetting" && <CookiesSetting />}
        {param.filename === "faqs" && <Faqs />}
        {param.filename === "pricing" && <Pricing />}
        {param.filename === "privacypolicy" && <PrivacyPolicy />}
        {param.filename === "refundcancellationpolicy" && (
          <RefundCancellationPolicy />
        )}
        {param.filename === "termsconditions" && <TermsConditions />}
      </div>
      {param.filename === "pricing" && (
        <div className={classes.registerDiv}>
          <button onClick={sendToVendor}>Register as a Service Provider</button>
        </div>
      )}
      <Footer />
    </>
  );
}

export default FooterLinkOpenPage;
