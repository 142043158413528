import React from "react";
import classes from "./Banner.module.css";

function Banner({ image, text }) {
  return (
    <div
      className={classes.banner}
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.4),
rgba(0, 0, 0, 0.4)), url(${image})`,
      }}
    >
      <div className={classes.bannerHeading}>
        <h1>{text}</h1>
      </div>
    </div>
  );
}

export default Banner;
