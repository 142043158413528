import React from "react";
import Faq from "./Faq";
import "./FaqContainer.css";

function FaqContainer() {
  return (
    <div className="faqContainer">
      <h3>FAQs</h3>
      <Faq
        ques="What all civic facilities are available close by?"
        ans="The various civic facilities that you can enjoy are supermarkets, schools, restaurants, petrol-pumps, parks, malls, hospitals, bus-stops, banks, atm, airport etc. The various
civic facilities that you can enjoy are supermarkets, schools, restaurants, petrol-pumps, parks, malls, hospitals, bus-stops, banks, atm, airport etc."
      />
      <Faq
        ques="What all civic facilities are available close by?"
        ans="The various civic facilities that you can enjoy are supermarkets, schools, restaurants, petrol-pumps, parks, malls, hospitals, bus-stops, banks, atm, airport etc. The various
civic facilities that you can enjoy are supermarkets, schools, restaurants, petrol-pumps, parks, malls, hospitals, bus-stops, banks, atm, airport etc."
      />
      <Faq
        ques="What all civic facilities are available close by?"
        ans="The various civic facilities that you can enjoy are supermarkets, schools, restaurants, petrol-pumps, parks, malls, hospitals, bus-stops, banks, atm, airport etc. The various
civic facilities that you can enjoy are supermarkets, schools, restaurants, petrol-pumps, parks, malls, hospitals, bus-stops, banks, atm, airport etc."
      />
    </div>
  );
}

export default FaqContainer;
