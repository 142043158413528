import React from "react";
import classes from "./TableEnquiry.module.css";
import { BASE_URL } from "../../apiConfig";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import { useState, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";
import { Modal, Box } from "@mui/material";
import Fade from "@mui/material/Fade";

function TableEnquiry() {
  const [form2, setForm2] = useState(false);
  const [form, setForm] = useState(false);
  const [activeId, setActiveId] = useState("");
  const [openForm, setOpenForm] = useState("");
  const [approve, setApprove] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState("");
  const [approvedData, setApprovedData] = useState(0);

  const [items, setItems] = useState([]);
  const [data, setData] = useState({
    transactionId: "",
    merchantTransactionId: "",
    paymentDetails: {},
    companyName: "",
    partnerName: "",
    startingPrice: "",
    maximumPrice: "",
    businessYears: "",
    projectsNum: "",
    categoriesSelected: [],
    address: "",
    city: "",
    state: "",
    planSelected: "",
    pincode: "",
    email: "",
    whatsappNumber: "",
    phoneNumber: "",
    websiteURL: "",
    fbLink: "",
    instaLink: "",
    gstNumber: "",
    additionalInfo: "",
    overview: "",
    image1: "",
    image2: "",
    image3: "",
    image4: "",
    video1: "",
    video2: "",
  });

  function toggleApprovedData() {
    setOpenForm(!openForm);
    setApprovedData(approvedData + 1);
  }

  useEffect(() => {
    const getCategories = async () => {
      try {
        const response = await fetch(`${BASE_URL}/filterdata`);
        if (!response.ok) {
          throw new Error("Error fetching categories");
        }
        const data = await response.json();
        setItems(data);
        console.log(data);
      } catch (error) {
        console.log(error);
      }
    };

    getCategories();
  }, [approvedData]);

  useEffect(() => {
    const getData = async (id) => {
      try {
        const response = await fetch(`${BASE_URL}/vendor/${id}`);
        if (!response.ok) {
          throw new Error("Error fetching data");
        }
        const data = await response.json();
        console.log(data);
        setData(data);
        console.log(data.paymentDetails);
      } catch (error) {
        console.log(error);
      }
    };

    getData(activeId);
  }, [approvedData, activeId]);

  //   useEffect(()=>{
  //       getData(activeId);
  //   },[activeId])

  // function toggleForm2 (id){
  //     setForm2(!form2)
  //     setActiveId(id)
  // }

  const viewCategoryHandler = (id) => {
    setOpenForm(true);
    setActiveId(id);
    console.log("hello");
  };

  const closeViewFormHandler = () => {
    setOpenForm(false);
  };

  const handleSubmit = async () => {
    //  e.preventDefault();
    toggleApprovedData();
    setOpenForm(!openForm);
    try {
      const response = await fetch(`${BASE_URL}/category/${activeId}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ approved: true }),
      });

      if (!response.ok) {
        throw new Error("Error updating category");
      }

      // const data = await response.json();
      // setUpdatedData(data);
      // setFormData(data);
      // console.log(data);
    } catch (error) {
      console.log(error);
    }
  };

  //delete a vendor
  const deleteCategory = async (id) => {
    try {
      await fetch(`${BASE_URL}/vendor/${id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id }),
      });
      setItems((prevData) => prevData.filter((item) => item._id !== id));
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={classes.table}>
      <table className={classes["content-table"]}>
        <tbody>
          <tr>
            <th>Business Name</th>
            <th>Price Range</th>
            <th>Plan</th>
            <th>Action</th>
          </tr>
          {items.map((val, index) => {
            return (
              <tr key={index}>
                <td>{val.companyName}</td>
                <td>{`₹${val.startingPrice}-₹${val.maximumPrice}`}</td>
                <td>{val.planSelected}</td>
                <td>
                  <div className={classes.action}>
                    <VisibilityIcon
                      fontSize="small"
                      onClick={() => {
                        viewCategoryHandler(val._id);
                      }}
                    />
                    <DeleteIcon
                      fontSize="small"
                      onClick={() => {
                        deleteCategory(val._id);
                      }}
                    />
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      <Modal open={openForm} onClose={() => setOpenForm(false)}>
        <Fade in={openForm}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              overflow: "auto",
              maxHeight: "30rem",
              width: "60%", //default width for all screens
              "@media (max-width: 768px)": {
                width: "85%" /* Width for screens up to 768px */,
                maxHeight: "85%",
              },
              "@media (max-width: 480px)": {
                width: "95%" /* Width for screens up to 480px */,
                maxHeight: "95%",
              },
              bgcolor: "#FFF",
              borderRadius: "15px",
              boxShadow: "0px 3px 8px rgba(0, 0, 0, 0.3)",
            }}
          >
            <div className={classes.viewCategoryForm}>
              <div className={classes["viewCategoryForm-Header"]}>
                <h3>View Vendor</h3>
                <CloseIcon
                  className={classes.closeIcon}
                  onClick={closeViewFormHandler}
                />
              </div>
              <hr />
              <div className={classes["viewCategoryForm-content"]}>
                <h3>Vendor's Information</h3>

                <form>
                  <div className={classes.formInput}>
                    <label>Vendor Id</label>
                    <h4>{data._id}</h4>
                  </div>
                  {data.merchantTransactionId && (
                    <div className={classes.formInput}>
                      <label>Merchant Transaction Id</label>
                      <h4>{data.merchantTransactionId}</h4>
                    </div>
                  )}
                  {data.transactionId && (
                    <div className={classes.formInput}>
                      <label>Transaction Id</label>
                      <h4>{data.transactionId}</h4>
                    </div>
                  )}
                  {data.paymentDetails?.data?.state && (
                    <div className={classes.formInput}>
                      <label>Payment Status</label>
                      <h4>{data.paymentDetails.data.state}</h4>
                    </div>
                  )}
                  {data.paymentDetails?.data?.amount && (
                    <div className={classes.formInput}>
                      <label>Amount Paid</label>
                      <h4>
                        ₹
                        {(
                          parseInt(data.paymentDetails.data.amount) / 100
                        ).toFixed(2)}
                      </h4>
                    </div>
                  )}

                  <div className={classes.formInput}>
                    <label>Company Name*</label>
                    <h4>{data.companyName}</h4>
                  </div>
                  <div className={classes.formInput}>
                    <label>Partner's Name*</label>
                    <h4>{data.partnerName}</h4>
                  </div>
                  <div className={classes.formInput}>
                    <label>Business Price Range *</label>
                    <h4>{`₹${data.startingPrice}-₹${data.maximumPrice}`}</h4>
                  </div>
                  <div className={classes.formInput}>
                    <label>Years in business *</label>
                    <h4>{data.businessYears}</h4>
                  </div>
                  <div className={classes.formInput}>
                    <label>Number of projects till date*</label>
                    <h4>{data.projectsNum}</h4>
                  </div>
                  <div
                    className={classes.formInput}
                    // style={{ maxHeight: "150px", overflow: "auto" }}
                  >
                    <label>Categories Selected*</label>
                    {/* <h4>{data.categoriesSelected}</h4> */}
                    <ul>
                      {data.categoriesSelected.map((category) => (
                        <li>{category.categoryName}</li>
                      ))}
                    </ul>
                  </div>
                  {/* <div className={classes.formInput}>
                    <label>Subcategories Selected*</label>
                    <h4>{data.subcategoriesSelected}</h4>
                  </div> */}
                  <div className={classes.formInput}>
                    <label>Address *</label>
                    <h4>{data.address}</h4>
                  </div>
                  <div className={classes.formInput}>
                    <label>City *</label>
                    <h4>{data.city}</h4>
                  </div>
                  <div className={classes.formInput}>
                    <label>Pincode*</label>
                    <h4>{data.pincode}</h4>
                  </div>
                  <div className={classes.formInput}>
                    <label>State*</label>
                    <h4>{data.state}</h4>
                  </div>
                  <div className={classes.formInput}>
                    <label>Mobile Number*</label>
                    <h4>{data.phoneNumber}</h4>
                  </div>
                  <div className={classes.formInput}>
                    <label>Whatsapp number*</label>
                    <h4>{data.whatsappNumber}</h4>
                  </div>
                  <div className={classes.formInput}>
                    <label>Email *</label>
                    <h4>{data.email}</h4>
                  </div>
                  <div className={classes.formInput}>
                    <label>Website URL</label>
                    <h4>{data.websiteURL}</h4>
                  </div>
                  <div className={classes.formInput}>
                    <label>Facebook Link</label>
                    <h4>{data.fbLink}</h4>
                  </div>
                  <div className={classes.formInput}>
                    <label>Instagram Link</label>
                    <h4>{data.instaLink}</h4>
                  </div>
                  <div className={classes.formInput}>
                    <label>GST Number</label>
                    <h4>{data.gstNumber}</h4>
                  </div>
                  <div className={classes.formInputBig}>
                    <label style={{ fontWeight: "bold" }}>
                      Additional Information*
                    </label>
                    <h6>{data.additionalInfo}</h6>
                  </div>
                  <div className={classes.formInputBig}>
                    <label style={{ fontWeight: "bold" }}>Overview*</label>
                    <h6>{data.overview}</h6>
                  </div>
                  {data.planSelected === "premium" && (
                    <>
                      <div className={classes.formInput}>
                        <label>Uploaded image 1</label>
                        <Link to={`${BASE_URL}/upload/${data.image1}`}>
                          View
                        </Link>
                      </div>
                      <div className={classes.formInput}>
                        <label>uploaded image 2 </label>
                        <Link to={`${BASE_URL}/upload/${data.image2}`}>
                          View
                        </Link>
                      </div>
                      <div className={classes.formInput}>
                        <label>uploaded image 3 </label>
                        <Link to={`${BASE_URL}/upload/${data.image3}`}>
                          View
                        </Link>
                      </div>
                      <div className={classes.formInput}>
                        <label>uploaded image 4 </label>
                        <Link to={`${BASE_URL}/upload/${data.image4}`}>
                          View
                        </Link>
                      </div>
                      <div className={classes.formInput}>
                        <label>uploaded video 1 </label>
                        <Link to={`${BASE_URL}/upload/${data.video1}`}>
                          View
                        </Link>
                      </div>
                      <div className={classes.formInput}>
                        <label>uploaded video 2</label>
                        <Link to={`${BASE_URL}/upload/${data.video2}`}>
                          View
                        </Link>
                      </div>
                    </>
                  )}
                </form>
                <div className={classes.formControl}>
                  <button type="submit" onClick={(e) => handleSubmit(data._id)}>
                    Approve
                  </button>
                  <button type="button" onClick={toggleApprovedData}>
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
      {/* <div
        className={classes.shadow}
        id={openForm ? classes.shadowActive : " "}
      ></div> */}
    </div>
  );
}

export default TableEnquiry;
