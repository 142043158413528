import React from "react";
import Login from "../components/LoginPage/Login";
import Navbar from "../components/Navbar";
import GoToTop from "../components/GoToTop";
import MessageUsButton from "../components/MessageUsButton";
import { Helmet } from "react-helmet-async";
import { ToastContainer } from "react-toastify";

function LoginPage(props) {
  return (
    <>
      {/* <Navbar /> */}
      <Helmet>
        <title>Party Help Desk</title>
        <link rel="canonical" href={props.canonicalHref} />
      </Helmet>
      <GoToTop />
      <Login page={props.page} for={props.for} />
      <MessageUsButton />
      <ToastContainer />
    </>
  );
}

export default LoginPage;
