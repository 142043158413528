import React, { useState, useEffect } from "react";
import moment from "moment";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SummaryCard from "../SummaryCard";
import classes from "./BlogsSlider.module.css";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import { BASE_URL } from "../../../apiConfig";

const responsive = [
  {
    breakpoint: 1024,
    settings: {
      slidesToShow: 2,
      slidesPerRow: 2,
      rows: 1,
      // slidesToScroll: 1,
      infinite: true,
    },
  },
  {
    breakpoint: 768,
    settings: {
      slidesToShow: 2,
      slidesPerRow: 2,
      rows: 1,
      // slidesToScroll: 1,
      centerMode: false,

      initialSlide: 1,
      infinite: false,
    },
  },
  {
    breakpoint: 620,
    settings: {
      slidesToShow: 1,
      rows: 1,
      centerMode: false,

      // slidesPerRow: 2,

      // slidesToScroll: 1,
      initialSlide: 1,
      infinite: false,
    },
  },
  {
    breakpoint: 480,
    settings: {
      slidesToShow: 1,
      centerMode: false,

      rows: 1,
      // slidesToScroll: 1,
    },
  },
];

function BlogsSlider() {
  const [items, setItems] = useState([]);

  useEffect(() => {
    const fetchItems = async () => {
      try {
        const response = await fetch(`${BASE_URL}/blog`);
        if (!response.ok) {
          throw new Error("Error fetching items");
        }

        const data = await response.json();
        //  console.log(data.blogs)
        setItems(data.blogs);
        console.log(data.blogs);
      } catch (e) {
        console.error("Error fetching items:", e);
      }
    };
    fetchItems();
  }, []);

  const formatBlogDate = (createdAt) => {
    const blogDate = moment(createdAt);

    return blogDate.format("MMM YYYY");
  };

  const NextArrow = ({ onClick }) => {
    //we get the onClick as prop from slider's nextArrow
    return (
      <div className={`${classes.arrow} ${classes.next}`} onClick={onClick}>
        <FaArrowRight />
      </div>
    );
  };

  const PrevArrow = ({ onClick }) => {
    return (
      <div className={`${classes.arrow} ${classes.prev}`} onClick={onClick}>
        <FaArrowLeft />
      </div>
    );
  };

  const settings = {
    responsive: responsive,
    className: "center",
    centerMode: true,
    infinite: true,
    // infinite: false,
    centerPadding: "60px",
    slidesToShow: 3,
    speed: 500,
    // rows: 2, //new
    // slidesPerRow: 2,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };
  return (
    <div className={classes.wrapper}>
      {/* <h4>Recent Blogs</h4> */}
      {/* <h4>No blogs yet...</h4> */}

      <Slider {...settings}>
        {items.map((blog) => (
          <SummaryCard
            key={blog._id}
            heading={blog.title}
            id={blog._id}
            detail={blog.content}
            image={blog.smallImg}
            date={formatBlogDate(blog.createdAt)}
            userRatings={[]}
          />
        ))}
      </Slider>
    </div>
  );
}

export default BlogsSlider;

{
  /* <div
            className={classes.summaryCard}
            style={{
              display: "grid",
              width: "90%",
              gridTemplateRows: "1fr",
              gridTemplateColumns: "1fr 1.1fr",
              padding: "1%",
              margin: "10px",
            }}
          > */
}

{
  /* </div> */
}
