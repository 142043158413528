import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { BASE_URL } from "../apiConfig";
import { useCookies } from "react-cookie";

function PublicRoutesVendor() {
  const [cookies] = useCookies(["newToken"]);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    // Check if the token exists in the cookie
    // if (cookies.newToken) {
    // API call to validate the token on the server
    fetch(`${BASE_URL}/validateVendorToken`, {
      method: "POST",
      // headers: {
      //   "Content-Type": "application/json",
      //   Cookie: `token=${cookies.newToken}`,
      // },
      credentials: "include",
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Network response was not ok.");
        }
      })
      .then((data) => {
        if (data.valid) {
          setIsAuthenticated(true);
          console.log(data.valid);
        } else {
          setIsAuthenticated(false);
        }
      })
      .catch((error) => {
        console.error(error);
        setIsAuthenticated(false);
      });
    // } else {
    //   setIsAuthenticated(false);
    // }
  }, [cookies]);

  if (isAuthenticated) {
    return <Navigate to="/vendor/dashboard" replace />;
  }

  return (
    <>
      <Outlet />
    </>
  );
}

export default PublicRoutesVendor;
