import React, { useState } from "react";
import classes from "./ExpectationCard.module.css";
import { Link, useLocation } from "react-router-dom";

function ExpectationCard(props) {
  let location = useLocation();

  const iconDiv = (
    <div className={classes.iconDiv}>
      <img src={props.icon} alt="category-icon" />
      <h6>{props.heading}</h6>
    </div>
  );

  const imageDiv = (
    <div className={classes.imageDiv}>
      <img src={props.image} alt="category-icon" />
      <h6>{props.heading}</h6>
    </div>
  );

  if (location.pathname === "/") {
    // console.log("homepage");
  } else if (location.pathname === "/services") {
    // console.log("services page");
  }

  return (
    <div className={classes.cardDiv}>
      {location.pathname === "/" && iconDiv}
      {location.pathname === "/services" && imageDiv}
      <hr />
      <div className={classes.subCatDiv}>
        <div className={classes.list}>
          <ul style={{ listStyle: "initial" }} className={classes.gridList}>
            {/* {props.list.map((item) => ( */}
            {props.list.slice(0, 10).map((item) => (
              <Link>
                <li>{item}</li>
              </Link>
            ))}
          </ul>
        </div>
      </div>
      <div className={classes.viewMoreDiv}>
        <Link to={props.link}>View more</Link>
      </div>
    </div>
  );
}

export default ExpectationCard;
