import React, { useState } from "react";
import classes from "./BudgetFilter.module.css";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import CloseIcon from "@mui/icons-material/Close";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const BUDGET_FILTERS = ["₹0 - 20,000", "₹20,000 - 50,000", "₹50,000 - 1Lac", "₹1Lac - 2Lac", "₹3Lac - 6Lac", "₹6Lac - 9Lac", ];

function BudgetFilter({ onBudgetSet }) {
  const [filteredOptions, setFilteredOptions] = useState(BUDGET_FILTERS);
  const [option, setOption] = useState("");
  const [budget, setBudget] = useState({
    startingPrice: null,
    maximumPrice: null,
  });
  const [showOptions, setShowOptions] = useState(false);
  const [inputBudget, setInputBudget] = useState("");
  // console.log(inputBudget);

  function handleBudgetInputChange(e) {
    setOption(e.target.value);
  }

  function handleOptionFilter(e) {
    const searchWord = e.target.value;
    setShowOptions(true);
    setOption(searchWord);
    const newFilter = BUDGET_FILTERS.filter((budget) => {
      return budget.toLowerCase().includes(searchWord.toLowerCase());
    });

    setFilteredOptions(newFilter);
  }

  // FROM SET YOUR BUDGET INPUT FORM
  function inputBudgetHandler(e) {
    e.preventDefault();
    setOption(inputBudget);
    // setBudget({
    //   startingPrice: inputBudget
    // })
    setShowOptions(false);
  }

  function handleOptionSelect(option) {
    onBudgetSet(option); //option
    setOption(option);

    console.log(option);
    setShowOptions(false);
  }

  function handleClearInput() {
    setShowOptions(false);
  }

  return (
    <>
      {/* BUDGET FILTER */}
      <div className={classes.search}>
        <div className={classes.searchInputs}>
          <input
            type="text"
            placeholder="Budget"
            onChange={handleOptionFilter}
            value={option}
          />
          <div className={classes.searchIcon}>
            {showOptions === false ? (
              <ArrowDownwardIcon onClick={() => setShowOptions(true)} />
            ) : (
              <CloseIcon id={classes.clearBtn} onClick={handleClearInput} />
            )}
          </div>
        </div>

        {showOptions && (
          <div className={classes.optionsMenu}>
            <div className={classes.dataResult}>
              <div className={`${classes.dataItem} ${classes.optionsHeader}`}>
                Select your Budget
              </div>
              {filteredOptions.map((option, index) => {
                return (
                  <div
                    key={index}
                    className={classes.dataItem}
                    onClick={() => handleOptionSelect(option)}
                  >
                    {option}
                  </div>
                );
              })}
              {/* <div className={classes.dataItem}>Other</div> */}
            </div>
            {/* <form onSubmit={inputBudgetHandler}>
              <div className={classes.addBudgetDiv}>
                <div className={classes.inputGroup}>
                  <label htmlFor="budget">Set your Budget</label>
                  <input
                    type="text"
                    placeholder="₹0000"
                    value={inputBudget}
                    onChange={(e) => setInputBudget(e.target.value)}
                    // value={option}
                    // onChange={handleBudgetInputChange}
                  />
                </div>
                <button type="submit">
                  <ArrowForwardIcon fontSize="large" />
                </button>
              </div>
            </form> */}
          </div>
        )}
      </div>
    </>
  );
}

export default BudgetFilter;
