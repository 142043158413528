import React from "react";
import { Link } from "react-router-dom";
import classes from "./HomepageText.module.css";

function HomepageText() {
  return (
    <div className={classes.contentDiv}>
      <h1>Extensive network of ace events' service providers</h1>
      <p>
        At Party Help Desk AKA PHD, we are your one-stop destination for all
        your event management needs across India. Whether you're planning{" "}
        <Link to="https://www.partyhelpdesk.com/services/corporate-event">
          a grand corporate gathering
        </Link>
        , a memorable wedding celebration, or a conference, we are here to make
        your event shine with unparalleled expertise and creativity.
      </p>
      <p>
        As a trusted event management company platform, we have service
        providers that cater to all of your event planning and management needs.
        Ranging from
        <Link to="https://www.partyhelpdesk.com/services/wedding">
          {" "}
          wedding vendors{" "}
        </Link>
        to corporate event planners, top photographers, décor, and mehndi
        artists to name a few.
      </p>
      <p>
        At Party Help Desk, we incorporate strict service provider
        authentication. Relevant background checks are done before onboarding a
        partner onto our platform. This is to ensure that you procure the
        best-in-class services in your upcoming events. Be it, event organisers
        or{" "}
        <Link to="https://www.partyhelpdesk.com/services/birthday">
          birthday party planners
        </Link>
        , or wedding planners; we work closely with you, our valued client - to
        understand your vision, preferences, and objectives.
      </p>
    </div>
  );
}

export default HomepageText;
