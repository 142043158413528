import React, { useEffect, useState } from "react";
import { BASE_URL } from "../../apiConfig";
// import { Link } from "react-router-dom";
import classes from "./ServiceDetailPage.module.css";
import Navbar from "../components/Navbar";
import ServiceBanner from "../components/ServiceDetailPage/ServiceBanner";
import MiddleBanner from "../components/MiddleBanner";
import Footer from "../components/Footer";
import pujaBanner from "../../assets/images/pujaBanner.webp";
import weddingBanner from "../../assets/images/weddingBanner.webp";
import birthdayBanner from "../../assets/images/birthdayBanner.webp";
import eventBanner from "../../assets/images/eventBanner.webp";
import MessageUsButton from "../components/MessageUsButton";
import ServiceProvidersSection from "../components/ServiceDetailPage/ServiceProvidersSection";
import FilterMenusSection from "../components/ServiceDetailPage/FilterMenusSection";
import { useLocation, useParams } from "react-router-dom";
import GoToTop from "../components/GoToTop";
import { Helmet } from "react-helmet-async";

function ServiceDetailPage() {
  const [category, setCategory] = useState({});
  const [vendors, setVendors] = useState([]);
  const [hasVendors, setHasVendors] = useState(true);
  const [filteredVendors, setFilteredVendors] = useState([]);
  const [isFiltered, setIsFiltered] = useState(false);

  const params = useParams();

  const [city, setCity] = useState("");
  const [services, setServices] = useState([]);
  const [budget, setBudget] = useState({});

  console.log(budget);

  const midBannerText = (
    <>
      Shor-sharaaba dhoom dhadakka
      <br />
      Event planning dekh har koi hoga
      <br />
      Hakka-bakka!
    </>
  );

  const handleCitySelect = (citySelected) => {
    console.log("hello hi");
    console.log(citySelected);
    setCity(citySelected);
  };

  // console.log(services)

  const handleServicesSelect = (servicesSelected) => {
    console.log("hi");
    console.log(servicesSelected);
    setServices(servicesSelected);
  };

  const handleBudgetSelect = (option) => {
    if (option === "₹0 - 20,000") {
      setBudget({
        startingPrice: 0,
        maximumPrice: 20000,
      });
    } else if (option === "₹20,000 - 50,000") {
      setBudget({
        startingPrice: 20000,
        maximumPrice: 50000,
      });
    } else if (option === "₹50,000 - 1Lac") {
      setBudget({
        startingPrice: 50000,
        maximumPrice: 100000,
      });
    } else if (option === "₹1Lac - 2Lac") {
      setBudget({
        startingPrice: 100000,
        maximumPrice: 200000,
      });
    } else if (option === "₹3Lac - 6Lac") {
      setBudget({
        startingPrice: 300000,
        maximumPrice: 600000,
      });
    } else if (option === "₹6Lac - 9Lac") {
      setBudget({
        startingPrice: 600000,
        maximumPrice: 900000,
      });
    }
    console.log("bye");
    // console.log(budgetSelected)
    //  setBudget(budgetSelected)
  };

  //filter data function
  const handleFilterVendors = async () => {
    let element = document.getElementById("vendorsSection");
    let position = element.offsetTop;
    window.scrollTo({
      top: position,
      behavior: "smooth",
    });
    console.log(budget);
    // console.log("hello filter");
    // console.log(params.servicename);
    // console.log(services);
    try {
      const queryParams = new URLSearchParams({
        city,
        startingPrice: budget.startingPrice,
        maximumPrice: budget.maximumPrice,
        subcategoriesSelected: services.toString(),
      });
      const response = await fetch(
        `${BASE_URL}/vendor/filter/data/${params.servicename}?${queryParams}`
      );
      if (response.ok) {
        const data = await response.json();
        // console.log(data);
        setIsFiltered(true);
        setFilteredVendors(data);
        if (data.length === 0) {
          setHasVendors(false);
        } else {
          setHasVendors(true);
        }
      } else {
        setHasVendors(false);
        throw new Error("Error retrieving vendors: " + response.status);
      }
    } catch (error) {
      console.error("Error retrieving vendors:", error);
      setHasVendors(false);
    }
  };

  const location = useLocation();
  // // console.log(location);

  // let banner;
  let heading;
  let href;
  let title;
  let description;

  if (location.pathname === "/services/wedding") {
    // banner = weddingBanner;
    heading = "Weddings made memorable with Party Help Desk service providers!";
    href = "https://www.partyhelpdesk.com/services/wedding";
    title =
      "Wedding Planner | Your One-Stop Shop for Planning the Perfect Wedding";
    description =
      "We offer a range of services to help you plan the perfect wedding, from venue selection to catering and vendors coordination.";
  } else if (location.pathname === "/services/birthday") {
    // banner = birthdayBanner;
    heading =
      "Birthdays made unforgettable with Party Help Desk service providers!";
    href = "https://www.partyhelpdesk.com/services/birthday";
    title =
      "Birthday Planner Services | Professional Help Planning Your Child's Special Day";
    description =
      "Our birthday planners will help you plan the perfect party for your child, offering services such as catering, entertainment, and party favors.";
  } else if (location.pathname === "/services/puja") {
    // banner = pujaBanner;
    heading = "Seasoned professionals for seamless puja preparations";
    href = "https://www.partyhelpdesk.com/services/puja";
    title = "Puja Organizer | A Complete Guide to Organizing a Puja";
    description =
      "We offer a complete guide to organizing a puja, from finding the right priest to choosing the right offerings.";
  } else if (location.pathname === "/services/corporate-event") {
    // banner = eventBanner;
    heading = "Unparalleled corporate event management services";
    href = "https://www.partyhelpdesk.com/services/corporate-event";
    title =
      "Corporate Event Planner | The Best in Event Planning for Businesses";
    description =
      "We have years of experience planning events for businesses of all sizes, creating an event that is both effective and enjoyable for guests.";
  }

  // TO GET SEPCIFIC CATEGORY DETAILS
  useEffect(() => {
    const getCategory = async () => {
      try {
        const response = await fetch(
          `${BASE_URL}/categories/${params.servicename}`
        );
        if (response.ok) {
          const data = await response.json();
          setCategory(data);
        } else {
          throw new Error("Error retrieving category: " + response.status);
        }
      } catch (error) {
        console.error("Error retrieving category:", error);
      }
    };
    getCategory();
  }, [params.servicename]);

  // TO GET VENDORS FOR A SPECIFIC CATEGORY
  useEffect(() => {
    const getVendors = async () => {
      // console.log("get vendors")
      try {
        const response = await fetch(
          `${BASE_URL}/getvendors/${params.servicename}`
        );
        if (response.ok) {
          const data = await response.json();
          setVendors(data);
          console.log(data);
          // console.log("hello");
        } else {
          throw new Error("Error retrieving vendors: " + response.status);
        }
      } catch (error) {
        console.error("Error retrieving vendors:", error);
      }
    };
    getVendors();
  }, [params.servicename]);

  console.log(category.subcategories);

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <link rel="canonical" href={href} />
        <meta name="description" content={description} />
      </Helmet>
      <GoToTop />
      <MessageUsButton />
      <Navbar />
      <ServiceBanner image={category.banner} />
      <div className={classes.headingDiv}>
        <h1>{heading}</h1>
        <hr id={classes.long} />
        <hr id={classes.short} />
      </div>
      <div className={classes.contentFiltersWrapper}>
        <FilterMenusSection
          subCats={category.subcategories}
          onCitySelect={handleCitySelect}
          onBudgetSelect={handleBudgetSelect}
          onServicesSelect={handleServicesSelect}
          onFilterClick={handleFilterVendors}
        />
      </div>

      <div className={classes.contentDiv}>
        <div dangerouslySetInnerHTML={{ __html: category.content }} />
      </div>
      <MiddleBanner text={midBannerText} />
      <ServiceProvidersSection
        vendors={isFiltered ? filteredVendors : vendors}
        hasVendors={hasVendors}
        servicename={params.servicename}
      />
      <Footer />
    </>
  );
}

export default ServiceDetailPage;
