import React from "react";
import Navbar from "../components/Navbar";
import UserContainer from "../components/UserDashboard/UserContainer";
import Banner from "../components/Banner";
import MiddleBanner from "../components/MiddleBanner";
import Footer from "../components/Footer";
import MessageUsButton from "../components/MessageUsButton";
import image from "../../assets/images/contactBanner.webp";
import GoToTop from "../components/GoToTop";
import { Helmet } from "react-helmet-async";

function UserDashboard() {
  const midBannerText = (
    <>
      Jashn hoga yaadgaar
      <br />
      Party Help Desk haina yaar!
    </>
  );
  return (
    <div>
      <Helmet>
        <title>Party Help Desk| User dashboard</title>
      </Helmet>
      <GoToTop />
      <MessageUsButton />
      <Navbar />
      <Banner image={image} text="Account" />
      <UserContainer />
      <MiddleBanner text={midBannerText} />
      <Footer />
      {/* <Footer /> */}
      {/* <GoToTop /> */}
    </div>
  );
}

export default UserDashboard;
