import React, { useState, useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "../../apiConfig";
import classes from "./CustomerDetailsPopUp.module.css";
import CloseIcon from "@mui/icons-material/Close";

function CustomerDetailsPopUp(props) {
  const [userDetails, setUserDetails] = useState({});
  const [hasDetails, setHasDetails] = useState(true);
  const [activeId, setActiveId] = useState("");

  //to GET customers who contacted this specific vendor    
  useEffect(() => {
    fetch(`${BASE_URL}/getusers/${props.userId}`, {
      method: "GET",
      credentials: "include",
    }) // set unique route with user id in URL to get specific books for each user in the reading space
      .then((res) => res.json())
      .then((data) => {
        const userDetails = data;
        setUserDetails(userDetails);
        if (userDetails) {
          setHasDetails(true);
        } else {
          setHasDetails(false);
        }
        console.log(userDetails);
      })
      .catch((err) => {
        console.log("Error fetching details: " + err);
      });
  }, [props.userId]);

  function closePopUpHandler() {
    props.onClose();
  }

  const userDetailsCard = (
    <>
      <div className={classes["PopUp-content"]}>
        <div className={classes["PopUp-content-row"]}>
          <h4>Plot:</h4>
          <p>{userDetails.plot ? userDetails.plot : ""}</p>
        </div>
        <div className={classes["PopUp-content-row"]}>
          <h4>Landmark:</h4>
          <p>{userDetails.landmark ? userDetails.landmark : ""}</p>
        </div>
        <div className={classes["PopUp-content-row"]}>
          <h4>Street:</h4>
          <p>{userDetails.street ? userDetails.street : ""}</p>
        </div>
        <div className={classes["PopUp-content-row"]}>
          <h4>City:</h4>
          <p>{userDetails.city ? userDetails.city : ""}</p>
        </div>
        <div className={classes["PopUp-content-row"]}>
          <h4>State:</h4>
          <p>{userDetails.state ? userDetails.state : ""}</p>
        </div>
        <div className={classes["PopUp-content-row"]}>
          <h4>Pin Code:</h4>
          <p>{userDetails.pincode ? userDetails.pincode : ""}</p>
        </div>
      </div>
    </>
  );

  return (
    <>
      <div className={classes.customersPopUp}>
        <div className={classes["PopUp-header"]}>
          <h3>User Details</h3>
          <CloseIcon
            className={classes.closeIcon}
            onClick={closePopUpHandler}
          />
        </div>
        <hr />
        {!hasDetails ? (
          <div className={classes.noCustomersContent}>
            <h6>No Details available!</h6>
          </div>
        ) : (
          userDetailsCard
        )}
      </div>
      <div className={classes.shadow} id={classes.shadowActive}></div>
    </>
  );
}

export default CustomerDetailsPopUp;
