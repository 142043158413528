import React from "react";

function CookiesPolicy() {
  return (
    <>
      {/* <h2>Cookies Policy</h2> */}
      <p>
        Party Help Desk uses cookies on its website for you to have an enhanced
        experience. These cookies are necessary for the website to function and
        cannot be switched off in our systems. They are usually only set in
        response to actions made by you, such as setting your privacy
        preferences, logging in or filling in forms. You can set your browser to
        block or alert you about these cookies, but some parts of the site will
        not then work.
      </p>
    </>
  );
}

export default CookiesPolicy;
