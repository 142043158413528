import React from "react";
import classes from "./MiddleBanner.module.css";

function MiddleBanner({ text }) {
  return (
    <div className={classes.banner}>
      <div className={classes.bannerHeading}>
        <p>{text}</p>
      </div>
    </div>
  );
}

export default MiddleBanner;
