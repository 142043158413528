import React, { useState } from "react";
import Slider from "react-slick";
import { BASE_URL } from "../../../apiConfig";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import classes from "./ServicesSlider.module.css";
import ServicesCard from "./ServicesCard";
// import categoryImg from "../../../assets/images/categoryImg.webp";

const responsive = [
  {
    breakpoint: 1024,
    settings: {
      slidesToShow: 3,
      slidesToScroll: 1,
      infinite: true,
    },
  },
  {
    breakpoint: 768,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 1,
      initialSlide: 1,
      // infinite: false
    },
  },
  {
    breakpoint: 480,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
    },
  },
];

function ServicesSlider({ categories }) {
  const [imageIndex, setImageIndex] = useState(0);

  const NextArrow = ({ onClick }) => {
    //we get the onClick as prop from slider's nextArrow
    return (
      <div className={`${classes.arrow} ${classes.next}`} onClick={onClick}>
        <FaArrowRight />
      </div>
    );
  };

  const PrevArrow = ({ onClick }) => {
    return (
      <div className={`${classes.arrow} ${classes.prev}`} onClick={onClick}>
        <FaArrowLeft />
      </div>
    );
  };

  const settings = {
    responsive: responsive,
    focusOnSelect: true,
    autoPlay: true,
    infinite: true,
    lazyLoad: true,
    speed: 300,
    slidesToShow: 3,
    centerMode: true,
    centerPadding: 0,
    swipeToSlide: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    beforeChange: (current, next) => setImageIndex(next),
  };

  console.log(categories);

  return (
    <div className={classes.wrapper}>
      <h4>Services</h4>
      {/* <div>CARD</div> */}
      <div style={{ padding: "0 20px" }}>
        <Slider {...settings}>
          {categories.map((category, index) => (
            <div
              key={category._id}
              className={
                index === imageIndex
                  ? `${classes.slide} ${classes.activeSlide}`
                  : classes.slide
              }
              // style={{width: "80%"}}
            >
              <ServicesCard
                // key={category.id}
                image={`${BASE_URL}/upload/${category.slider}`}
                heading={category.categoryName}
                imgText="Part without any hassle"
                text={category.subcategories
                  .map((subCat) => subCat.subcategoryName)
                  .join(", ")}
              />
            </div>
          ))}
          {/* {CATEGORY_LIST.map((category, index) => (
            <div
              className={
                index === imageIndex
                  ? `${classes.slide} ${classes.activeSlide}`
                  : classes.slide
              }
            >
              <ServicesCard
                key={category.id}
                image={category.image}
                heading={category.heading}
                imgText={category.imgText}
                text={category.text}
              />
            </div>
          ))} */}
        </Slider>
      </div>

      <div className={classes.line}>{/* <hr /> */}</div>
    </div>
  );
}

export default ServicesSlider;
