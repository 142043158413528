import React, { useState } from "react";
import { BASE_URL } from "../../apiConfig";
import axios from "axios";
import Header from "../components/Header";
import classes from "./PasswordPage.module.css";
import Sidebar from "../components/Sidebar";

function PasswordPage() {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");

  // to change vendor password
  async function changePasswordHandler(e) {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      setMessage("Passwords don't match!");
      return;
    }

    try {
      const response = await fetch(`${BASE_URL}/changepassword`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          oldPassword,
          newPassword,
          confirmPassword,
        }),
        credentials: "include",
      });

      const data = await response.json();
      setMessage(data.message);
      console.log(message);
      setOldPassword("");
      setNewPassword("");
      setConfirmPassword("");
    } catch (error) {
      console.error(error);
      // setMessage("Error changing password");
    }
  }

  return (
    <div className={classes.wrapper}>
      <Sidebar />

      <div className={classes.password}>
        <Header />
        <div className={classes["password-container"]}>
          <h2>
            <strong>Change Password</strong>
          </h2>
          <form onSubmit={changePasswordHandler}>
            <div className={classes.formInput}>
              <label htmlFor="oldPassword">Enter Current Password*</label>
              <input
                className={classes.categoryInput}
                type="password"
                name="oldPassword"
                id="oldPassword"
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
                required
              />
            </div>
            <div className={classes.formInput}>
              <label htmlFor="newPassword">Enter New Password*</label>
              <input
                className={classes.categoryInput}
                type="password"
                name="newPassword"
                id="newPassword"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
              />
            </div>
            <div className={classes.formInput}>
              <label htmlFor="confirmPassword">Confirm New Password*</label>
              <input
                className={classes.categoryInput}
                type="password"
                name="confirmPassword"
                id="confirmPassword"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
            </div>

            <p style={{ color: "grey" }}>{message}</p>

            <div className={classes.passwordBottomBar}>
              <button type="submit">Save Changes</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default PasswordPage;
