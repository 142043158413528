import React from "react";
import FirstForm from "../components/VendorRegistration/FirstForm";
import Navbar from "../components/Navbar";
import MiddleBanner from "../components/MiddleBanner";
import Footer from "../components/Footer";
import MessageUsButton from "../components/MessageUsButton";
import GoToTop from "../components/GoToTop";
import classes from "./VendorPayment.module.css";
import vendorBanner from "../../assets/images/vendorBanner.webp";
import PaymentInfo from "../components/VendorPayment/PaymentInfo";
import { Helmet } from "react-helmet-async";

function VendorPayment() {
  return (
    <>
      <Helmet>
        <title>
          Find party Vendors in All - Venues, Photographers, Bridal Makeup &
          more
        </title>
        <link
          rel="canonical"
          href="https://www.partyhelpdesk.com/vendorregistration/payment"
        />
        <meta
          name="description"
          content="vendors including Venue, Photographer, Decoraters, Wedding planners, Corporate events Planners etc. Read Reviews & check prices."
        />
      </Helmet>
      <GoToTop />
      <MessageUsButton />
      <Navbar />
      <div
        className={classes.banner}
        style={{
          backgroundImage: `url(${vendorBanner})`,
        }}
      ></div>
      {/* <FirstForm /> */}
      <div className={classes.contentDiv}>
        <PaymentInfo />
      </div>
      {/* <MiddleBanner /> */}
      <Footer />
    </>
  );
}

export default VendorPayment;
