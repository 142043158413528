import React, { useState } from "react";
import { Link } from "react-router-dom";
import classes from "./Navbar.module.css";
import brandLogo from "../../assets/logos/brandLogo.jpeg";

function Navbar() {
  const [openDropdown, setOpenDropdown] = useState(false);

  function dropdownHandler() {
    setOpenDropdown((prev) => !prev);
  }

  const navbarDropdown = (
    <div className={classes.dropdown}>
      <div className={classes.dropdownLink}>
        <Link to="/aboutus">About Us</Link>
      </div>
      <hr />
      <div className={classes.dropdownLink}>
        <Link to="/services">Services</Link>
      </div>
      <hr />
      <div className={classes.dropdownLink}>
        <Link to="/blogs">Blog</Link>
      </div>
      <hr />
      <div className={classes.dropdownLink}>
        <Link to="/contactus">Contact Us</Link>
      </div>
    </div>
  );

  return (
    <div className={classes.navbarWrapper}>
      {/* RESPONSIVE NAVBAR FOR DISPLAY ON SCREEN 768PX AND BELOW */}
      <div className={classes.navbarResponsive}>
        <div className={classes.navbarMain}>
          <div className={classes.logoDiv}>
            <Link to="/">
              <img src={brandLogo} alt="partyhelpdesk-logo" />
            </Link>
          </div>
          <div className={classes.menuIcon} onClick={dropdownHandler}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        {openDropdown && navbarDropdown}
      </div>
      {/* -------------------------------- */}
      <div className={classes.navbar}>
        <div className={classes.navbarMain}>
          <div className={classes.menuIcon} onClick={dropdownHandler}>
            <span></span>
            <span></span>
            <span></span>
          </div>
          <div className={classes.logoDiv}>
            <Link to="/">
              <img src={brandLogo} alt="partyhelpdesk-logo" />
            </Link>
          </div>
        </div>
        {openDropdown && navbarDropdown}
      </div>
    </div>
  );
}

export default Navbar;
