import React from "react";
import { BASE_URL } from "../../apiConfig";
import classes from "./SummaryCard.module.css";
import { Link } from "react-router-dom";
import staticImage from "../../assets/images/blogImg.webp";
import sample from "../../assets/images/categoryImg.webp";
import { useLocation } from "react-router-dom";

const BLOG = {
  blog: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse et fermentum orci. Suspendisse sit amet tincidunt ex. Sed ac tempus ex. Quisque hendrerit hendrerit purus nec malesuada. Phasellus vel malesuada eros, eu",
};

function SummaryCard({ id, heading, detail, image, date, plan, userRatings }) {
  console.log(userRatings);
  const location = useLocation();
  // const detailShort = detail.substring(0, 80);

  let avgRating;
  let sum = 0,
    count = 0;

  userRatings.forEach((val) => {
    count += 1;
    sum += val.rating;
  });

  // avgRating = 5.0

  avgRating = (sum / count).toFixed(1);
  console.log(plan);

  //for link to a blog
  const lowercaseBlogHeading = heading.toLowerCase();
  const modifiedBlogHeading = lowercaseBlogHeading.replace(/\s+/g, "-");

  const blogSummaryCard = (
    <>
      <div className={classes.summaryCard}>
        <>
          <div className={classes.blogImgDiv}>
            <img src={`${BASE_URL}/upload/${image}`} alt="blog-img" />
          </div>
          <div className={classes.blogSummary}>
            <div className={classes.content}>
              {/* <h6>Lorem Ipsum Dolor</h6> */}
              <h6>{heading.substring(0, 21)}...</h6>
              <p className={classes.date}>{date}</p>
              <div
                dangerouslySetInnerHTML={{ __html: detail.substring(0, 80) }}
              />
            </div>
            <div className={classes.knowMoreDiv}>
              <p>
                <Link
                  style={{ color: "black" }}
                  to={`/blogs/${modifiedBlogHeading}/${id}`}
                >
                  Read more
                </Link>
              </p>
            </div>
          </div>
        </>
      </div>
    </>
  );

  const vendorSummaryCard = (
    <>
      <div className={classes.summaryCard}>
        <>
          <div className={classes.vendorImgDiv}>
            {!isNaN(avgRating) && (
              <div className={classes.avgRatingsDiv}>
                <p>{avgRating}</p>
              </div>
            )}
            {plan === "premium" && (
              <img src={`${BASE_URL}/upload/${image}`} alt="vendor-img" />
            )}
            {plan === "basic" && <img src={staticImage} alt="vendor-img" />}
            {/* <img src={`${BASE_URL}/upload/${image}`} alt="vendor-img" /> */}
          </div>
          <div className={classes.blogSummary}>
            <div className={classes.content}>
              <h6>{heading.substring(0, 21)}...</h6>
              <p>{detail.substring(0, 90)}...</p>
            </div>
            <div className={classes.knowMoreDiv}>
              <p>Know more</p>
            </div>
          </div>
        </>
      </div>
    </>
  );

  if (location.pathname === "/blogs") {
    return blogSummaryCard;
  }

  return vendorSummaryCard;
}

export default SummaryCard;
