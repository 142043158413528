import React, { useEffect, useState } from "react";
import axios from "axios";
import { BASE_URL } from "../../apiConfig";
import classes from "./Sidebar.module.css";
import { useLocation } from "react-router-dom";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PersonIcon from "@mui/icons-material/Person";
import EditIcon from "@mui/icons-material/Edit";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import LogoutIcon from "@mui/icons-material/Logout";
import GroupsIcon from "@mui/icons-material/Groups";
import { ToastContainer, toast } from "react-toastify";

import { Link } from "react-router-dom";

function Sidebar(props) {
  const [currBtn, setCurrBtn] = useState("1");

  // FOR VENDOR LOGOUT
  const logoutHandler = () => {
    fetch(`${BASE_URL}/vendor/logout`, {
      method: "POST",
      credentials: "include",
    })
      .then((response) => {
        // Check if the logout was successful
        if (response.status === 200) {
          toast.success("Logged out successfully!", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "colored",
          });
          console.log("logged out");
          // Redirect the vendor to the login page or perform any other action
          setTimeout(() => {
            window.location.href = "/vendorlogin";
          }, 1200);
        } else {
          // Handle logout error
          console.error("Logout failed.");
          toast.error("Failed to logout!", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "colored",
          });
        }
      })
      .catch((error) => {
        // Handle network or other errors
        console.error("Error during logout:", error);
        toast.error("Failed to logout!", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "colored",
        });
      });
  };

  const routePath = useLocation().pathname;
  useEffect(() => {
    if (routePath === "/vendor/dashboard") {
      setCurrBtn("1");
    } else if (routePath === "/vendor/customer") {
      setCurrBtn("2");
    } else if (routePath === "/vendor/profile") {
      setCurrBtn("3");
    } else if (routePath === "/vendor/edit") {
      setCurrBtn("4");
    } else if (routePath === "/vendor/password") {
      setCurrBtn("5");
    }
  }, [routePath]);

  return (
    <>
      <div className={classes.sidebar}>
        <div className={classes["sidebar-content-container"]}>
          <Link style={{ textDecoration: "none" }} to="/vendor/dashboard">
            <button
              className={
                currBtn === "1"
                  ? classes["sidebar-content-active"]
                  : classes["sidebar-content"]
              }
              id="1"
            >
              <DashboardIcon className={classes.dashboardIcon} />
              <h3>Dashboard</h3>
              <ChevronRightIcon className={classes.rightIcon} />
            </button>
          </Link>

          <Link style={{ textDecoration: "none" }} to="/vendor/customer">
            <button
              className={
                currBtn === "2"
                  ? classes["sidebar-content-active"]
                  : classes["sidebar-content"]
              }
              id="2"
            >
              <GroupsIcon className={classes.dashboardIcon} />
              <h3>Customers</h3>
              <ChevronRightIcon className={classes.rightIcon} />
            </button>
          </Link>
          <Link style={{ textDecoration: "none" }} to="/vendor/profile">
            <button
              className={
                currBtn === "3"
                  ? classes["sidebar-content-active"]
                  : classes["sidebar-content"]
              }
              id="3"
            >
              <PersonIcon className={classes.dashboardIcon} />
              <h3>Profile</h3>
              <ChevronRightIcon className={classes.rightIcon} />
            </button>
          </Link>
          <Link style={{ textDecoration: "none" }} to="/vendor/edit">
            <button
              className={
                currBtn === "4"
                  ? classes["sidebar-content-active"]
                  : classes["sidebar-content"]
              }
              id="4"
            >
              <EditIcon className={classes.dashboardIcon} />
              <h3>Details</h3>
              <ChevronRightIcon className={classes.rightIcon} />
            </button>
          </Link>
          <Link style={{ textDecoration: "none" }} to="/vendor/password">
            <button
              className={
                currBtn === "5"
                  ? classes["sidebar-content-active"]
                  : classes["sidebar-content"]
              }
              id="5"
            >
              <VpnKeyIcon className={classes.dashboardIcon} />
              <h3>Password</h3>
              <ChevronRightIcon className={classes.rightIcon} />
            </button>
          </Link>

          <Link style={{ textDecoration: "none" }}>
            <button
              onClick={logoutHandler}
              className={
                currBtn === "6"
                  ? classes["sidebar-content-active"]
                  : classes["sidebar-content"]
              }
              id="6"
            >
              <LogoutIcon className={classes.dashboardIcon} />
              <h3>Log out</h3>
              <ChevronRightIcon className={classes.rightIcon} />
            </button>
          </Link>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default Sidebar;
