import React, { useState, useEffect } from "react";
import { Modal, Box } from "@mui/material";
import Fade from "@mui/material/Fade";
import Backdrop from "@mui/material/Backdrop";
// import { useNavigate } from "react-router-dom";
import LinearProgress from "@mui/material/LinearProgress";
import classes from "./ConfirmationPopUp.module.css";

function ConfirmationPopUp({ open, onClose }) {
  // const navigate = useNavigate();
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 0;
        }
        const diff = (100 / 25000) * 100; 
        return Math.min(oldProgress + diff, 100);
      });
    }, 100);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <>
      <Modal
        open={open}
        // onClose={onClose}
        closeAfterTransition
        disableEscapeKeyDown
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
            style: { backdropFilter: "blur(5px)" },
          },
        }}
      >
        <Fade in={open}>
          <Box
            sx={{
              outline: "none", //to remove the strange outline (black border) that shows up when modal is in focus
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "35%" /* Default width for all screen sizes */,
              "@media (max-width: 768px)": {
                width: "80%" /* Width for screens up to 768px */,
              },
              "@media (max-width: 480px)": {
                width: "85%" /* Width for screens up to 480px */,
              },
              maxHeight: "700px",
              padding: "20px", //new
              overflow: "auto", //new
              bgcolor: "white",
              // bgcolor: "rgba(255, 255, 255, 0.700)",
              border: "2px solid white",
              borderRadius: "15px",
              boxShadow: "0px 3px 8px rgba(0, 0, 0, 0.3)",
            }}
          >
            <h5 className={classes.modalHeading}>Congratulations!</h5>
            <p>Your details have been submitted successfully.</p>
            <p>
              You are being redirected to the payment page. Kindly pay according
              to the plan chosen during registration.
            </p>
            {/* <p>
              We aim to process and approve registrations within 24 to 48 hours,
              upon receiving payment. <br />
              We appreciate your patience and look forward to welcoming you as
              an approved vendor.
            </p> */}
            <LinearProgress variant="determinate" color="success" value={progress} />
            {/* <button type="button" onClick={() => navigate("/vendor/dashboard")}>
              Go To Dashboard
            </button> */}
          </Box>
        </Fade>
      </Modal>
    </>
  );
}

export default ConfirmationPopUp;
