import React, { useEffect, useState } from "react";
import GoToTop from "../components/GoToTop";
import { BASE_URL } from "../../apiConfig";
import MessageUsButton from "../components/MessageUsButton";
import Navbar from "../components/Navbar";
import classes from "./PaymentStatus.module.css";
import vendorBanner from "../../assets/images/vendorBanner.webp";
import Footer from "../components/Footer";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

const PaymentStatus = () => {
  const navigate = useNavigate();
  // const [code, setCode] = useState("");
  // const [merchantTransactionId, setMerchantTransactionId] = useState("");
  // const [transactionId, setTransactionId] = useState("");
  // const [amount, setAmount] = useState("");
  const [paymentDetails, setPaymentDetails] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Extract parameters from the URL
    const urlParams = new URLSearchParams(window.location.search);
    const transactionIdParam = urlParams.get("transactionId");

    const fetchPayDetails = async () => {
      try {
        const response = await fetch(
          `${BASE_URL}/payment/${transactionIdParam}`
        );
        if (!response.ok) {
          throw new Error("Error fetching payment details");
        }

        const data = await response.json();
        setPaymentDetails(data);
        console.log(data.data.merchantTransactionId);
        console.log(data.data.transactionId);
        console.log(data.data.amount);
      } catch (e) {
        toast.error("Error fetching payment details!", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "colored",
        });
        console.error("Error fetching payment details:", e);
      } finally {
        setIsLoading(false);
      }
    };
    fetchPayDetails();
  }, []);

  function sendToDashboard() {
    navigate("/vendor/dashboard");
  }

  // if (isLoading) {
  //   return <p>Loading payment details</p>;
  // }

  return (
    <>
      <ToastContainer />
      <GoToTop />
      <MessageUsButton />
      <Navbar />
      <div
        className={classes.banner}
        style={{
          backgroundImage: `url(${vendorBanner})`,
        }}
      ></div>
      {/* <FirstForm /> */}
      <div className={classes.contentDiv} style={{ textAlign: "center" }}>
        {isLoading ? (
          <p>Loading payment details</p>
        ) : (
          <>
            <h1>Payment Details</h1>
            <hr />
            <h2>{paymentDetails?.message}</h2>

            <p>Payment Status: {paymentDetails?.data?.state}</p>
            {paymentDetails?.data?.state === "PENDING" && (
              <p style={{ color: "red" }}>
                Your payment is pending and it may take a few minutes to confirm
                the status. Please check your dashboard for updates.
              </p>
            )}

            <div>
              <p>
                Merchant Transaction ID:{" "}
                {paymentDetails?.data?.merchantTransactionId}
              </p>
              <p>Transaction ID: {paymentDetails?.data?.transactionId}</p>
              <p>
                Amount: ₹
                {(parseInt(paymentDetails?.data?.amount) / 100).toFixed(2)}
              </p>
            </div>
          </>
        )}
      </div>
      <div className={classes.registerDiv}>
        <button onClick={sendToDashboard}>Go to dashboard</button>
      </div>
      <Footer />
    </>
  );
};

export default PaymentStatus;
