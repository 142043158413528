import React from "react";
import { Link, useLocation } from "react-router-dom";
import classes from "./Services.module.css";
import GoToTop from "../components/GoToTop";
import Navbar from "../components/Navbar";
import Banner from "../components/Banner";
import MiddleBanner from "../components/MiddleBanner";
import Footer from "../components/Footer";
import servicesBanner from "../../assets/images/servicesBanner.webp";
import ExpectationCardsSlider from "../components/Homepage/ExpectationCardsSlider";
import MessageUsButton from "../components/MessageUsButton";
import { Helmet } from "react-helmet-async";
import { ToastContainer } from "react-toastify";

function Services() {
  const midBannerText = (
    <>
      Shor-sharaaba dhoom dhadakka
      <br />
      Event planning dekh har koi hoga
      <br />
      Hakka-bakka!
    </>
  );
  // let location =useLocation()
  // console.log(location.pathname)
  return (
    <>
      <Helmet>
        <title>
          Wedding Planners, Corporate Events Planners, and Special Ocassions
        </title>
        <link rel="canonical" href="https://www.partyhelpdesk.com/services" />
        <meta
          name="description"
          content="We are a team of experienced wedding planners, corporate events planners, and Special Ocassion organizer. We can help you plan your event from start to finish, so you can relax and enjoy your special day."
        />
      </Helmet>
      <GoToTop />
      <MessageUsButton />
      <Navbar />
      <Banner image={servicesBanner} text="Services We Offer" />
      <ExpectationCardsSlider />

      <div className={classes.wrapper}>
        <div className={classes.contentDiv}>
          <p>
            <strong>
              Make your events an affair to remember with Party Help Desk Vendor
              Facilitation Services!
            </strong>
          </p>
          <p>
            Planning and executing an event can be a demanding task. Especially
            with little to virtually no hands-on planning experience, comes a
            variety of challenges and setbacks that keep you from realising a
            memorable event or function. From getting experienced and credible
            vendors to streamlining logistics, requires great attention to
            detail. We at{" "}
            <Link to="https://www.partyhelpdesk.com/">Party Help Desk</Link>,
            work relentlessly with the sole objective, to enable people to
            rejoice in magnificent event experiences. And to make this happen,
            we have a forte in facilitating vendors for all kind of events. With
            a robust network of trusted service providers and vendors, we look
            forward to change the face of event planning and organisation.
          </p>
          <p>
            From a corporate conference or gathering to a wedding event,
            birthday party or any other event, we have you covered. We believe
            every event is unique and significant in itself and has a variety of
            distinctive requirements. Therefore, we have acquired expertise to
            facilitate service providers and vendors for all kinds of
            gatherings. From an intimate family affair to large-scale
            extravagant celebrations, we bring you resources and connections
            that generate creative and cutting-edge solutions to all of your
            event planning challenges.
          </p>
          <p>
            We take immense pride in our extensive network of service providers.
            Our team of expert professionals carefully screen and selects
            service providers based on experience, professionalism and quality
            of rendered services. Finding the right and trusted vendor is
            crucial to an event’s success. In order to accomplish this, we go
            the extra mile to match you with the vendors that run in complete
            accord with your event expectations and requirements. You may
            require an event planner, caterers, decorators, photographers, or
            entertainment services, we strive by day and by night to connect you
            with the best in the business across India.
          </p>
          <p>
            When it comes to having a tremendous event experience, working
            towards coordinating vendors can be a tough task. But, we are here
            to exempt you of all the burden that comes with multiple vendor
            management. We operate as a single point of contact, optimising the
            communication and coordination between you and the service
            providers.
          </p>
          <p>
            At Party Help Desk, we work intently to provide you tailored
            recommendations that can meet specific event requirements. Our team
            works one-to-one with you in order to understand and determine your
            goals, visions and budget.
          </p>
          <p>
            We are hardwired to delivering exceptional quality in all aspects of
            event planning and management. The{" "}
            <Link to="https://www.partyhelpdesk.com/vendorregistration">
              vendors and service providers
            </Link>
            associated with Party Help Desk are committed to meet high standards
            of professionalism and expertise. Thorough background checks,
            portfolio reviews, and client testimonials are the parametres we
            give our due diligence in order to select the best vendors and
            deliver high-quality work.
          </p>
          <p>
            Party Help Desk believes cost optimization is imperative in regards
            to effective event planning and management. Our primary agenda is to
            give our customers cost-efficient solutions without compromising on
            the quality.
          </p>
          <p>
            With our{" "}
            <Link to="https://www.partyhelpdesk.com/vendorlogin">
              vendor facilitation services
            </Link>
            , you can have the confidence your event is going to be a resounding
            success. We consider and work upon intricate details, source
            best-in-class service providers, regulate coordination, generate
            customised recommendations, maintain quality assurances, and deliver
            cost-effective solutions. Whether you’re planning a corporate event
            or a family celebration, Party Help Desk can transform your event
            planning journey in its entirety.
          </p>
        </div>
      </div>
      <MiddleBanner text={midBannerText} />
      <Footer />
      <ToastContainer />
    </>
  );
}

export default Services;
