import React from "react";

function PrivacyPolicy() {
  return (
    <>
      {/* <h2>Privacy Policy</h2> */}
      <p>
        At Party Help Desk, we value your privacy and are committed to
        protecting the personal information you provide when using our vendor
        directory for various events. This privacy policy explains how we
        collect, use, disclose, and safeguard your personal credentials. Kindly
        go through the policy carefully for a broader understanding of our
        practices.
      </p>
      <p>
        When you avail our vendor directory services, we may require the
        following personal information from your end:
        <ul>
          <li>Name, address, email, and contact number</li>
          <li>Company name, designation, and industry</li>
          <li>Vendor portfolio and description</li>
          <li>Event preferences and specialties</li>
        </ul>
      </p>
      <p>
        Party Help Desk may collect non-personal information, viz. aggregated
        data, statistics, and analytics related to the usage of our vendor
        database. This information does not directly identify you:
      </p>
      <p>
        We use the collated information in order to facilitate vendors for you
        for a wide variety of events and provide a better experience, including:
        <ul>
          <li>Displaying your vendor profile in our directory</li>
          <li>
            Connecting event planners with vendors based on their expectations
            and requirements.
          </li>
          <li>Fostering communication between event planners and vendors</li>
        </ul>
      </p>
      <p>
        This information can be used to study usage patterns, improve our
        services, develop new features, and personalise your experience on the
        Party Help Desk website.
      </p>
      <p>
        As we get your consent, we may use your information to send you
        marketing communications, newsletters, and most recent updates about our
        services, events, and relevant opportunities. You can choose to not
        receive such communications at any time.
      </p>
      <p>
        Your vendor profile and contact information may be shared with event
        organizers who are interested in your services for their events. This
        facilitates direct communication and collaboration between vendors and
        event organizers.
      </p>
      <p>
        We may disclose your information if required by law, legal process, or
        governmental request. We may also disclose your information to protect
        our rights, investigate potential violations, or respond to security
        incidents.
      </p>
      <p>
        We implement reasonable security measures to protect your personal
        information from unauthorized access, disclosure, alteration, and
        destruction. However, please note that no method of transmission or
        storage is entirely secure. While we strive to protect your personal
        information, we cannot guarantee its absolute security.
      </p>
      <p>
        Our platform may contain links to third-party websites or services. This
        Privacy Policy applies only to our platform. We do not control or assume
        responsibility for the privacy practices of third-party websites or
        services. We encourage you to review their respective privacy policies
        before interacting with them.
      </p>
      <p>
        You have the right to access, update, correct, or delete your personal
        information held by us. If you wish to exercise these rights or have any
        concerns about your personal information, please contact us using the
        information provided below.
      </p>
      <p>
        Our services are not directed to individuals under the age of 18. We do
        not knowingly collect personal information from children. If we become
        aware that we have inadvertently collected personal information from a
        child, we will promptly delete it from our records.
      </p>
      <p>
        We may update this Privacy Policy from time to time. The updated version
        will be posted on our website, and the revised effective date will
        indicate when the changes take effect. We encourage you to review this
        Privacy Policy periodically.
      </p>
    </>
  );
}

export default PrivacyPolicy;
