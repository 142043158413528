import React, { useState } from "react";
import axios from "axios";
import Header from "./Header";
import { BASE_URL } from "../../apiConfig";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import Table from "./TableVendor";
import MenuIcon from "@mui/icons-material/Menu";
import Sidebar from "./Sidebar";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import TableBlog from "./TableBlog";
import classes from "./Blog.module.css";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Modal, Box } from "@mui/material";
import Fade from "@mui/material/Fade";
import { ToastContainer, toast } from "react-toastify";

function Blog() {
  const [addForm, setAddForm] = useState(false);

  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");

  const [selectedFile1, setSelectedFile1] = useState(null);
  const [selectedFile2, setSelectedFile2] = useState(null);

  const handleFileChange1 = (e) => {
    setSelectedFile1(e.target.files[0]);
  };

  const handleFileChange2 = (e) => {
    setSelectedFile2(e.target.files[0]);
  };

  const handleAddBlogForm = (e) => {
    setAddForm(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const uploadPromise = new Promise(async (resolve, reject) => {
      try {
        const formData = new FormData();
        formData.append("title", title);
        formData.append("content", content);
        formData.append("bannerImg", selectedFile1);
        formData.append("smallImg", selectedFile2);

        const response = await fetch(`${BASE_URL}/blog/createblog`, {
          method: "POST",
          body: formData,
        });

        if (!response.ok) {
          reject();
          console.log("Failed to upload blog");
        } else {
          resolve();
          console.log("Blog uploaded successfully!");
        }
      } catch (error) {
        reject();
      }
    });

    await toast.promise(uploadPromise, {
      pending: "Uploading Blog...",
      success: "Blog uploaded successfully!",
      error: "Error uploading blog!",
    });
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   toast.info("Uploading Blog...", {
  //     position: "top-center",
  //     autoClose: 5000,
  //     hideProgressBar: false,
  //     closeOnClick: false,
  //     pauseOnHover: false,
  //     draggable: false,
  //     progress: undefined,
  //     theme: "light",
  //   });
  //   try {
  //     const formData = new FormData();
  //     formData.append("title", title);
  //     formData.append("content", content);

  //     formData.append("bannerImg", selectedFile1);
  //     formData.append("smallImg", selectedFile2);

  //     console.log(formData);

  //     const response = await fetch(`${BASE_URL}/blog/createblog`, {
  //       method: "POST",
  //       body: formData,
  //     });

  //     if (!response.ok) {
  //       toast.error("Failed to upload blog!", {
  //         position: "top-center",
  //         autoClose: 5000,
  //         hideProgressBar: false,
  //         closeOnClick: false,
  //         pauseOnHover: false,
  //         draggable: false,
  //         progress: undefined,
  //         theme: "colored",
  //       });
  //       throw new Error("Failed to upload blog");
  //     } else {
  //       console.log("data uploaded successfully!");
  //       toast.success("Blog uploaded successfully!", {
  //         position: "top-center",
  //         autoClose: 5000,
  //         hideProgressBar: false,
  //         closeOnClick: false,
  //         pauseOnHover: false,
  //         draggable: false,
  //         progress: undefined,
  //         theme: "colored",
  //       });
  //     }
  //   } catch (e) {
  //     toast.error("Failed to upload blog!", {
  //       position: "top-center",
  //       autoClose: 5000,
  //       hideProgressBar: false,
  //       closeOnClick: false,
  //       pauseOnHover: false,
  //       draggable: false,
  //       progress: undefined,
  //       theme: "colored",
  //     });
  //     console.log("Error uploading data:", e);
  //   }
  // };

  return (
    <div className="adminPage">
      <Sidebar />

      <div className={classes.category}>
        <Header />
        <div className={classes.addCategory}>
          <h3>All Blogs</h3>

          <button className={classes.addDiv} onClick={handleAddBlogForm}>
            <AddIcon className={classes.addIcon} />
            <h4>Add Blog</h4>
          </button>
        </div>
        <hr />

        {/* ADD NEW BLOG */}
        <Modal
          open={addForm}
          onClose={() => setAddForm(false)}
          disableEnforceFocus
        >
          <Fade in={addForm}>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "80%",
                maxHeight: "500px",
                padding: " 20px",
                overflow: "auto",
                bgcolor: "#FFF",
                borderRadius: "15px",
                boxShadow: "0px 3px 8px rgba(0, 0, 0, 0.3)",
              }}
            >
              <div className={classes.addVendorForm}>
                <div className={classes["addVendorForm-Header"]}>
                  <h3>Add Blog</h3>
                  <CloseIcon
                    className={classes.closeIcon}
                    onClick={(e) => setAddForm(false)}
                  />
                </div>
                <hr />
                <form
                  encType="multipart/form-data"
                  method="POST"
                  onSubmit={handleSubmit}
                >
                  <div className={classes.formInput}>
                    <label> Enter Blog title*</label>
                    <input
                      className={classes.vendorInput}
                      type="text"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                      name="title"
                      required
                    />
                  </div>
                  <div className={classes.formInput}>
                    <label> Upload banner image*</label>

                    <input
                      type="file"
                      name="bannerImg"
                      required
                      onChange={handleFileChange1}
                    />
                  </div>
                  <div className={classes.formInput}>
                    <label> Upload card image*</label>
                    <input
                      type="file"
                      name="smallImg"
                      required
                      onChange={handleFileChange2}
                    />
                  </div>
                  <div className={classes.formInput}></div>
                  <div className={classes.contentInput}>
                    <label htmlFor="index">Upload Content* </label>
                    <CKEditor
                      name="content"
                      editor={ClassicEditor}
                      data={content}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setContent(data);
                        console.log({ event, editor, data });
                      }}
                    />
                  </div>
                  <br />
                  <div className={classes.formControl}>
                    <button onClick={(e) => setAddForm(false)}> Close</button>
                    <button type="submit" onClick={(e) => setAddForm(false)}>
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </Box>
          </Fade>
        </Modal>

        <TableBlog />
      </div>
      <ToastContainer />
    </div>
  );
}

export default Blog;
