import React from "react";

function Faqs() {
  return (
    <>
      {/* <h2>FAQs</h2> */}
      <ol>
        <li>
          <p>
            <strong>What is Party Help Desk?</strong>
          </p>
          <p>
            Party Help Desk is a one-stop destination to all your event planning
            and management needs. With Party Help Desk, you are accessible to a
            wide variety of vendors and service providers for all kinds of
            events, celebrations, and festivities, viz. birthday party, wedding,
            corporate events, puja functions, etc.
          </p>
        </li>
        <li>
          <p>
            <strong>
              Will Party Help Desk negotiate with service providers on my
              behalf?
            </strong>
          </p>
          <p>
            No, Party Help Desk does not negotiate with service providers on
            your behalf. We solely provide a database of best-in-class and
            experienced vendors you can work with in order to plan and execute
            splendid events.
          </p>
        </li>
        <li>
          <p>
            <strong>
              Will Party Help Desk ask for commission from service providers on
            </strong>
            closed deals?
          </p>
          <p>
            Party Help Desk does NOT seek any commission from vendors or service
            providers for any closed deals.
          </p>
        </li>
        <li>
          <p>
            <strong>
              Will Party Help Desk be a part of complete service provider hiring
              process by end consumers, from end to end?
            </strong>
          </p>
          <p>
            No, we do not participate in the hiring process of service providers
            by end consumers. The end consumers shortlist and onboard the
            service providers and vendors based on their discretion.
          </p>
        </li>
        <li>
          <p>
            <strong>
              Will Party Help Desk recommend service providers to the end
              consumers?
            </strong>
          </p>
          <p>
            No, we do not make any service provider recommendations for the end
            consumers. End consumers review a vendor’s body of work and market
            experience and make a decision based on it.
          </p>
        </li>
        <li>
          <p>
            <strong>
              For how much duration my ad will be there on Party Help Desk
              website?
            </strong>
          </p>
          <p>
            As a service provider, your ad on the Party Help Desk website would
            last for a year as we provide yearly subscriptions.
          </p>
        </li>
        <li>
          <p>
            <strong>Will I have to renew the subscriptions?</strong>
          </p>
          <p>
            Yes. As a service provider associated with Party Help Desk, you will
            have to renew your subscription after a year.
          </p>
        </li>
        <li>
          <p>
            <strong>
              Will payment to the service provider be routed through Party Help
              Desk?
            </strong>
          </p>
          <p>
            No. Party Help Desk does not have a payment gateway for payments to
            vendors and service providers.
          </p>
        </li>
        <li>
          <p>
            <strong>
              Will Party Help Desk verify the service provider’s credentials on
              end consumers behalf?
            </strong>
          </p>
          <p>
            No. Party Help Desk does not verify or authenticate service
            provider’s credentials on behalf of end consumers.
          </p>
        </li>
      </ol>
    </>
  );
}

export default Faqs;
