import React, { useState, useEffect } from "react";
import { BASE_URL } from "../../apiConfig";
import { Link } from "react-router-dom";

import classes from "./VendorProfile.module.css";

import Sidebar from "../components/Sidebar";
import { CircularProgress } from "@mui/material";

function VendorProfile() {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState({
    planSelected: "",
    categoriesSelected: [],
    companyName: "",
    subcategoriesSelected: [],
    pincode: "",
    whatsappNumber: "",
    phoneNumber: "",
    address: "",
    city: "",
    state: "",
    email: "",
    additionalInfo: "",
    overview: "",
    servicesOffered: "",
    gstNumber: "",
    businessYears: "",
    projectsNum: "",
    startingPrice: "",
    maximumPrice: "",
    instaLink: "",
    fbLink: "",
    websiteURL: "",
    partnerName: "",
    // companyName: "",
    image1: "",
    image2: "",
    image3: "",
    image4: "",
    video1: "",
    video2: "",
  });

  console.log(data.categoriesSelected);

  useEffect(() => {
    fetch(`${BASE_URL}/getdetails`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data.categoriesSelected);
        setIsLoading(false);
        setData(data);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }, []);

  return (
    <div className={classes.wrapper}>
      <Sidebar />
      {isLoading ? (
        <div className={classes.loadingDiv}>
          <CircularProgress color="inherit" />
        </div>
      ) : (
        <div className={classes.rightColumn}>
          <h3>My Profile</h3>
          <hr />
          <div className={classes.gridContainer}>
            <div className={classes.gridItem}>
              <h5>Name of Proprietor/Partner:</h5>
            </div>
            <div className={classes.gridItem}>
              <p>{data.partnerName}</p>
            </div>
          </div>
          <div className={classes.gridContainer}>
            <div className={classes.gridItem}>
              <h5>Name of the Company:</h5>
            </div>
            <div className={classes.gridItem}>
              <p>{data.companyName}</p>
            </div>
          </div>
          <div className={classes.gridContainer}>
            <div className={classes.gridItem}>
              <h5>Current Plan:</h5>
            </div>
            <div className={classes.gridItem}>
              <p>{data.planSelected}/-</p>
            </div>
          </div>
          <div className={classes.gridContainer}>
            <div className={classes.gridItem}>
              <h5>Categories Selected:</h5>
            </div>
            <div className={classes.gridItem}>
              <ul>
                {data.categoriesSelected.map((cat) => (
                  <li>{cat.categoryName}</li>
                ))}
              </ul>
            </div>
          </div>
          <div className={classes.gridContainer}>
            <div className={classes.gridItem}>
              <h5>Address:</h5>
            </div>
            <div className={classes.gridItem}>
              <p>{data.address}</p>
            </div>
          </div>
          <div className={classes.gridContainer}>
            <div className={classes.gridItem}>
              <h5>City:</h5>
            </div>
            <div className={classes.gridItem}>
              <p>{data.city}</p>
            </div>
          </div>
          <div className={classes.gridContainer}>
            <div className={classes.gridItem}>
              <h5>Pin Code:</h5>
            </div>
            <div className={classes.gridItem}>
              <p>{data.pincode}</p>
            </div>
          </div>
          <div className={classes.gridContainer}>
            <div className={classes.gridItem}>
              <h5>State:</h5>
            </div>
            <div className={classes.gridItem}>
              <p>{data.state}</p>
            </div>
          </div>

          {/* <div className={classes.gridContainer}>
            <div className={classes.gridItem}>
              <h5>Category:</h5>
            </div> */}
          {/* <div className={classes.gridItem}>
            {data.categoriesSelected.map((cat) => (
               <p key={cat._id}>{cat.CategoryName}</p>
             ))}
            </div> */}
          {/* </div>                   */}
          {/* <div className={classes.gridContainer}>    
            <div className={classes.gridItem}>   
              <h5>Sub-Category:</h5>
            </div>
            <div className={classes.gridItem}>
            </div>
          </div> */}

          <div className={classes.gridContainer}>
            <div className={classes.gridItem}>
              <h5>Email:</h5>
            </div>
            <div className={classes.gridItem}>
              <p>{data.email}</p>
            </div>
          </div>
          <div className={classes.gridContainer}>
            <div className={classes.gridItem}>
              <h5>Mobile Number:</h5>
            </div>
            <div className={classes.gridItem}>
              <p>{data.phoneNumber}</p>
            </div>
          </div>
          <div className={classes.gridContainer}>
            <div className={classes.gridItem}>
              <h5>WhatsApp Number:</h5>
            </div>
            <div className={classes.gridItem}>
              <p>{data.whatsappNumber}</p>
            </div>
          </div>
          <div className={classes.gridContainer}>
            <div className={classes.gridItem}>
              <h5>Website URL:</h5>
            </div>
            <div className={classes.gridItem}>
              <p>{data.websiteURL}</p>
            </div>
          </div>
          <div className={classes.gridContainer}>
            <div className={classes.gridItem}>
              <h5>Facebook page link:</h5>
            </div>
            <div className={classes.gridItem}>
              <p>{data.fbLink}</p>
            </div>
          </div>
          <div className={classes.gridContainer}>
            <div className={classes.gridItem}>
              <h5>Instagram page link:</h5>
            </div>
            <div className={classes.gridItem}>
              <p>{data.instaLink}</p>
            </div>
          </div>
          <div className={classes.gridContainer}>
            <div className={classes.gridItem}>
              <h5>GST Number:</h5>
            </div>
            <div className={classes.gridItem}>
              <p>{data.gstNumber}</p>
            </div>
          </div>
          <div className={classes.gridContainer}>
            <div className={classes.gridItem}>
              <h5>Number of projects till date:</h5>
            </div>
            <div className={classes.gridItem}>
              <p>{data.projectsNum}</p>
            </div>
          </div>
          <div className={classes.gridContainer}>
            <div className={classes.gridItem}>
              <h5>Years in business:</h5>
            </div>
            <div className={classes.gridItem}>
              <p>{data.businessYears}</p>
            </div>
          </div>
          <div className={classes.gridContainer}>
            <div className={classes.gridItem}>
              <h5>Price Range:</h5>
            </div>
            <div className={classes.gridItem}>
              <p>{`₹${data.startingPrice}-₹${data.maximumPrice}`}</p>
            </div>
          </div>
          <div className={classes.gridContainer}>
            <div className={classes.gridItem}>
              <h5>Overview:</h5>
            </div>
            <div className={classes.gridItem}>
              <p>{data.overview}</p>
            </div>
          </div>
          <div className={classes.gridContainer}>
            <div className={classes.gridItem}>
              <h5>Additional Info:</h5>
            </div>
            <div className={classes.gridItem}>
              <p>{data.additionalInfo}</p>
            </div>
          </div>

          {/* FOR PREMIUM VENDORS */}
          {data.planSelected === "premium" && (
            <>
              <div className={classes.gridContainer}>
                <div className={classes.gridItem}>
                  <h5>Small image 1:</h5>
                </div>
                <div className={classes.gridItem}>
                  <Link to={`${BASE_URL}/upload/${data.image1}`}>View</Link>
                </div>
              </div>
              <div className={classes.gridContainer}>
                <div className={classes.gridItem}>
                  <h5>Small Image 2:</h5>
                </div>
                <div className={classes.gridItem}>
                  <img src="" alt="" />
                  <Link to={`${BASE_URL}/upload/${data.image2}`}>View</Link>
                </div>
              </div>
              <div className={classes.gridContainer}>
                <div className={classes.gridItem}>
                  <h5>Small Image 3:</h5>
                </div>
                <div className={classes.gridItem}>
                  <img src="" alt="" />
                  <Link to={`${BASE_URL}/upload/${data.image3}`}>View</Link>
                </div>
              </div>
              <div className={classes.gridContainer}>
                <div className={classes.gridItem}>
                  <h5>Small Image 4:</h5>
                </div>
                <div className={classes.gridItem}>
                  <img src="" alt="" />
                  <Link to={`${BASE_URL}/upload/${data.image4}`}>View</Link>
                </div>
              </div>
              <div className={classes.gridContainer}>
                <div className={classes.gridItem}>
                  <h5>Video1:</h5>
                </div>
                <div className={classes.gridItem}>
                  <video width="320" height="240" controls>
                    <source
                      src={`${BASE_URL}/upload/${data.video1}`}
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video>
                </div>
              </div>
              <div className={classes.gridContainer}>
                <div className={classes.gridItem}>
                  <h5>Video2:</h5>
                </div>
                <div className={classes.gridItem}>
                  <video width="320" height="240" controls>
                    <source
                      src={`${BASE_URL}/upload/${data.video2}`}
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video>
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </div>
    // </div>
  );
}

export default VendorProfile;
